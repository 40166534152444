/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, Fragment } from "react";
import { Button, Col, Row, Image, Dropdown } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import imgAvatar from "assets/imgs/avatar.svg";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { SiVisa, SiMastercard } from "react-icons/si";
import { MdShare, MdKeyboardArrowDown } from "react-icons/md";
import { BiInfoCircle } from "react-icons/bi";

import { LazyLoadImage } from "react-lazy-load-image-component";
import configs from "configs";
import classNames from "classnames";

import {
  B1NormalTextTitle,
  B3NormalTextTitle,
  DivideLine,
  FlexAlignCenterDiv,
  MidTextTitle,
  NftAvatar,
  SmallTextTitle,
  MBigTitle,
} from "components/common/common.styles";
import Layout from "components/Layout";
import BuyerHistoryItem from "components/token/BuyerHistoryItem";
import BidHistoryItem from "components/token/BidHistoryItem";
import BuyTokenModal from "components/token/BuyTokenModal";
import PlaceBidModal from "components/token/PlaceBidModal";
import Utility from "service/utility";
import { BigNumberAdd, BigNumberMul, toFixed } from "service/number";
import LoadingSpinner from "components/common/LoadingSpinner";
import TokenOwnerItem from "components/token/TokenOwnerItem";
import HistoryItem from "components/token/HistoryItem";
import TokenController from "controller/TokenController";
import ReadMore from "components/common/ReadMore";
import ResellNftModal from "components/token/ResellNftModal";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getNftServiceFee,
  getETHUSDTCurrency,
  getMATICUSDTCurrency,
  getBNBUSDTCurrency,
} from "store/Nft/nft.selector";
import { getMyInfo, getWalletAddress } from "store/User/user.selector";
import {
  connectUserWallet,
  getWalletBalance,
  switchNetwork,
} from "store/User/user.slice";
import verifyBadge from "assets/imgs/verify.png";

import "./TokenDetail.scss";
import "react-lazy-load-image-component/src/effects/opacity.css";
import EthUtil from "ethereum/EthUtil";
import CreateERC721OfferModal from "pages/CreateCollectible/CreateERC721OfferModal";
import LoadingBar from "components/common/LoadingBar";
import CreateERC1155OfferModal from "pages/CreateCollectible/CreateERC1155OfferModal";
import useState from "react-usestateref";
import { FacebookShareButton, TwitterShareButton } from "react-share";

interface TokenDetailProps {}

const TokenDetail: React.FC<TokenDetailProps> = () => {
  const params: any = useParams();
  const location: any = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const serviceFee = useAppSelector(getNftServiceFee);
  const ethDollarPrice = useAppSelector(getETHUSDTCurrency);
  const maticDollarPrice = useAppSelector(getMATICUSDTCurrency);
  const bnbDollarPrice = useAppSelector(getBNBUSDTCurrency);
  const walletAddress = useAppSelector(getWalletAddress);

  const userInfo = useAppSelector(getMyInfo);
  const [showResellDialog, setShowResellDialog] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [offer, setOffer] = useState<any>(null);
  const [offers, setOffers] = useState<any>(null);
  const [token, setToken] = useState<any>({});
  const [ercType, setErcType] = useState<number>(721);
  const [histories, setHistories] = useState([]);
  const [bidHistory, setBidHistory] = useState<any[]>([]);
  const [highestBidder, setHighestBidder] = useState<any>(null);
  const [owner, setOwner] = useState<any>(null);
  const [owners, setOwners] = useState<any>(null);
  const [buyers, setBuyers] = useState<any>(null);
  const [creator, setCreator] = useState<any>(null);
  const [remainTimeObj, setRemainTimeObj] = useState<any>(null);

  const [showBuyTokenModal, setShowBuyTokenModal] = useState(false);
  const [showPlaceBidModal, setShowPlaceBidModal] = useState(false);
  const [isTransProgressing, setTransProgressing, isTransProgressingRef] =
    useState(false);
  const [imagesIsLoaded, setImagesIsLoaded] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);

  const [likeDisable, setLikeDisable] = useState(false);
  const [likes, setLikes] = useState(0);
  const [selectedTab, setSelectedTab] = useState("details");
  const tabs = [
    { label: "Owners", value: "owners" },
    { label: "Details", value: "details" },
    { label: "Bids", value: "bids" },
    { label: "Buyers", value: "buyers" },
    { label: "History", value: "history" },
  ];

  const resellFormData = useRef({
    min_bid_price: 0,
    duration: 0,
    offer_price: 0,
    copies: 1,
  });

  useEffect(() => {
    setTokenLoading(true);
    loadOffer();
  }, []);

  useEffect(() => {
    let timeInterval: any;

    if (isPending() && !isExpired()) {
      if (ercType === 721) {
        timeInterval = setInterval(() => {
          let obj = Utility.getPassedTimeObject(offer.date_end);
          setRemainTimeObj(obj);
        }, 1000);
      }

      if (ercType === 1155) {
        timeInterval = setInterval(() => {
          if (offer && offer.auction && !isExpired()) {
            let obj = Utility.getPassedTimeObject(offer.auction.date_end);
            setRemainTimeObj(obj);
          } else {
            setRemainTimeObj(null);
          }
        }, 1000);
      }
    }

    if (isPending() && isExpired()) {
      timeInterval = setInterval(() => {
        loadOffer();
      }, 60 * 1000);
    }

    if (!isPending() && isExpired()) {
      if (timeInterval) {
        clearInterval(timeInterval);
        timeInterval = null;
      }
    }

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
        timeInterval = null;
      }
    };
  }, [ercType, offer, remainTimeObj]);

  useEffect(() => {
    if (isPending() && !isExpired()) {
      if (ercType === 721) {
        let obj = Utility.getPassedTimeObject(offer.date_end);

        setRemainTimeObj(obj);
      }

      if (ercType === 1155) {
        if (offer?.auction) {
          let obj = Utility.getPassedTimeObject(offer.auction.date_end);

          setRemainTimeObj(obj);
        } else {
          setRemainTimeObj(null);
        }
      }
    }
  }, [offer, ercType]);

  const loadOffer = async () => {
    try {
      if (params.id) {
        const query = new URLSearchParams(location.search);

        const offer_id = query.get("offer");

        const { offer, history, token, offers } = await TokenController.getById(
          params.id,
          offer_id
        );
        setOffers(offers);

        let currOffer: any = null;

        if (offers && offers.length > 0) {
          currOffer =
            offers.find((it: any) => {
              return it._id === offer_id;
            }) || offers[0];

          if (currOffer) {
            if (currOffer.buyers && currOffer.buyers.length > 0) {
              currOffer.buyers.reverse();
              setBuyers(currOffer.buyers);
            }
            setOffer(currOffer);
          }
        } else if (offer) {
          currOffer = offer;
          setOffer(offer);

          if (offer.buyers && offer.buyers.length > 0) {
            offer.buyers.reverse();
            setBuyers(offer.buyers);
          }
        } else {
          setOffer(null);
        }

        history.sort(function (a: any, b: any) {
          return b.date - a.date;
        });

        setHistories(history);

        const bids: any[] = currOffer?.bids || [];

        bids.sort(function (a: any, b: any) {
          if (ercType === 1155 && a.copies && b.copies) {
            return b.price * b.copies - a.price * a.copies;
          }
          return b.price - a.price;
        });

        const owners = token?.owners || [];

        setOwners(token.owners);

        let currOwner = null;

        if (token?.owners?.length > 0) {
          if (token.type === 1155 && offers?.length > 0) {
            currOwner = token.owners.find((it: any) => {
              return it.user._id === currOffer.creator._id;
            });
          } else if (token.type === 721) {
            currOwner = token.owners.find((it: any) => {
              return it.user.wallet === walletAddress;
            });
          }
        }

        setToken(token);
        setErcType(token.type);

        if (token?.liked) {
          setLikeDisable(true);
        } else {
          setLikeDisable(false);
        }
        setLikes(token?.likes || 0);

        setBidHistory(bids);

        if (
          bids.length &&
          (currOffer?.auction ||
            ((currOffer?.type === "auction" || currOffer?.type === "both") &&
              token?.type === 721))
        ) {
          setHighestBidder(bids[0]);
        }

        const tokenOwner = owners.find((e: any) => {
          return e.user._id === token.creator._id;
        });

        if (owners.length)
          setOwner(currOwner ? currOwner : tokenOwner || owners[0]);

        if (token.creator) setCreator(token.creator);
      }

      setTokenLoading(false);
    } catch (err) {
      if (err) {
        history.push("/404");
      }
    }
  };

  const onLiked = async (id: string) => {
    if (!likeDisable) {
      let res = await TokenController.setLike(id);
      if (res.status === 200) {
        NotificationManager.success("Successfully liked!", "Success");
        setLikes(likes + 1);
        setLikeDisable(true);
      }
    } else {
      let res = await TokenController.setUnLike(id);
      if (res.status === 200) {
        NotificationManager.success("Successfully unliked!", "Success");
        setLikes(likes - 1);
        setLikeDisable(false);
      }
    }
    loadOffer();
  };

  const getBidMinPrice = () => {
    let minBidPrice: any = 0;
    if (offer) {
      minBidPrice = highestBidder?.price || offer.min_bid;
    }
    const auctionGap = 0.001;

    minBidPrice = parseFloat(BigNumberAdd(minBidPrice, auctionGap).toFixed(4));
    return minBidPrice;
  };

  const getTokenMedia = () => {
    return Utility.mediaValidation(token.media);
  };

  const getCollectionImgPath = () => {
    if (token && token.collections && token.collections.image)
      return Utility.mediaValidation(token.collections.image);
    return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
  };

  const isPicture = () => {
    if (token.media_type) {
      let media = token.media_type && token.media_type.toLowerCase();
      if (
        media.includes("png") ||
        media.includes("gif") ||
        media.includes("jpeg") ||
        media.includes("jpg") ||
        media.includes("webp")
      )
        return true;
      return false;
    }
    return false;
  };

  const isExpired = () => {
    if (offer && isAuction()) {
      if (offer.status === "expired") return true;

      const dateEnd = offer.auction
        ? new Date(offer.auction.date_end).getTime()
        : new Date(offer.date_end).getTime();
      const now = new Date().getTime();

      if (dateEnd && dateEnd <= now) return true;
    }
    return false;
  };

  const isPending = () => {
    if (offer) return offer?.status === "pending";
    return false;
  };

  const isBidder = () => {
    if (!isAuctionStarted()) return false;

    if (highestBidder?.user?.wallet === walletAddress) {
      return true;
    }
    return false;
  };

  const checkOfferReady = () => {
    if (
      ercType === 721 &&
      owner &&
      userInfo &&
      owner.user &&
      owner.user._id === userInfo._id
    ) {
      NotificationManager.info("You already owned this item.", "Info");
      return false;
    }
    return true;
  };

  const onDirectBuyClicked = (selectedOffer: any) => {
    if (!walletAddress) {
      dispatch(connectUserWallet());
      return;
    }

    dispatch(getWalletBalance());
    if (ercType === 1155 && !selectedOffer.sales_copies) {
      NotificationManager.error(
        `${selectedOffer.available_copies} copies are reserved by the auction.`,
        "Error"
      );
      return;
    } else {
      setOffer(selectedOffer);
      if (
        EthUtil.getNetwork() !==
        EthUtil.getChainIDByNetworkName(token.blockchain)
      ) {
        switchNetwork(EthUtil.getChainIDByNetworkName(token.blockchain));
      } else {
        if (checkOfferReady()) setShowBuyTokenModal(true);
      }
    }
  };

  const onPlaceBidClicked = () => {
    if (!walletAddress) {
      dispatch(connectUserWallet());
      return;
    }

    dispatch(getWalletBalance());
    if (
      EthUtil.getNetwork() !== EthUtil.getChainIDByNetworkName(token.blockchain)
    ) {
      switchNetwork(EthUtil.getChainIDByNetworkName(token.blockchain));
    } else {
      if (checkOfferReady()) setShowPlaceBidModal(true);
    }
  };

  const isDirectSale = () => {
    if (offer && (offer.type === "direct" || offer.type === "both"))
      return true;
    return false;
  };

  const isAuction = () => {
    if (offer) return offer.type === "auction" || offer.type === "both";
    if (ercType === 1155 && offer)
      return offer.type === "auction" || offer.type === "both";
    return false;
  };

  const isAuctionStarted = () =>
    !!offer?.auction ||
    ((offer?.type === "auction" || offer?.type === "both") &&
      token?.type === 721);

  const isOwner = () => {
    if (owner && walletAddress) return owner?.user?.wallet === walletAddress;
    return false;
  };

  const getOfferPriceWithServiceFee = () => {
    let overFlowPer = 100 + serviceFee;
    if (offer)
      return parseFloat(toFixed((offer.offer_price * overFlowPer) / 100, 3));
  };

  const getAvatar = (type: any) => {
    if (type === "creator" && creator && creator.avatar)
      return Utility.mediaValidation(creator.avatar);
    if (type === "owner" && owner && owner.user && owner.user.avatar)
      return Utility.mediaValidation(owner.user.avatar);
    if (
      type === "highestBidder" &&
      highestBidder &&
      highestBidder.user &&
      highestBidder.user.avatar
    )
      return Utility.mediaValidation(highestBidder.user.avatar);
    return imgAvatar;
  };

  const submitted = async (form: any) => {
    setShowStatusModal(true);
    setShowResellDialog(false);
    resellFormData.current = form;
  };

  const isCreator = () => {
    if (offer && offer.creator && userInfo._id === offer.creator._id)
      return true;
    return false;
  };

  const getCurrentOffers = (currOwner: any) => {
    const currOffers =
      offers &&
      offers.length > 0 &&
      offers.filter((e: any) => e.creator._id === currOwner.user._id);
    if (currOffers && currOffers.length > 0) {
      return currOffers;
    }
    return null;
  };

  const getCurrentOwner = () => {
    const currentOwner =
      owners &&
      owners.length > 0 &&
      owners.find((e: any) => e.user._id === offer.creator._id);
    if (currentOwner) {
      return currentOwner;
    }
    return owner;
  };

  const onRedirectUser = (user: any) => {
    if (user.wallet) {
      history.push(`/users/${user.wallet}`);
    } else {
      history.push(`/users/${user._id}`);
    }
  };

  const onRedirectSearch = (category_id: any) => {
    if (category_id) {
      history.push(`/search`, { category: category_id });
    }
  };

  const handleResell = () => {
    const networkID = EthUtil.getNetwork();
    if (
      networkID &&
      networkID !== EthUtil.getChainIDByNetworkName(token.blockchain)
    ) {
      switchNetwork(EthUtil.getChainIDByNetworkName(token.blockchain));
      return;
    }
    dispatch(getWalletBalance());
    setShowResellDialog(true);
  };

  const getScanURL = (block: string) => {
    if (block) {
      switch (block) {
        case "ETH":
          return configs.ETH_SCAN_URL;
        case "RINKEBY":
          return configs.RINKEBY_SCAN_URL;
        case "MATIC":
          return configs.MATIC_SCAN_URL;
        case "BNB":
          // return configs.BSC_TEST_SCAN_URL;
          return configs.BSC_SCAN_URL;
      }
    } else {
      return configs.ETH_SCAN_URL;
    }
  };

  return (
    <Layout className="token-detail">
      {tokenLoading ? (
        <div className="mt-auto mb-auto d-flex justify-content-center loading-bar">
          <LoadingBar />
        </div>
      ) : (
        <>
          <Row className="mb-5">
            <Col lg="6" className="pr-lg-5">
              <Row className="h-100">
                <Col xl="10" lg="12">
                  <div className="featured-media">
                    {isPicture() ? (
                      <>
                        <LazyLoadImage
                          id="token-img"
                          className="media"
                          effect="opacity"
                          afterLoad={() => setImagesIsLoaded(true)}
                          src={getTokenMedia()}
                          alt="tokenImage1"
                        />
                        {!imagesIsLoaded && (
                          <div className="loading-bar ml-auto mr-auto d-flex justify-content-center">
                            <LoadingBar />
                          </div>
                        )}
                      </>
                    ) : (
                      token &&
                      token.media && (
                        <>
                          <video
                            src={getTokenMedia()}
                            className="media"
                            loop
                            autoPlay
                            controls
                          >
                            <source type="video/mp4"></source>
                            <source type="video/webm"></source>
                            <source type="video/ogg"></source>
                            Your browser does not support HTML5 video.
                          </video>
                          {token.media_type.includes("mp3") && (
                            <>
                              <LazyLoadImage
                                id="token-img"
                                className="media cover-image"
                                effect="opacity"
                                afterLoad={() => setImagesIsLoaded(true)}
                                src={`${
                                  Utility.mediaValidation(token.preview) ||
                                  configs.DEPLOY_URL +
                                    "/content/collection/xsigma.jpg"
                                }`}
                                alt="tokenImage"
                              />
                              {!imagesIsLoaded && (
                                <div className="loading-bar loading-bar-cover d-flex justify-content-center">
                                  <LoadingBar />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              {token?._id && (
                <div className="d-flex justify-content-end mb-4">
                  <div
                    className="liked-action mr-3"
                    onClick={() => onLiked(token._id)}
                  >
                    {token.liked ? <AiFillHeart /> : <AiOutlineHeart />}
                    <span>{likes || 0}</span>
                  </div>

                  <Dropdown
                    className="token-detail__shared-dropdown"
                    drop="down"
                  >
                    <Dropdown.Toggle variant="none" className="shared-action">
                      <MdShare />
                      <span>SHARE</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="dropdown-menu__content">
                        <Dropdown.Item
                          onClick={() =>
                            navigator.clipboard.writeText(window.location.href)
                          }
                        >
                          Copy Link
                        </Dropdown.Item>
                        <FacebookShareButton url={window.location.href}>
                          <Dropdown.Item>Share on Facebook</Dropdown.Item>
                        </FacebookShareButton>

                        <TwitterShareButton url={window.location.href}>
                          <Dropdown.Item>Share to Twitter</Dropdown.Item>
                        </TwitterShareButton>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}

              {token?.name && (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="font-matrice mb-0 token-detail__title">
                    {token.name}
                  </div>
                </div>
              )}

              <div className="collection">
                <div className="collection-overlay">
                  <img src={getCollectionImgPath()} alt="" />
                </div>
                <div className="d-flex flex-wrap">
                  <div className="d-flex flex-column mr-auto">
                    <div className="collection-detail__btn">
                      <BiInfoCircle />
                      <span>ERC{ercType}</span>
                    </div>
                    <div className="collection-detail__btn">
                      <Image
                        src={Utility.getImageByNetworkName(
                          token.blockchain,
                          false
                        )}
                        height="22"
                        width="22"
                      />
                      <span>{token.blockchain}</span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="collection-detail__right">
                      <div className="collection-detail__available">
                        {offer &&
                          ercType === 1155 &&
                          `${offer.sales_copies}/${offer.total_copies} Available`}

                        {!offer &&
                          ercType === 1155 &&
                          `0/${token.copies} Available`}

                        {offer && ercType === 721 && "1 Available"}

                        {!offer && ercType === 721 && "0 Available"}
                      </div>
                      <Link
                        to={`/collections/${
                          token.collections ? token.collections._id : "xsigma"
                        }`}
                        className="collection-detail__title"
                      >
                        {token.collections && token.collections.verified && (
                          <Image width="24" height="24" src={verifyBadge} />
                        )}
                        {token.collections ? token.collections.name : "xSigma"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* {token?.categories && (
                <Row className="mt-3 mb-3">
                  <Col className="mb-2 mt-2">
                    <SmallTextTitle className="font-matrice">
                      CATEGORIES
                    </SmallTextTitle>
                    <div className="d-flex flex-wrap token-detail__categories">
                      {token.categories.map((category: any, index: number) => {
                        return (
                          <div
                            className="token-detail__categories-item mr-2 mt-2"
                            key={index}
                            onClick={() => onRedirectSearch(category._id)}
                          >
                            {category.name}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              )} */}

              <Row className="mt-3 mb-3">
                <Col className="mb-2 mt-2">
                  <SmallTextTitle className="mb-2 font-matrice">
                    {ercType === 721 && owner?.price && !offer
                      ? "OWNED BY"
                      : "CREATOR"}
                  </SmallTextTitle>

                  <div className="d-flex">
                    <Link
                      to={`/users/${
                        owner?.price && !offer
                          ? owner?.user.wallet
                          : creator?.wallet
                      }`}
                      className="token-detail__creator"
                    >
                      <NftAvatar
                        imagePath={getAvatar(
                          owner?.price && !offer ? "owner" : "creator"
                        )}
                        className="mr-3 token-detail__creator-image"
                      ></NftAvatar>

                      <SmallTextTitle className="token-detail__creator-text font-matrice">
                        {owner?.price && !offer
                          ? owner?.user.name
                          : creator?.name}
                        {((!owner?.price && offer && creator?.verified) ||
                          (owner?.price && !offer && owner?.user.verified)) && (
                          <Image
                            className="ml-2"
                            width="20"
                            height="20"
                            src={verifyBadge}
                          />
                        )}
                      </SmallTextTitle>
                    </Link>
                  </div>
                </Col>

                {isDirectSale() && (
                  <Col
                    className={classNames("mb-2 mt-2", {
                      "flex-grow-0": !isAuction() && !highestBidder,
                    })}
                  >
                    <SmallTextTitle className="mb-2 font-matrice">
                      PRICE
                    </SmallTextTitle>

                    <div className="token-detail__col">
                      <div>
                        <Image
                          src={Utility.getImageByNetworkName(
                            token.blockchain,
                            false
                          )}
                        />
                      </div>

                      <div className="token-detail__price-col ">
                        <div className="token-detail__price-text">
                          {Utility.toPlainString(offer.offer_price)}
                        </div>
                        <div className="token-detail__price-subtext">
                          {EthUtil.getDollarPrice(
                            offer.offer_price,
                            EthUtil.getChainIDByNetworkName(token.blockchain),
                            {
                              ethDollarPrice,
                              maticDollarPrice,
                              bnbDollarPrice,
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}

                {isAuction() && highestBidder && (
                  <Col className="mb-2 mt-2 flex-grow-0">
                    <SmallTextTitle className="mb-2 font-matrice">
                      HIGHEST OFFER
                    </SmallTextTitle>

                    <div className="token-detail__col">
                      <div>
                        <Image
                          src={Utility.getImageByNetworkName(
                            token.blockchain,
                            false
                          )}
                        />
                      </div>

                      <div className="token-detail__price-col">
                        <div className="token-detail__price-text">
                          {Utility.toPlainString(
                            BigNumberMul(
                              highestBidder.copies || 1,
                              highestBidder.price
                            )
                          )}
                        </div>
                        <div className="token-detail__price-subtext">
                          {" "}
                          {EthUtil.getDollarPrice(
                            BigNumberMul(
                              highestBidder.copies || 1,
                              highestBidder.price
                            ),
                            EthUtil.getChainIDByNetworkName(token.blockchain),
                            {
                              ethDollarPrice,
                              maticDollarPrice,
                              bnbDollarPrice,
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}

                {isAuction() && !highestBidder && (
                  <Col className="mb-2 mt-2 flex-grow-0 d-flex align-items-center">
                    <div className="token-detail__col">
                      {isPending() && !isOwner() && !highestBidder && (
                        <SmallTextTitle className="font-matrice text-nowrap">
                          No bids yet
                        </SmallTextTitle>
                      )}

                      {isPending() && isOwner() && !highestBidder && (
                        <SmallTextTitle className="font-matrice text-nowrap">
                          No bidders on this auction.
                        </SmallTextTitle>
                      )}
                    </div>
                  </Col>
                )}

                {owner?.price && ercType === 721 && !offer && (
                  <Col className="mb-2 mt-2 flex-grow-0">
                    <B1NormalTextTitle className="mb-2 font-matrice">
                      SOLD FOR
                    </B1NormalTextTitle>

                    <div className="token-detail__col">
                      <div>
                        <Image
                          src={Utility.getImageByNetworkName(
                            token.blockchain,
                            false
                          )}
                        />
                      </div>

                      <div className="token-detail__price-col ">
                        <div className="token-detail__price-text">
                          {Utility.toPlainString(owner?.price)}
                        </div>
                        <div className="token-detail__price-subtext">
                          {EthUtil.getDollarPrice(
                            owner?.price,
                            EthUtil.getChainIDByNetworkName(token.blockchain),
                            {
                              ethDollarPrice,
                              maticDollarPrice,
                              bnbDollarPrice,
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>

              {(offer?.status === "completed" ||
                (!isPending() && isExpired()) ||
                (isPending() && isExpired() && remainTimeObj) ||
                (isPending() && !isExpired() && remainTimeObj) ||
                (isOwner() &&
                  (!offer ||
                    (!isPending() && isExpired()) ||
                    token.my_available_copies > 0)) ||
                (isAuction() &&
                  !isOwner() &&
                  isPending() &&
                  !isExpired() &&
                  !isBidder()) ||
                (isDirectSale() &&
                  isPending() &&
                  offer.sales_copies !== 0 &&
                  !isOwner())) && (
                <Row className="mt-3 mb-3">
                  <Col>
                    <div className="auction">
                      {(offer?.status === "completed" ||
                        (!isPending() && isExpired()) ||
                        (isPending() && isExpired() && remainTimeObj) ||
                        (isPending() && !isExpired() && remainTimeObj)) && (
                        <Row>
                          <Col className="d-flex align-items-center">
                            {offer?.status === "completed" && (
                              <B1NormalTextTitle className="auction-status">
                                Auction Ended
                              </B1NormalTextTitle>
                            )}

                            {!isPending() && isExpired() && (
                              <B1NormalTextTitle className="auction-status">
                                Auction Expired
                              </B1NormalTextTitle>
                            )}

                            {isPending() && isExpired() && (
                              <B1NormalTextTitle className="auction-status">
                                Auction in processing
                              </B1NormalTextTitle>
                            )}

                            {isPending() && !isExpired() && remainTimeObj && (
                              <B1NormalTextTitle className="auction-status">
                                Auction ends in
                              </B1NormalTextTitle>
                            )}
                          </Col>

                          {isPending() && !isExpired() && remainTimeObj && (
                            <Col className="flex-grow-0">
                              <div className="auction-timer">
                                <div>
                                  <MidTextTitle className="auction-timer__time">
                                    {remainTimeObj.day}
                                  </MidTextTitle>
                                  <B1NormalTextTitle className="auction-timer__label">
                                    Days
                                  </B1NormalTextTitle>
                                </div>
                                <div className="ml-2 ml-md-4">
                                  <MidTextTitle className="auction-timer__time">
                                    {remainTimeObj.hour}
                                  </MidTextTitle>
                                  <B1NormalTextTitle className="auction-timer__label">
                                    Hours
                                  </B1NormalTextTitle>
                                </div>
                                <div className="ml-2 ml-md-4">
                                  <MidTextTitle className="auction-timer__time">
                                    {remainTimeObj.min}
                                  </MidTextTitle>
                                  <B1NormalTextTitle className="auction-timer__label">
                                    Mins
                                  </B1NormalTextTitle>
                                </div>
                                <div className="ml-2 ml-md-4">
                                  <MidTextTitle className="auction-timer__time">
                                    {remainTimeObj.sec}
                                  </MidTextTitle>
                                  <B1NormalTextTitle className="auction-timer__label">
                                    Sec
                                  </B1NormalTextTitle>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      )}

                      {((isAuction() &&
                        !isOwner() &&
                        isPending() &&
                        !isExpired() &&
                        !isBidder()) ||
                        (isDirectSale() &&
                          isPending() &&
                          offer.sales_copies !== 0 &&
                          !isOwner() &&
                          !(
                            isAuctionStarted() &&
                            offer.sales_copies === 1 &&
                            ercType === 1155
                          ))) && (
                        <Row className="special-buttons">
                          {isDirectSale() &&
                            isPending() &&
                            offer.sales_copies !== 0 &&
                            !isOwner() &&
                            !(
                              isAuctionStarted() &&
                              offer.sales_copies === 1 &&
                              ercType === 1155
                            ) && (
                              <Col className="flex-grow-0">
                                {/* {offer.paper_url ? (
                                  <Dropdown
                                    className="token-detail__dropdown"
                                    drop="up"
                                  >
                                    <Dropdown.Toggle
                                      variant="primary"
                                      className="auction-buy btn-white"
                                    >
                                      <div>
                                        <div>Buy Now</div>
                                        {offer.offer_price && (
                                          <div className="auction-buy__price">
                                            PRICE{" "}
                                            <Image
                                              src={Utility.getImageByNetworkName(
                                                token.blockchain
                                              )}
                                              className="auction-buy__token auction-buy__token--dark"
                                            />
                                            <Image
                                              src={Utility.getImageByNetworkName(
                                                token.blockchain,
                                                false
                                              )}
                                              className="auction-buy__token auction-buy__token--white"
                                            />{" "}
                                            {Utility.toPlainString(
                                              offer.offer_price
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => {
                                          onDirectBuyClicked(offer);
                                        }}
                                      >
                                        Pay with {token.blockchain}
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          onDirectBuyClicked(offer);
                                        }}
                                      >
                                        <SiVisa className="auction-icons__visa"></SiVisa>
                                        <SiMastercard className="auction-icons__mastercard"></SiMastercard>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : (
                                  <Button
                                    variant="primary"
                                    className="auction-buy btn-white"
                                    onClick={() => {
                                      onDirectBuyClicked(offer);
                                    }}
                                  >
                                    <div>
                                      <div>Buy Now</div>
                                      {offer.offer_price && (
                                        <div className="auction-buy__price">
                                          PRICE{" "}
                                          <Image
                                            src={Utility.getImageByNetworkName(
                                              token.blockchain
                                            )}
                                            className="auction-buy__token auction-buy__token--dark"
                                          />
                                          <Image
                                            src={Utility.getImageByNetworkName(
                                              token.blockchain,
                                              false
                                            )}
                                            className="auction-buy__token auction-buy__token--white"
                                          />{" "}
                                          {Utility.toPlainString(
                                            offer.offer_price
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Button>
                                )} */}
                                <Button
                                  variant="primary"
                                  className="auction-buy btn-white"
                                  onClick={() => {
                                    onDirectBuyClicked(offer);
                                  }}
                                >
                                  <div>
                                    <div>Buy Now</div>
                                    {offer.offer_price && (
                                      <div className="auction-buy__price">
                                        PRICE{" "}
                                        <Image
                                          src={Utility.getImageByNetworkName(
                                            token.blockchain
                                          )}
                                          className="auction-buy__token auction-buy__token--dark"
                                        />
                                        <Image
                                          src={Utility.getImageByNetworkName(
                                            token.blockchain,
                                            false
                                          )}
                                          className="auction-buy__token auction-buy__token--white"
                                        />{" "}
                                        {Utility.toPlainString(
                                          offer.offer_price
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </Button>
                              </Col>
                            )}

                          {isAuction() &&
                            !isOwner() &&
                            isPending() &&
                            !isExpired() &&
                            !isBidder() && (
                              <Col className="d-flex">
                                <Button
                                  variant="primary"
                                  className={classNames("auction-bid", {
                                    "btn-white":
                                      !(
                                        isDirectSale() &&
                                        isPending() &&
                                        offer.sales_copies !== 0 &&
                                        !isOwner()
                                      ) ||
                                      (isAuctionStarted() &&
                                        offer.sales_copies === 1 &&
                                        ercType === 1155),
                                    "btn-black":
                                      isDirectSale() &&
                                      isPending() &&
                                      offer.sales_copies !== 0 &&
                                      !isOwner() &&
                                      !(
                                        isAuctionStarted() &&
                                        offer.sales_copies === 1 &&
                                        ercType === 1155
                                      ),
                                  })}
                                  onClick={() => {
                                    onPlaceBidClicked();
                                  }}
                                >
                                  <div>
                                    <span>PLACE A BID</span>
                                    <div className="auction-bid__price">
                                      MIN BID{" "}
                                      <Image
                                        src={Utility.getImageByNetworkName(
                                          token.blockchain
                                        )}
                                        className="auction-bid__token auction-bid__token--dark"
                                      />
                                      <Image
                                        src={Utility.getImageByNetworkName(
                                          token.blockchain,
                                          false
                                        )}
                                        className="auction-bid__token auction-bid__token--white"
                                      />{" "}
                                      {Utility.toPlainString(getBidMinPrice())}
                                    </div>
                                  </div>
                                </Button>
                              </Col>
                            )}

                          <Col
                            className={classNames("d-flex flex-grow-0", {
                              "ml-auto":
                                !(
                                  isDirectSale() &&
                                  isPending() &&
                                  offer.sales_copies !== 0 &&
                                  !isOwner()
                                ) ||
                                !(
                                  isAuction() &&
                                  !isOwner() &&
                                  isPending() &&
                                  !isExpired() &&
                                  !isBidder()
                                ),
                            })}
                          >
                            <div className="auction-icons">
                              <Image
                                src={Utility.getImageByNetworkName(
                                  token.blockchain,
                                  false
                                )}
                                className="auction-icons__token"
                              />
                              <SiVisa className="auction-icons__visa"></SiVisa>
                              <SiMastercard className="auction-icons__mastercard"></SiMastercard>
                            </div>
                          </Col>
                        </Row>
                      )}

                      {isOwner() &&
                        (!offer ||
                          (!isPending() && isExpired()) ||
                          token.my_available_copies > 0) && (
                          <Row>
                            <Col>
                              <Button
                                variant="primary"
                                className="full-width outline-btn"
                                onClick={() => handleResell()}
                              >
                                <span>
                                  {isCreator() ? "Resell" : "Put on Sale"}
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        )}
                    </div>
                  </Col>
                </Row>
              )}

              {offer && offer.status === "completed" && (
                <MidTextTitle className="mt-3">
                  This offer is completed.
                </MidTextTitle>
              )}

              <Row>
                <Col>
                  <Button
                    variant="link"
                    className={classNames("btn-showmore", {
                      active: showDetails,
                    })}
                    onClick={() => {
                      setShowDetails(!showDetails);
                    }}
                  >
                    <MdKeyboardArrowDown></MdKeyboardArrowDown>
                    <span>MORE DETAILS</span>
                  </Button>
                </Col>
              </Row>

              {showDetails && (
                <>
                  <FlexAlignCenterDiv className="tab-nav mt-5">
                    {tabs.map((tab, index) => {
                      return (
                        (tab.value !== "buyers" ||
                          (tab.value === "buyers" && isDirectSale())) &&
                        (tab.value !== "bids" ||
                          (tab.value === "bids" && isAuction())) && (
                          <div
                            key={index}
                            className={
                              tab.value !== selectedTab
                                ? "tab-nav__item pointer-cursor mr-2 mr-lg-3 mr-xl-4"
                                : "tab-nav__item tab-nav__item--active mr-2 mr-lg-3 mr-xl-4"
                            }
                            onClick={() => {
                              setSelectedTab(tab.value);
                            }}
                          >
                            {tab.label}
                          </div>
                        )
                      );
                    })}
                  </FlexAlignCenterDiv>

                  <div className="list pt-4 mb-4">
                    {selectedTab === "buyers" && (
                      <>
                        <B1NormalTextTitle className="mb-2">
                          {buyers && buyers.length > 0
                            ? "Buyer List"
                            : "No Buyers Yet"}
                        </B1NormalTextTitle>
                        {buyers &&
                          buyers.length > 0 &&
                          buyers.map(
                            (
                              buyerItem: any,
                              index: React.Key | null | undefined
                            ) => {
                              return (
                                <Fragment key={index}>
                                  <BuyerHistoryItem
                                    item={buyerItem}
                                    token={token}
                                    onClick={(user: any) =>
                                      onRedirectUser(user)
                                    }
                                  />
                                  <DivideLine
                                    key={`line_${index}`}
                                  ></DivideLine>
                                </Fragment>
                              );
                            }
                          )}
                      </>
                    )}
                    {selectedTab === "bids" && (
                      <>
                        <B1NormalTextTitle className="mb-2">
                          {bidHistory && bidHistory.length > 0
                            ? "Bid List"
                            : "No Bids Yet"}
                        </B1NormalTextTitle>
                        {bidHistory.length > 0 &&
                          bidHistory.map((bidItem, index) => {
                            return (
                              <Fragment key={index}>
                                <BidHistoryItem
                                  item={bidItem}
                                  token={token}
                                  onClick={(user: any) => onRedirectUser(user)}
                                />
                                <DivideLine key={`line_${index}`}></DivideLine>
                              </Fragment>
                            );
                          })}
                      </>
                    )}
                    {selectedTab === "details" && (
                      <>
                        {token?.description && (
                          <div className="mt-3 mb-3">
                            <SmallTextTitle className="mb-2 font-matrice">
                              DESCRIPTION
                            </SmallTextTitle>
                            <ReadMore maxChars={250} text={token.description} />
                          </div>
                        )}
                        {isAuction() && highestBidder && (
                          <div className="mt-3 mb-3">
                            <SmallTextTitle className="mb-2 font-matrice text-uppercase">
                              Highest bid by
                            </SmallTextTitle>

                            <div className="d-flex">
                              <Link
                                to={`/users/${highestBidder?.user.wallet}`}
                                className="token-detail__creator"
                              >
                                <NftAvatar
                                  imagePath={getAvatar("highestBidder")}
                                  className="mr-3 token-detail__creator-image"
                                ></NftAvatar>

                                <SmallTextTitle className="token-detail__creator-text font-matrice">
                                  {highestBidder?.user.name}
                                </SmallTextTitle>
                              </Link>
                            </div>
                          </div>
                        )}
                        <div className="token-detail__details">
                          <div className="token-detail__details-col">
                            <div className="token-detail__details-item">
                              <div className="token-detail__details-title">
                                Token ID
                              </div>
                              <div className="token-detail__details-title">
                                Contract
                              </div>
                              <div className="token-detail__details-title">
                                Blockchain
                              </div>
                            </div>
                            <div className="token-detail__details-item">
                              <div className="token-detail__details-text">
                                {token.chain_id}
                              </div>
                              <div className="token-detail__details-text">
                                <a
                                  href={`${getScanURL(token.blockchain)}${
                                    token.chain_address
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {token.chain_address?.slice(0, 2)}...
                                  {token.chain_address?.slice(-4)}
                                </a>
                              </div>
                              <div className="token-detail__details-text">
                                {EthUtil.getCurrencyByNetworkName(
                                  token.blockchain
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="token-detail__details-divider"></div>

                          <div className="token-detail__details-col">
                            <div className="token-detail__details-item">
                              <div className="token-detail__details-title">
                                Token Standard
                              </div>
                              <div className="token-detail__details-title">
                                Royalties
                              </div>
                              <div className="token-detail__details-title">
                                MaximNFT Fee
                              </div>
                            </div>
                            <div className="token-detail__details-item">
                              <div className="token-detail__details-text">
                                ERC{ercType}
                              </div>
                              <div className="token-detail__details-text">
                                {token.royalties || 0}%
                              </div>
                              <div className="token-detail__details-text">
                                {serviceFee || 0}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedTab === "history" &&
                      (histories.length > 0 ? (
                        histories.map((history, i) => (
                          <HistoryItem
                            item={history}
                            token={token}
                            key={i}
                            onClick={(user: any) => onRedirectUser(user)}
                          />
                        ))
                      ) : (
                        <div className="mx-auto my-2">
                          <B3NormalTextTitle>
                            Not Found History
                          </B3NormalTextTitle>
                        </div>
                      ))}
                    {selectedTab === "owners" &&
                      owners.length > 0 &&
                      owners.map((e: any, i: any) => (
                        <TokenOwnerItem
                          key={i}
                          offers={getCurrentOffers(e)}
                          offer={ercType === 721 ? offer : null}
                          owner={e}
                          token={token}
                          onClick={(user: any) => onRedirectUser(user)}
                          buyClicked={(ownerOffer: any) => {
                            onDirectBuyClicked(ownerOffer);
                          }}
                        />
                      ))}
                  </div>
                </>
              )}
            </Col>
          </Row>

          {showBuyTokenModal && offer && (
            <BuyTokenModal
              show={showBuyTokenModal}
              offer={offer}
              token={token}
              type={ercType}
              handleClose={() => {
                setShowBuyTokenModal(false);
                loadOffer();
              }}
              loadOffer={loadOffer}
              owner={getCurrentOwner()}
              available_copies={
                ercType === 1155 && offer ? offer.sales_copies : 0
              }
              setShowBuyTokenModal={setShowBuyTokenModal}
              setTransProgressing={setTransProgressing}
              isTransProgressing={isTransProgressingRef}
            />
          )}

          {showPlaceBidModal && (
            <PlaceBidModal
              show={showPlaceBidModal}
              minPrice={getBidMinPrice()}
              type={ercType}
              token={token}
              highestBidder={ercType === 1155 ? highestBidder : null}
              owner={getCurrentOwner()}
              offer={offer}
              available_copies={
                ercType === 1155 && offer ? offer.available_copies : 0
              }
              loadOffer={loadOffer}
              handleClose={() => {
                setShowPlaceBidModal(false);
              }}
              isTransProgressing={isTransProgressingRef}
              setShowPlaceBidModal={setShowPlaceBidModal}
              setTransProgressing={setTransProgressing}
            />
          )}

          {isTransProgressing && <LoadingSpinner></LoadingSpinner>}

          <ResellNftModal
            show={showResellDialog}
            handleClose={() => {
              setShowResellDialog(false);
            }}
            type={ercType}
            token={token}
            copies={ercType === 1155 && token ? token.my_available_copies : 0}
            isResell={isCreator()}
            handleSubmit={submitted}
          />

          {ercType === 721 && (
            <CreateERC721OfferModal
              show={showStatusModal}
              collectible={resellFormData.current}
              onClose={() => setShowStatusModal(false)}
              onSuccess={loadOffer}
              updateDataCallback={loadOffer}
              token={token}
              chainId={token.chain_id}
            />
          )}

          {ercType === 1155 && (
            <CreateERC1155OfferModal
              show={showStatusModal}
              collectible={resellFormData.current}
              onClose={() => setShowStatusModal(false)}
              onSuccess={loadOffer}
              updateDataCallback={loadOffer}
              token={token}
              chainId={token.chain_id}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default TokenDetail;
