import React, { useEffect, useState } from "react";
import configs from "configs";
import { Link } from "react-router-dom";
import {
  B1NormalTextTitle,
  NftAvatar,
  SubDescription,
} from "../common/common.styles";
import Utility from "service/utility";
import verifyBadge from "assets/imgs/verify.png";
import { Image } from "react-bootstrap";
import DateTimeService from "service/dateTime";

interface CollectionItemProps {
  item: any;
}

const CollectionItem: React.FC<CollectionItemProps> = ({ item }) => {
  const [remainTimeObj, setRemainTimeObj] = useState<any>({});

  const walletHiddenAddress = () => {
    if (item.creator)
      return Utility.getHiddenWalletAddress(item.creator.wallet);
    return "";
  };

  const collectionImgUrl = () => {
    let media = item.thumbnail
      ? item.thumbnail.toLowerCase()
      : item.media
      ? item.media.toLowerCase()
      : "";
    if (
      media &&
      (media.includes("mp3") || media.includes("mp4") || media.includes("webm"))
    ) {
      if (item.preview) {
        return Utility.mediaValidation(item.preview);
      }
      return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
    }
    return Utility.mediaValidation(
      item.preview ||
        (item.thumbnail && item.thumbnail !== "false" ? item.thumbnail : "") ||
        item.media ||
        item.image
    );
  };

  const collectionCreatorImgUrl = () => {
    if (item.creator && item.creator.avatar)
      return Utility.mediaValidation(item.creator.avatar);
    return "";
  };

  const pushLink = () => {
    if (item.collections && item.creator) {
      return `/users/${item.creator.wallet}`;
    } else if (item.creator) {
      return `/collections/${item._id}`;
    } else {
      return "/collections/xsigma";
    }
  };

  useEffect(() => {
    setRemainTimeObj(Utility.getPassedTimeObject(Utility.getDiffDate(item?.date_launch)));
  }, [item]);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (Utility.getDiffDate(item?.date_launch)) {
        let obj = Utility.getPassedTimeObject(Utility.getDiffDate(item?.date_launch));
        setRemainTimeObj(obj);
      }
    }, 1000);

    return () => {
      if (timeInterval) clearInterval(timeInterval);
    };
  }, [item, remainTimeObj]);

  return (
    <Link className="collection-item mr-4 mt-3" to={pushLink()}>
      <div className="collection-item-bg">
        {collectionImgUrl() ? (
          <img src={collectionImgUrl()} alt="collectionImage" />
        ) : (
          <div className="no-thumbnail"></div>
        )}
      </div>

      <div className="token-details-area m-4">
        <NftAvatar
          imagePath={collectionCreatorImgUrl()}
          className="nft-avatar no-border"
        >
          {item.creator && item.creator.verified && (
            <Image style={{ width: 20, height: 20 }} src={verifyBadge} />
          )}
        </NftAvatar>
        <B1NormalTextTitle className="mt-4 text-center title">
          {item.name}
        </B1NormalTextTitle>
        <SubDescription className="mt-1 text-center grey-color">
          {walletHiddenAddress()}
        </SubDescription>

        {!Utility.getDiffDate(item?.date_launch) && (item.collections || item.creator) && (
          <div className="collection__details">
            <div className="collection__details-container">
              <div className="collection__details-item">
                <div className="collection__details-title">Items</div>
                <div className="collection__details-value">
                  {item?.items || 0}
                </div>
              </div>
              <div className="collection__details-item">
                <div className="collection__details-title">Volume</div>
                <div className="collection__details-value">
                  <Image
                    src={Utility.getImageByNetworkName(item.blockchain, false)}
                    width="10"
                  />
                  {Number(item?.volume?.toFixed(4)) || 0}
                </div>
              </div>
              <div className="collection__details-item">
                <div className="collection__details-title">Floor Price</div>
                <div className="collection__details-value">
                  <Image
                    src={Utility.getImageByNetworkName(item.blockchain, false)}
                    width="10"
                  />
                  {Number(item?.floor_price?.toFixed(4)) || 0}
                </div>
              </div>
            </div>
          </div>
        )}

        {Utility.getDiffDate(item?.date_launch) && (
          <div className="collection__details">
            <div className="collection__details-container">
              <div className="collection__details-item">
                <div className="collection__details-title">Days</div>
                <div className="collection__details-value">
                  {remainTimeObj.day ? remainTimeObj.day : "0"}
                </div>
              </div>
              <div className="collection__details-item">
                <div className="collection__details-title">Hours</div>
                <div className="collection__details-value">
                  {remainTimeObj.hour ? remainTimeObj.hour : "0"}
                </div>
              </div>
              <div className="collection__details-item">
                <div className="collection__details-title">Minutes</div>
                <div className="collection__details-value">
                  {remainTimeObj.min ? remainTimeObj.min : "0"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default CollectionItem;
