/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  Form,
  Image,
} from "react-bootstrap";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Storage from "service/storage";
import {
  SuperLargeTextTitle,
  // MidTextTitle,
  FlexColumnContainer,
  BigTitle,
  FlexJustifyBetweenDiv,
  SmallTextTitle,
  B1NormalTextTitle,
  FlexAlignCenterDiv,
  B3NormalTextTitle,
} from "components/common/common.styles";
import Carousel from "react-bootstrap/Carousel";
import Layout from "components/Layout";
import Collections from "components/collection/Collections";
import AuctionItem from "components/auction/AuctionItem";
import NoItem from "components/common/noItem";
import { useAppSelector } from "store/hooks";
import { getNftCategories } from "store/Nft/nft.selector";

import "./Home.scss";
import OfferController from "controller/OfferController";
import { FaCheck } from "react-icons/fa";
import LoadingBar from "components/common/LoadingBar";
import Utility from "service/utility";
// import LeftImage from "assets/imgs/left.png";
// import RightImage from "assets/imgs/right.png";
import BannerController from "../../controller/BannerController";
import verifyBadge from "assets/imgs/verify.png";

import homeSectionmLeft from "assets/imgs/maxim_vertical_text_1.png";
import homeSectionRight from "assets/imgs/maxim_vertical_text_2.png";
import homeSectionVideo from "assets/video/maximnft_video.mp4";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const location = useLocation();
  const layoutView = useRef(null);
  const exploreView = useRef(null);
  const nftCategories = useAppSelector(getNftCategories);
  const [exploreLoading, setExploreLoading] = useState(false);
  const [liveLoading, setLiveLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [exploreAuctions, setExploreAuctions] = useState<any[]>([]);
  const [explorePageNum, setExplorePageNumber] = useState(1);
  const [explorePages, setExplorePages] = useState(1);
  const [liveAuctions, setLiveAuctions] = useState<any[]>([]);
  const [liveAuctionPageNum, setLiveAuctionPageNumber] = useState(1);
  const [liveAuctionPages, setLiveAuctionPages] = useState(1);
  const [newAuctions, setNewAuctions] = useState<any[]>([]);
  const [newAuctionPageNum, setNewAuctionPageNumber] = useState(1);
  const [newAuctionPages, setNewAuctionPages] = useState(1);
  const [banners, setBanners] = useState<any[]>([]);
  const [searchParam, setSearchParam] = useState<any>({
    blockchain: "",
    category: "all",
    sort: "recent",
    verified: false,
  });

  const blockchainTypes = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Ethereum",
      value: "ETH",
    },
    {
      label: "Polygon",
      value: "MATIC",
    },
    {
      label: "BSC",
      value: "BNB",
    },
  ];

  const sortByOptions = [
    {
      value: "recent",
      text: "Recently added",
    },
    {
      value: "cheap",
      text: "Lowest price",
    },
    {
      value: "costly",
      text: "Highest price",
    },
    {
      value: "liked",
      text: "Most liked",
    },
  ];

  const onBlockTypeClicked = (e: any, value: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchParam({ ...searchParam, blockchain: value });
    setExplorePageNumber(1);
    Storage.set(
      "home_filter",
      JSON.stringify({ ...searchParam, blockchain: value })
    );
  };

  const onSortOptionClicked = (e: any, value: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchParam({ ...searchParam, sort: value });
    setExplorePageNumber(1);
    Storage.set("home_filter", JSON.stringify({ ...searchParam, sort: value }));
  };

  const onCheckVerified = (val: boolean) => {
    setSearchParam({ ...searchParam, verified: val });
    setExplorePageNumber(1);
    Storage.set(
      "home_filter",
      JSON.stringify({ ...searchParam, verified: val })
    );
  };

  const goToExplore = () => {
    setTimeout(function () {
      // @ts-ignore
      document.getElementById("layout").scrollTo({
        // @ts-ignore
        top: exploreView.current?.getBoundingClientRect().top - 120,
        behavior: "smooth",
      });
    }, 200);
  };

  useEffect(() => {
    if (Storage.get("home_filter")) {
      setSearchParam(JSON.parse(Storage.get("home_filter")));
    }
    const loadBanners = async () => {
      const data = await BannerController.getList();
      setBanners(data);
    };
    loadBanners();
  }, []);

  useEffect(() => {
    const loadExploreData = async () => {
      let params = {};
      if (searchParam.category === "all" && !searchParam.verified) {
        params = { sort: searchParam.sort, blockchain: searchParam.blockchain };
      } else if (searchParam.verified && searchParam.category === "all") {
        params = {
          sort: searchParam.sort,
          verified: searchParam.verified,
          blockchain: searchParam.blockchain,
        };
      } else if (searchParam.category !== "all" && !searchParam.verified) {
        params = {
          sort: searchParam.sort,
          category: searchParam.category,
          blockchain: searchParam.blockchain,
        };
      } else if (searchParam.verified && searchParam.category !== "all") {
        params = { ...searchParam };
      }
      if (explorePageNum > 1) {
        params = { page: explorePageNum, ...params };
      }
      setExploreLoading(true);
      let { offers, pages } = await OfferController.getList("explore", params);
      setExplorePages(pages);
      setExploreAuctions(
        explorePageNum === 1 ? offers : exploreAuctions.concat(offers)
      );
      setExploreLoading(false);
    };

    loadExploreData();
  }, [searchParam, explorePageNum]);

  useEffect(() => {
    const loadLiveAuctionData = async () => {
      let params = {};
      if (liveAuctionPageNum > 1) {
        params = { page: liveAuctionPageNum };
      }
      setLiveLoading(true);
      let { offers, pages } = await OfferController.getList("live", params);
      setLiveAuctionPages(pages);
      setLiveAuctions(
        liveAuctionPageNum === 1 ? offers : liveAuctions.concat(offers)
      );
      setLiveLoading(false);
    };
    loadLiveAuctionData();
  }, [liveAuctionPageNum]);

  useEffect(() => {
    const loadNewAuctionData = async () => {
      let params = {};
      if (newAuctionPageNum > 1) {
        params = { page: newAuctionPageNum };
      }
      setNewLoading(true);
      let { offers, pages } = await OfferController.getList("new", params);
      setNewAuctionPages(pages);
      setNewAuctions(
        newAuctionPageNum === 1 ? offers : newAuctions.concat(offers)
      );
      setNewLoading(false);
    };
    loadNewAuctionData();
  }, [newAuctionPageNum]);

  useEffect(() => {
    if (location.pathname.includes("explore")) {
      goToExplore();
    }
  }, [location.pathname]);

  const getTokenThumbnail = (token: any) => {
    return Utility.mediaValidation(token.image);
  };

  const getGroupAuctions = () => {
    if (banners && banners.length > 0) {
      let groups = [];
      var i = 0;
      while (i < banners.length) {
        groups.push(banners.slice(i, (i += 4)));
      }
      return groups;
    }
    return [];
  };

  return (
    <Layout className="home-container" ref={layoutView}>
      <div className="section-hero">
        <Container>
          <Row>
            <Col>
              <div className="section-hero__wrapper">
                <div className="section-hero__video-wrapper">
                  <img
                    src={homeSectionmLeft}
                    alt=""
                    className="section-hero__img-left--mobile"
                  />
                  <div className="section-hero__video">
                    <video
                      src={homeSectionVideo}
                      className="media"
                      loop
                      autoPlay
                      muted
                      playsInline
                    >
                      <source type="video/mp4"></source>
                      <source type="video/webm"></source>
                      <source type="video/ogg"></source>
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <img
                    src={homeSectionRight}
                    alt=""
                    className="section-hero__img-right--mobile"
                  />
                </div>
                <div className="section-hero__body">
                  <img
                    src={homeSectionmLeft}
                    alt=""
                    className="section-hero__img-left"
                  />

                  <div className="section-hero__content">
                    <SuperLargeTextTitle className="xsigma-nft-marketplace-2">
                      Iconic nfts
                      <br />
                      by <span>MAXIM</span>
                    </SuperLargeTextTitle>
                    <B3NormalTextTitle className="mt-3 upcoming-title">
                      Discover exclusive NFTs by selected talents. Buy limited
                      drops by the greatest artists.
                    </B3NormalTextTitle>
                    <Button
                      variant="outline-primary"
                      className="btn-white"
                      onClick={goToExplore}
                    >
                      <span>Discover</span>
                    </Button>
                  </div>

                  <img
                    src={homeSectionRight}
                    alt=""
                    className="section-hero__img-right"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="item-columns">
        <Row className="mt-5">
          <Col>
            <FlexJustifyBetweenDiv className="mb-5" ref={exploreView}>
              <BigTitle className="mt-3">Explore</BigTitle>
              <div className="category-list">
                {nftCategories.map((eType, index) => {
                  return (
                    <div
                      className={`nft-type-btn mr-2 my-2 ${
                        searchParam.category === eType.value
                          ? "nft-type-selected"
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        setSearchParam({
                          ...searchParam,
                          category: eType.value,
                        });
                        setExplorePageNumber(1);
                        Storage.set(
                          "home_filter",
                          JSON.stringify({
                            ...searchParam,
                            category: eType.value,
                          })
                        );
                      }}
                    >
                      {eType.label}
                    </div>
                  );
                })}
                <Dropdown className="filter-sort my-2 mr-2">
                  <Dropdown.Toggle className="filter-sort-btn">
                    Filter and sort
                  </Dropdown.Toggle>

                  <Dropdown.Menu renderOnMount={true}>
                    <div className="dropdown-menu__content">
                      <SmallTextTitle className="grey-color px-4">
                        SORT BY
                      </SmallTextTitle>
                      {sortByOptions.map((sortOption, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={(e) =>
                              onSortOptionClicked(e, sortOption.value)
                            }
                          >
                            <FlexAlignCenterDiv>
                              <B1NormalTextTitle>
                                {sortOption.text}
                              </B1NormalTextTitle>
                              {sortOption.value === searchParam.sort && (
                                <FaCheck className="fa-check ml-5"></FaCheck>
                              )}
                            </FlexAlignCenterDiv>
                          </Dropdown.Item>
                        );
                      })}

                      <SmallTextTitle className="grey-color px-4">
                        OPTIONS
                      </SmallTextTitle>
                      <FlexAlignCenterDiv className="px-4 text-nowrap">
                        Verified only
                        <Form.Check
                          type="switch"
                          id="is-verified"
                          className="ml-3 pointer-cursor"
                          onChange={(e) => onCheckVerified(e.target.checked)}
                          checked={searchParam.verified}
                        />
                      </FlexAlignCenterDiv>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="filter-sort my-2" alignRight>
                  <Dropdown.Toggle className="filter-sort-btn">
                    Blockchain
                  </Dropdown.Toggle>
                  <Dropdown.Menu renderOnMount={true}>
                    <div className="dropdown-menu__content">
                      {blockchainTypes.map((type, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={(e) => onBlockTypeClicked(e, type.value)}
                          >
                            <FlexAlignCenterDiv>
                              <B1NormalTextTitle>
                                {type.label}
                              </B1NormalTextTitle>
                              {type.value === searchParam.blockchain && (
                                <FaCheck className="fa-check ml-5"></FaCheck>
                              )}
                            </FlexAlignCenterDiv>
                          </Dropdown.Item>
                        );
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </FlexJustifyBetweenDiv>
            <Fragment>
              {exploreLoading && explorePageNum === 1 ? (
                <div className="d-flex my-3 justify-content-center loading-bar">
                  <LoadingBar />
                </div>
              ) : (
                <Fragment>
                  <FlexColumnContainer>
                    {exploreAuctions.length > 0 ? (
                      exploreAuctions.map((auction, index) => {
                        return (
                          <AuctionItem item={auction} key={index}></AuctionItem>
                        );
                      })
                    ) : (
                      <NoItem
                        title="No Items Found"
                        description="Try browsing other categories"
                        btnLink="/"
                        btnLabel="Browse marketplace"
                      />
                    )}
                  </FlexColumnContainer>
                </Fragment>
              )}

              {exploreLoading && explorePageNum > 1 ? (
                <div className="d-flex my-3 justify-content-center loading-bar">
                  <LoadingBar />
                </div>
              ) : (
                exploreAuctions.length > 0 &&
                explorePages > explorePageNum && (
                  <div className="mt-3 mb-5 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      className="btn-round w-50 outline-btn"
                      onClick={() => setExplorePageNumber(explorePageNum + 1)}
                    >
                      <span>Load More</span>
                    </Button>
                  </div>
                )
              )}

              {!liveLoading &&
                liveAuctionPageNum === 1 &&
                liveAuctions.length > 0 && (
                  <>
                    <BigTitle>
                      <span className="mr-3">Live auctions</span>
                    </BigTitle>

                    <FlexColumnContainer>
                      {liveAuctions.map((auction, index) => {
                        return (
                          <AuctionItem
                            item={auction}
                            showTimer={true}
                            key={index}
                          ></AuctionItem>
                        );
                      })}
                    </FlexColumnContainer>
                  </>
                )}
              {liveAuctionPageNum > 1 && liveLoading ? (
                <div className="d-flex my-3 justify-content-center loading-bar">
                  <LoadingBar />
                </div>
              ) : (
                liveAuctions.length > 0 &&
                liveAuctionPages > liveAuctionPageNum && (
                  <div className="mt-3 mb-5 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      className="btn-round w-50 outline-btn"
                      onClick={() =>
                        setLiveAuctionPageNumber(liveAuctionPageNum + 1)
                      }
                    >
                      <span>Load More</span>
                    </Button>
                  </div>
                )
              )}
              <BigTitle>
                <span className="mr-3">New auctions</span>
              </BigTitle>
              {newLoading && newAuctionPageNum === 1 ? (
                <div className="d-flex my-3 justify-content-center loading-bar">
                  <LoadingBar />
                </div>
              ) : (
                <Fragment>
                  <FlexColumnContainer>
                    {newAuctions.length > 0 ? (
                      newAuctions.map((auction, index) => {
                        return (
                          <AuctionItem item={auction} key={index}></AuctionItem>
                        );
                      })
                    ) : (
                      <NoItem
                        title="No Items Found"
                        description="Try to browse something for you on our marketplace"
                        btnLink="/"
                        btnLabel="Browse marketplace"
                      />
                    )}
                  </FlexColumnContainer>
                </Fragment>
              )}
              {newLoading && newAuctionPageNum > 1 ? (
                <div className="d-flex my-3 justify-content-center loading-bar">
                  <LoadingBar />
                </div>
              ) : (
                newAuctions.length > 0 &&
                newAuctionPages > newAuctionPageNum && (
                  <div className="mt-3 mb-5 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      className="btn-round w-50 outline-btn"
                      onClick={() =>
                        setNewAuctionPageNumber(newAuctionPageNum + 1)
                      }
                    >
                      <span>Load More</span>
                    </Button>
                  </div>
                )
              )}
            </Fragment>
            <BigTitle>
              <span className="mr-3">Featured collections</span>
            </BigTitle>
            <Collections type="featured" isSlider={true} />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Home;
