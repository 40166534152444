import React from "react";
import {
  NftAvatar,
  B1NormalTextTitle,
  NormalTextTitle,
} from "../common/common.styles";
import configs from "configs";
import { useHistory, Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai";
import imgAvatar from "assets/imgs/avatar.svg";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import verifyBadge from "assets/imgs/verify.png";
import Utility from "service/utility";

interface TokenItemProps {
  item: any;
}

const TokenItem: React.FC<TokenItemProps> = ({ item }) => {
  const history = useHistory();
  const getTokenThumbnail = () => {
    let media = item.media_type && item.media_type.toLowerCase();
    if (
      media &&
      (media.includes("mp3") || media.includes("mp4") || media.includes("webm"))
    ) {
      if (item.preview) {
        return Utility.mediaValidation(item.preview);
      }
      return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
    }
    return Utility.mediaValidation(
      item.preview || item.thumbnail || item.media
    );
  };

  const getCreator = () => {
    if (item && item.creator && item.creator.wallet) return item.creator;
    return null;
  };

  const getCreatorAvatar = () => {
    const creator = getCreator();
    if (creator && creator.avatar) {
      return Utility.mediaValidation(creator.avatar);
    }
    return imgAvatar;
  };

  const goToCreatorProfile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (getCreator()) history.push("/users/" + getCreator()?._id);
  };

  const getLink = () => {
    if (item.token) {
      if (item.type === 1155 && item.offer) {
        return `/tokens/${item.offer.token}?offer=${item.offer._id}`;
      } else {
        return `/tokens/${item.token._id}`;
      }
    } else if (item.offer && item.offer.token) {
      if (item.type === 1155 && item.offer) {
        return `/tokens/${item.offer.token}?offer=${item.offer._id}`;
      } else {
        return `/tokens/${item.offer.token}`;
      }
    } else if (item.collections && item.collections._id) {
      return `/collections/${item.collections._id}`;
    } else if (item.creator && item.creator.wallet) {
      return `/users/${item.creator.wallet}`;
    }
    return "/";
  };

  return (
    <Link to={getLink()} className="auction-item mr-4 p-4">
      {item && item.copies && <div className="multiple" />}

      <div
        className="auction-item__owner-wrapper"
      >
        <NftAvatar
          className="auction-owner-avatar"
          imagePath={getCreatorAvatar()}
        >
          {getCreator() && getCreator().verified && (
            <Image style={{ width: 12, height: 12 }} src={verifyBadge} />
          )}
        </NftAvatar>

        <span className="auction-item__owner">
          BY <span>{getCreator()?.name || "NAME"}</span>
        </span>
      </div>

      <div className="token-img-area">
        <div className="pre-token-img">
          {item.media ? (
            <img src={getTokenThumbnail()} alt="auctionImage" />
          ) : (
            <div className="no-thumbnail"></div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between token-details-area align-items-end">
        <div className="item-content">
          <B1NormalTextTitle className="mt-3">{item.name}</B1NormalTextTitle>
          <div className="description">{item.description}</div>
          <NormalTextTitle className="grey-color">
            {item.offer && item.offer.available_copies
              ? item.offer.available_copies + "/" + item.offer.total_copies
              : ""}
          </NormalTextTitle>
        </div>
        {item && item.likes && (
          <div className="likes d-flex align-items-center">
            <AiFillHeart />
            &nbsp;
            {item.likes}
          </div>
        )}
      </div>
    </Link>
  );
};

export default TokenItem;
