import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector, useInterval } from "store/hooks";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getNftServiceFee } from "store/Nft/nft.selector";
import {
  B2NormalTextTitle,
  BigTitle,
  DivideLine,
  FlexJustifyBetweenDiv,
  NormalTextTitle,
} from "../common/common.styles";
import { getWalletBalance as getWalletBalanceSelector } from "store/User/user.selector";
import { getWalletBalance } from "store/User/user.slice";
import { NotificationManager } from "react-notifications";
import { web3 } from "ethereum/OnBoard";
import { BigNumberMul } from "service/number";
import configs from "configs";
import SmartContract721 from "ethereum/Contract721";
import SmartContract1155 from "ethereum/Contract1155";
import ethUtil from "ethereum/EthUtil";
import OfferController from "controller/OfferController";
import useState from "react-usestateref";
import "./BuyTokenModal.scss";

interface BuyTokenModalProps {
  handleClose?: any;
  show: boolean;
  token: any;
  owner: any;
  offer: any;
  loadOffer: any;
  type: number;
  setTransProgressing: any;
  setShowBuyTokenModal: any;
  isTransProgressing: any;
  available_copies?: number;
}

const BuyTokenModal: React.FC<BuyTokenModalProps> = ({
  show,
  handleClose,
  offer,
  token,
  owner,
  type,
  loadOffer,
  setTransProgressing,
  setShowBuyTokenModal,
  isTransProgressing,
  available_copies,
}) => {
  const [quantity, setQuantity] = useState<any>(1);
  const [price, setPrice] = useState<any>(offer.offer_price * quantity);
  const serviceFee = useAppSelector(getNftServiceFee);
  const balance = useAppSelector(getWalletBalanceSelector);
  const dispatch = useAppDispatch();
  const [, setProcessStatus, processStatusRef] = useState(false);
  const errorInterval = useInterval();
  const txInterval = useInterval();
  const [, setIsSpeedUpLoading, isSpeedUpLoadingRef] = useState(false);

  useEffect(() => {
    if (quantity > 0 && offer) {
      setPrice(offer.offer_price * quantity);
    }
  }, [quantity, offer]);

  const directBuy = async () => {
    if (
      token.type === 1155 &&
      offer &&
      offer.sales_copies &&
      parseInt(`${quantity}`) > offer.sales_copies
    ) {
      NotificationManager.error(
        `You can not input greater than ${offer.sales_copies}.`,
        "Error"
      );
      return;
    }
    if (parseInt(`${quantity}`) < 1) {
      NotificationManager.error(`You can not input 0`, "Error");
      return;
    }
    try {
      setTransProgressing(true);
      if (web3 && offer) {
        const totalPrice =
          token.type === 721
            ? offer.offer_price
            : BigNumberMul(offer.offer_price, quantity).toString();

        const chainId =
          token.type === 721 ? token.chain_id : offer.offer_chain_id;

        const bytecode =
          token.type === 721
            ? await SmartContract721.getEngine721Bytecode()
            : await SmartContract1155.getEngine1155Bytecode();

        const engineContract =
          token.type === 721
            ? SmartContract721.getEngine721Contract()
            : SmartContract1155.getEngine1155Contract();

        const gasEstimate: any =
          token.type === 721
            ? await engineContract.methods.buy(chainId).estimateGas({
                data: bytecode,
                from: ethUtil.getAddress(),
                value: ethUtil.toWei(totalPrice),
              })
            : await engineContract.methods.buy(chainId, quantity).estimateGas({
                data: bytecode,
                from: ethUtil.getAddress(),
                value: ethUtil.toWei(totalPrice),
              });

        if (gasEstimate) {
          let hash = "";
          setProcessStatus(false);

          await new web3.eth.sendTransaction({
            from: ethUtil.getAddress(),
            to:
              token.type === 721
                ? SmartContract721.getEngine721Address()
                : SmartContract1155.getEngine1155Address(),
            value: ethUtil.toWei(totalPrice),
            gas: gasEstimate + configs.GAS_LIMIT,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
            ...((await ethUtil.getGasPrice()) || {}),
            data:
              token.type === 721
                ? engineContract.methods.buy(chainId).encodeABI()
                : engineContract.methods.buy(chainId, quantity).encodeABI(),
          })
            .on("transactionHash", async function (transactionHash: any) {
              hash = transactionHash;

              await handleOnTransactionHashBuy(totalPrice, transactionHash);
            })
            .on("receipt", async function (receipt: any) {
              await handleOnReceiptBuy(totalPrice, receipt);
            })
            .on("error", async function (error: any) {
              await ethUtil.txErrorHandler(
                error,
                hash,
                processStatusRef.current,
                errorInterval,
                setProcessStatus,
                setTransProgressing,
                true,
                false
              );
            });
        }
      }
    } catch (error: any) {
      rejectCallback(error);
    }
  };

  const handleOnSpeedUpBuy = async (
    totalPrice: any,
    nonce: any,
    contract: any
  ) => {
    if (isSpeedUpLoadingRef.current) return;

    setIsSpeedUpLoading(true);

    const speedUpData = await ethUtil.catchSpeedUpOrCancelTx(
      nonce,
      rejectCallback,
      "PaymentToOwner",
      contract
    );

    if (!speedUpData) return setIsSpeedUpLoading(false);

    const transactionHash = speedUpData.tx.hash;

    await buyCallback(totalPrice, transactionHash);
  };

  const handleOnTransactionHashBuy = async (
    totalPrice: any,
    transactionHash: any
  ) => {
    const engineContract =
      token.type === 721
        ? SmartContract721.getEngine721Contract()
        : SmartContract1155.getEngine1155Contract();

    let nonce: any;

    if (transactionHash) {
      var counter = configs.TIMEOUT;

      txInterval.current = setInterval(async function () {
        if (!nonce) {
          const tx = await ethUtil.getTransaction(transactionHash);

          if (tx) {
            nonce = tx.nonce;
          }
        }

        counter--;
        if (counter % 20 === 0) {
          if (isSpeedUpLoadingRef.current) return;

          const tx = await ethUtil.getTransaction(transactionHash);
          const receipt = await ethUtil.getTransactionReceipt(transactionHash);

          console.log({ tx, receipt });

          if (!tx && !receipt) {
            await handleOnSpeedUpBuy(totalPrice, nonce, engineContract);
          }

          if (receipt && receipt.status === true && !processStatusRef.current) {
            if (txInterval.current) {
              await buyCallback(totalPrice, transactionHash);
            }
          } else if (
            receipt &&
            receipt.status === false &&
            !processStatusRef.current
          ) {
            rejectCallback();
          }
        }

        if (processStatusRef.current) {
          clearInterval(errorInterval.current);
          errorInterval.current = null;
          clearInterval(txInterval.current);
          txInterval.current = null;
        }
      }, 1000);
    }
  };

  const handleOnReceiptBuy = async (totalPrice: any, receipt: any) => {
    if (receipt && receipt.status === true && !processStatusRef.current) {
      await buyCallback(totalPrice, receipt.transactionHash);
    } else if (
      receipt &&
      receipt.status === false &&
      !processStatusRef.current
    ) {
      rejectCallback();
    }
  };

  const rejectCallback = (error?: any) => {
    if (
      error?.message?.startsWith("Transaction was not mined within 50 blocks")
    )
      return;

    if (!isTransProgressing) return;

    clearInterval(txInterval.current);
    txInterval.current = null;
    clearInterval(errorInterval.current);
    errorInterval.current = null;

    setProcessStatus(true);
    loadOffer();
    setTransProgressing(false);
    NotificationManager.error(
      "Your attempt to buy this item was not successful",
      "Error"
    );

    if (error) {
      ethUtil.catchError(error);
    }
  };

  const buyCallback = async (price: any, hash: any) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    await OfferController.directBuy(offer._id, {
      price: price,
      hash: hash,
      copies: quantity,
    });

    if (hash) {
      await ethUtil.checkAndWaitNextBlock(hash);
    }

    loadOffer();

    NotificationManager.success(
      "You have bought this item successfully.",
      "Success"
    );
    
    dispatch(getWalletBalance());
    setIsSpeedUpLoading(false);
    setTransProgressing(false);
    setShowBuyTokenModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} className="buy-token-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          <BigTitle>Checkout</BigTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12" className="px-4">
            <B2NormalTextTitle>
              You are about to purchase {token.name}
              <br />
              from {owner && owner.user ? owner.user.name : ""}
            </B2NormalTextTitle>
            {type === 1155 && (
              <>
                <B2NormalTextTitle className="mt-4">
                  Enter quantity
                </B2NormalTextTitle>
                <FlexJustifyBetweenDiv className="mt-2">
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter quantity"
                    min={1}
                    max={available_copies}
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </FlexJustifyBetweenDiv>
                {quantity < 1 ? (
                  <NormalTextTitle className="mt-2 danger-color">
                    Please input valid quantity
                  </NormalTextTitle>
                ) : (
                  ""
                )}
                {available_copies && quantity > available_copies ? (
                  <NormalTextTitle className="mt-2 danger-color">
                    You can not input greater than {available_copies}.
                  </NormalTextTitle>
                ) : (
                  ""
                )}
              </>
            )}
            <B2NormalTextTitle className="mt-4">You pay</B2NormalTextTitle>
            <FlexJustifyBetweenDiv className="mt-2">
              <div>{price}</div>
              <B2NormalTextTitle>{token.blockchain}</B2NormalTextTitle>
            </FlexJustifyBetweenDiv>
            <DivideLine className="mt-2 buy-token-modal__divider"></DivideLine>
            <FlexJustifyBetweenDiv className="mt-3">
              <div>Your balance</div>
              <B2NormalTextTitle>
                {balance} {token.blockchain}
              </B2NormalTextTitle>
            </FlexJustifyBetweenDiv>
            <FlexJustifyBetweenDiv className="mt-2">
              <div>Service fee</div>
              <B2NormalTextTitle>
                {serviceFee} {token.blockchain}
              </B2NormalTextTitle>
            </FlexJustifyBetweenDiv>
            <FlexJustifyBetweenDiv className="mt-2">
              <div>You will pay</div>
              <B2NormalTextTitle>
                {price + serviceFee} {token.blockchain}
              </B2NormalTextTitle>
            </FlexJustifyBetweenDiv>
            <Button
              variant="primary"
              className="full-width mt-3 outline-btn"
              disabled={
                type === 1155 && available_copies
                  ? quantity > available_copies
                  : false
              }
              onClick={() => {
                directBuy();
              }}
            >
              <span>Proceed to payment</span>
            </Button>
            <Button variant="link" className="full-width" onClick={handleClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BuyTokenModal;
