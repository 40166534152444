import configs from "configs";
import NftController from "controller/NftController";
import smartContract from "ethereum/Contract1155";
import ethUtil from "ethereum/EthUtil";
import { web3 } from "ethereum/OnBoard";
import { NftCreateStatus } from "model/NftCreateStatus";
import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import useState from "react-usestateref";
import Utility from "service/utility";
import { useAppDispatch, useInterval } from "store/hooks";
import { getWalletBalance } from "store/User/user.slice";
import CreateERC1155OfferModal from "./CreateERC1155OfferModal";

const CreateERC1155Modal: React.FC<any> = ({
  network,
  show,
  collectible,
  onClose,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [, setProcessStatus, processStatusRef] = useState(false);
  const [nftFromDB, setNftFromDB, nftFromDBRef] = useState<any>(null);
  const [chainId, setChainId] = useState(null);
  const [, setIsSpeedUpLoading, isSpeedUpLoadingRef] = useState(false);
  const [createNftStatus, setCreateNftStatus, createNftStatusRef] = useState(
    NftCreateStatus.NONE
  );

  const errorInterval = useInterval();
  const txInterval = useInterval();

  useEffect(() => {
    if (!show) return;

    if (
      createNftStatus === NftCreateStatus.NONE ||
      createNftStatus === NftCreateStatus.MINT_FAILED
    ) {
      createNft();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  
  useEffect(() => {
    setNftFromDB(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  const getPureNftObj = () => {
    return {
      name: collectible.name,
      description: collectible.description,
      type: collectible.type,
      media: collectible.media,
      preview: collectible.preview,
      royalties: collectible.royalties,
      categories: collectible.categories,
      collection: collectible.collection,
      locked: collectible.locked,
      offchain: collectible.offchain,
      copies: collectible.copies,
      blockchain: ethUtil.getCurrencyByNetworkName(),
      contract: smartContract.getEngine1155Address(),
      chain_address: smartContract.getXsigma1155Address(),
    };
  };

  const createNftCallback = async (offerId: any, transactionHash: any) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    const nftObj = getPureNftObj();
    const formData = Utility.getFormDataFromObject({
      ...nftObj,
      chain_id: offerId,
    });
    const result = await NftController.create(formData);

    await ethUtil.checkAndWaitNextBlock(transactionHash);

    setChainId(offerId);
    dispatch(getWalletBalance());
    setCreateNftStatus(NftCreateStatus.MINT_SUCCEED);
    setNftFromDB(result);

    if (!collectible.is_auction && collectible.offer_price === 0) {
      NotificationManager.success("Nft is created successfully.", "Success");
      onClose();
      onSuccess();
      setTimeout(function () {
        // @ts-ignore
        document.getElementById("layout").scrollTo({
          // @ts-ignore
          top: 0,
          behavior: "smooth",
        });
      }, 200);
    }
  };

  const rejectCreateNftCallback = (error?: any) => {
    if (
      error?.message?.startsWith("Transaction was not mined within 50 blocks")
    )
      return;

    if (createNftStatusRef.current === NftCreateStatus.MINT_FAILED) return;

    clearInterval(txInterval.current);
    txInterval.current = null;
    clearInterval(errorInterval.current);
    errorInterval.current = null;

    setProcessStatus(true);

    setCreateNftStatus(NftCreateStatus.MINT_FAILED);
    NotificationManager.error("Create NFT failed", "Error");

    if (error) {
      ethUtil.catchError(error);
    }
  };

  const handleOnSpeedUpCreateNft = async (nonce: any, contract: any) => {
    if (isSpeedUpLoadingRef.current) return;

    setIsSpeedUpLoading(true);

    const speedUpData = await ethUtil.catchSpeedUpOrCancelTx(
      nonce,
      rejectCreateNftCallback,
      "TransferSingle",
      contract
    );

    if (!speedUpData) return setIsSpeedUpLoading(false);

    const tokenId = ethUtil.getDataFromEvent(speedUpData.event, "id");

    const transactionHash = speedUpData.tx.hash;

    await createNftCallback(tokenId, transactionHash);

    setIsSpeedUpLoading(false);
  };

  const handleOnTransactionHashCreateNft = async (transactionHash: any) => {
    const xSigmaContract = smartContract.getXsigma1155Contract();

    let nonce: any;

    if (transactionHash) {
      var counter = configs.TIMEOUT;

      txInterval.current = setInterval(async function () {
        if (!nonce) {
          const tx = await ethUtil.getTransaction(transactionHash);

          if (tx) {
            nonce = tx.nonce;
          }
        }

        counter--;
        if (counter % 20 === 0) {
          if (isSpeedUpLoadingRef.current) return;
          
          const tx = await ethUtil.getTransaction(transactionHash);
          const receipt = await ethUtil.getTransactionReceipt(transactionHash);

          console.log({ tx, receipt });

          if (!tx && !receipt) {
            await handleOnSpeedUpCreateNft(nonce, xSigmaContract);
          }

          if (receipt && receipt.status === true && !processStatusRef.current) {
            const tokenId = await ethUtil.getDataFromTxReceipt(
              receipt,
              "id",
              xSigmaContract,
              "TransferSingle"
            );

            console.log("tokenId: " + tokenId);

            if (tokenId && !chainId && txInterval.current) {
              await createNftCallback(tokenId, transactionHash);
            }
          } else if (
            receipt &&
            receipt.status === false &&
            !processStatusRef.current
          ) {
            rejectCreateNftCallback();
          }
        }

        if (processStatusRef.current) {
          clearInterval(errorInterval.current);
          errorInterval.current = null;
          clearInterval(txInterval.current);
          txInterval.current = null;
        }
      }, 1000);
    }
  };

  const handleOnReceiptCreateNft = async (receipt: any) => {
    if (receipt && receipt.status === true && !processStatusRef.current) {
      const tokenId = ethUtil.getDataFromEvent(
        receipt.events.TransferSingle,
        "id"
      );

      await createNftCallback(tokenId, receipt.transactionHash);
    } else if (
      receipt &&
      receipt.status === false &&
      !processStatusRef.current
    ) {
      rejectCreateNftCallback();
    }
  };

  const createNft = async () => {
    setCreateNftStatus(NftCreateStatus.MINT_PROGRESSING);

    if (!nftFromDBRef.current) {
      const royalties = collectible.royalties * 100 || 0;
      const locked_content = collectible.locked || "";
      const amount = collectible.copies;

      try {
        if (web3) {
          const xSigmaContract = smartContract.getXsigma1155Contract();

          const gasEstimate: any = await xSigmaContract.methods
            .createItem(amount, royalties, locked_content)
            .estimateGas({
              from: ethUtil.getAddress(),
              data: await smartContract.getXsigma1155Bytecode(),
            });

          if (gasEstimate) {
            let hash = "";
            setProcessStatus(false);

            await xSigmaContract.methods
              .createItem(amount, royalties, locked_content)
              .send({
                from: ethUtil.getAddress(),
                gas: gasEstimate + configs.GAS_LIMIT,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
                ...((await ethUtil.getGasPrice()) || {}),
              })
              .on("transactionHash", async function (transactionHash: any) {
                hash = transactionHash;
                await handleOnTransactionHashCreateNft(transactionHash);
              })
              .on("receipt", async function (receipt: any) {
                await handleOnReceiptCreateNft(receipt);
              })
              .on("error", async function (error: any, receipt: any) {
                await ethUtil.txErrorHandler(
                  error,
                  hash,
                  processStatusRef.current,
                  errorInterval,
                  setProcessStatus,
                  setCreateNftStatus,
                  NftCreateStatus.MINT_PROGRESSING,
                  NftCreateStatus.MINT_FAILED
                );
              });
          }
        }
      } catch (error) {
        rejectCreateNftCallback(error);
      }
    }
  };

  return (
    <CreateERC1155OfferModal
      show={show}
      innerCreateNftStatus={createNftStatus}
      collectible={collectible}
      onClose={onClose}
      onSuccess={onSuccess}
      createNft={createNft}
      token={nftFromDB?.token}
      chainId={chainId}
    ></CreateERC1155OfferModal>
  );
};

export default CreateERC1155Modal;
