import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  FlexAlignCenterDiv,
  LargeTextTitle,
  B2NormalTextTitle,
} from "components/common/common.styles";
import Layout from "components/Layout";
import "./Collectible.scss";
import collectibleImage from "assets/imgs/collectible.png";
import starImage from "assets/imgs/star.png";
import { Link } from "react-router-dom";

interface CollectibleProps {}

const Collectible: React.FC<CollectibleProps> = () => {

  return (
    <Layout className="collectible-container">
      <Row className="mb-5 row-reserve">
        <Col lg="6">
          <Row>
            <Col xl="9">
              <LargeTextTitle className="create-collectible-txt">
                <span>Create</span>
                <br /> Collectible
              </LargeTextTitle>
              <B2NormalTextTitle className="mt-3 mr-3 grey-color">
                Choose “Single” if you want to sell one copy of your collectible.
              </B2NormalTextTitle>
              <B2NormalTextTitle className="mr-3 grey-color">
                Choose “Multiple” if you want to sell multiple copies.
              </B2NormalTextTitle>
            </Col>
          </Row>

          <div className="d-flex mt-5 flex-wrap">
            <Link to="/create-collectible/erc721">
              <Button className="default-btn-size mr-4 outline-btn mb-3">
                <span>Create Single</span>
              </Button>
            </Link>
            <Link to="/create-collectible/erc1155">
              <Button className="default-btn-size outline-btn mb-3">
                <span>
                  Create Multiple
                </span>
              </Button>
            </Link>
          </div>
        </Col>
        <Col lg="6" className="pt-5">
          <img
            src={collectibleImage}
            className="collectible-img"
            alt="collectibleImage"
          />
          <FlexAlignCenterDiv>
            <img src={starImage} className="star-image mr-4" alt="starImage" />
            <p className="mt-4 mr-4">
              We do not own your private keys and cannot access your funds
              without your confirmation
            </p>
          </FlexAlignCenterDiv>
        </Col>
      </Row>
    </Layout>
  );
};

export default Collectible;
