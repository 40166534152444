/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import {
  B1NormalTextTitle,
  NftAvatar,
  SmallTextTitleGrey,
} from "../common/common.styles";
import "./TokenView.scss";
import configs from "configs";
import { Button, Image } from "react-bootstrap";
import ResellNftModal from "./ResellNftModal";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getMyInfo } from "store/User/user.selector";
import { getWalletBalance } from "store/User/user.slice";
import { switchNetwork } from "../../store/User/user.slice";
import Utility from "service/utility";
import EthUtil from "ethereum/EthUtil";
import CreateERC721OfferModal from "pages/CreateCollectible/CreateERC721OfferModal";
import verifyBadge from "assets/imgs/verify.png";
import imgAvatar from "assets/imgs/avatar.svg";

import {
  getETHUSDTCurrency,
  getMATICUSDTCurrency,
  getBNBUSDTCurrency,
} from "store/Nft/nft.selector";
import CreateERC1155OfferModal from "pages/CreateCollectible/CreateERC1155OfferModal";
interface TokenViewProps {
  item: any;
  user: any;
  resaleSucced?: any;
}

const TokenView: React.FC<TokenViewProps> = ({ item, user, resaleSucced }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showResellDialog, setShowResellDialog] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [ercType] = useState(item.type || 721);
  const loggedInUserInfo = useAppSelector(getMyInfo);
  const [offer, setOffer] = useState<any>(null);
  const [owner, setOwner] = useState({
    copies: 0,
    date: "",
    price: 0,
    user: "",
    on_sale: 0,
  });

  const ethDollarPrice = useAppSelector(getETHUSDTCurrency);
  const maticDollarPrice = useAppSelector(getMATICUSDTCurrency);
  const bnbDollarPrice = useAppSelector(getBNBUSDTCurrency);

  const resellFormData = useRef({
    min_bid_price: 0,
    duration: 0,
    offer_price: 0,
    copies: 1,
  });

  useEffect(() => {
    getOwner();
    getOffer();
  }, [item]);

  const getOffer = () => {
    if (item.offers && item.offers.length > 0) {
      const isExist = item.offers.find((e: any) => {
        return (e.creator = user._id);
      });
      if (isExist) {
        setOffer(isExist);
      } else {
        setOffer(null);
      }
    } else {
      setOffer(null);
    }
  };

  const getOffers = () => {
    if (item.offers && item.offers.length > 0) {
      const offers = item.offers.filter((e: any) => {
        return (e.creator = user._id);
      });
      return offers;
    }
    return null;
  };

  const getOwner = () => {
    if (item.owners && item.owners.length > 0) {
      const isExist = item.owners.find((e: any) => {
        return e.user === user._id;
      });
      if (isExist) {
        setOwner(isExist);
      } else {
        const user = item.owners.find((e: any) => {
          const offerUser =
            offer && offer.creator ? offer.creator : item.creator._id;
          return e.user === offerUser;
        });
        if (user) {
          setOwner(user);
        }
      }
    }
  };

  const getTokenThumbnail = () => {
    let media = item.media_type && item.media_type.toLowerCase();
    if (
      media &&
      (media.includes("mp3") || media.includes("mp4") || media.includes("webm"))
    ) {
      if (item.preview) {
        return Utility.mediaValidation(item.preview);
      }
      return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
    }
    return Utility.mediaValidation(
      item.preview || item.thumbnail || item.media
    );
  };

  const getCurrentBidPrice = (e: any) => {
    if (e && e.bids && e.bids.length) {
      let bids = e.bids.sort(function (a: any, b: any) {
        return b.price - a.price;
      });
      return bids[0].price;
    }
    return 0;
  };

  const get721Owner = () => {
    if (item.owners && item.owners.length) return item.owners[0];
    return null;
  };

  const isOwner = () => {
    const owner = get721Owner();
    if (owner) {
      if (user._id === owner.user && loggedInUserInfo._id === owner.user)
        return true;
    }
    return false;
  };

  const hasResellPermission = () => {
    if (ercType === 1155) {
      const isExist = item.owners.find((e: any) => {
        return e.user === loggedInUserInfo._id && e.user === user._id;
      });
      if (isExist) return true;
      return false;
    }
    return (!offer || offer.status === "expired") && isOwner();
  };

  const isCreator = () => {
    if (offer && user._id === offer.creator) return true;
    return false;
  };

  const submitted = async (form: any) => {
    resellFormData.current = form;
    setShowStatusModal(true);
    setShowResellDialog(false);
  };

  const tokenViewClicked = (e?: any) => {
    if (item && item.token) {
      if (item.type === 1155 && offer) {
        return `/tokens/${item.token._id}?offer=${offer._id}`;
      } else {
        return `/tokens/${item.token._id}`;
      }
    } else if (offer && offer.token) {
      if (item.type === 1155 && offer) {
        return `/tokens/${offer.token}?offer=${offer._id}`;
      } else {
        return `/tokens/${offer.token}`;
      }
    } else {
      if (item.type === 1155 && offer) {
        return `/tokens/${item._id}?offer=${offer._id}`;
      } else {
        return `/tokens/${item._id}`;
      }
    }
  };

  const handleOpenModal = () => {
    const networkID = EthUtil.getNetwork();
    if (
      networkID &&
      networkID !== EthUtil.getChainIDByNetworkName(item.blockchain)
    ) {
      switchNetwork(EthUtil.getChainIDByNetworkName(item.blockchain));
      return;
    }
    dispatch(getWalletBalance());
    setShowResellDialog(true);
  };

  const goToCreatorProfile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (getCreator())
      history.push("/users/" + (getCreator()?.wallet || getCreator()?._id));
  };

  const getCreator = () => {
    if (item?.creator?.wallet) return item.creator;
    return null;
  };

  const getCreatorAvatar = () => {
    const creator = getCreator();
    if (creator && creator.avatar) {
      return Utility.mediaValidation(creator.avatar);
    }
    return imgAvatar;
  };

  return (
    <div className="token-view auction-item mr-4 p-4">
      <div
        className="auction-item__owner-wrapper"
        onClick={(e) => {
          goToCreatorProfile(e);
        }}
      >
        <NftAvatar
          className="auction-owner-avatar"
          imagePath={getCreatorAvatar()}
        >
          {getCreator() && getCreator().verified && (
            <Image style={{ width: 12, height: 12 }} src={verifyBadge} />
          )}
        </NftAvatar>

        <span className="auction-item__owner">
          BY <span>{getCreator()?.name || "NAME"}</span>
        </span>
      </div>

      <div className="token-img-area mt-2">
        <Link className="pre-token-img" to={tokenViewClicked()}>
          {item.media ? (
            <img src={getTokenThumbnail()} alt="tokenImage" />
          ) : (
            <div className="no-thumbnail"></div>
          )}
        </Link>
      </div>

      <div className="token-details-area">
        <Link to={tokenViewClicked()}>
          <B1NormalTextTitle className="mt-3">{item.name}</B1NormalTextTitle>
          <SmallTextTitleGrey className="grey-color">
            {item.collections ? item.collections.name : "xSigma"}
          </SmallTextTitleGrey>
          {ercType === 1155 ? (
            <SmallTextTitleGrey className="grey-color">
              {offer && offer.creator === owner.user && owner.on_sale
                ? `${owner.on_sale}/${owner.copies} ${
                    owner.on_sale > 1 ? "editions" : "edition"
                  } On sale`
                : owner.copies
                ? owner.copies +
                  (owner.copies > 1
                    ? " editions Not on sale"
                    : " edition Not on sale")
                : "Not for Sale"}
            </SmallTextTitleGrey>
          ) : (
            ""
          )}
        </Link>

        {getOffers() && (
          <div>
            {getOffers().length > 0
              ? getOffers().map(
                  (e: any, index: number) =>
                    index < 3 && (
                      <div key={index} className="d-flex flex-wrap grey-color">
                        {/* {e.available_copies || ''} */}
                        {e.type !== "auction" && (
                          <>
                            From{" "}
                            {EthUtil.getDollarPrice(
                              e.offer_price,
                              EthUtil.getChainIDByNetworkName(item.blockchain),
                              {
                                ethDollarPrice,
                                maticDollarPrice,
                                bnbDollarPrice,
                              }
                            )}
                            &nbsp;
                          </>
                        )}
                        {(e.type === "auction" || e.type === "both") && (
                          <div>
                            {getCurrentBidPrice(e) ? (
                              <>
                                Current Bid{" "}
                                {EthUtil.getDollarPrice(
                                  getCurrentBidPrice(e),
                                  EthUtil.getChainIDByNetworkName(
                                    item.blockchain
                                  ),
                                  {
                                    ethDollarPrice,
                                    maticDollarPrice,
                                    bnbDollarPrice,
                                  }
                                )}
                              </>
                            ) : e.type !== "direct" ? (
                              <>
                                Minimum Bid{" "}
                                {EthUtil.getDollarPrice(
                                  e.min_bid,
                                  EthUtil.getChainIDByNetworkName(
                                    item.blockchain
                                  ),
                                  {
                                    ethDollarPrice,
                                    maticDollarPrice,
                                    bnbDollarPrice,
                                  }
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    )
                )
              : ""}
            {getOffers().length > 3 && "..."}
          </div>
        )}

        {hasResellPermission() &&
          ((item.offers && !offer) ||
            (offer && offer.status === "expired") ||
            item.my_available_copies > 0) && (
            <Button
              variant="primary"
              className="full-width mt-3 outline-btn"
              onClick={() => handleOpenModal()}
            >
              <span>
                {!isCreator() ? "Put on Sale" : "Resell"}{" "}
                {item.my_available_copies
                  ? `(${item.my_available_copies})`
                  : ""}
              </span>
            </Button>
          )}
      </div>

      <ResellNftModal
        show={showResellDialog}
        handleClose={() => {
          setShowResellDialog(false);
        }}
        token={item}
        type={ercType}
        copies={item.type === 1155 ? item.my_available_copies : 0}
        isResell={isCreator()}
        handleSubmit={submitted}
      ></ResellNftModal>

      {item.type === 721 && (
        <CreateERC721OfferModal
          show={showStatusModal}
          collectible={resellFormData.current}
          token={item}
          chainId={item.chain_id}
          onClose={() => {
            setShowStatusModal(false);
          }}
          onSuccess={resaleSucced}
        ></CreateERC721OfferModal>
      )}

      {item.type === 1155 && (
        <CreateERC1155OfferModal
          show={showStatusModal}
          collectible={resellFormData.current}
          token={item}
          chainId={item.chain_id}
          onClose={() => {
            setShowStatusModal(false);
          }}
          onSuccess={resaleSucced}
        ></CreateERC1155OfferModal>
      )}
    </div>
  );
};

export default TokenView;
