import { web3, onboard } from "./OnBoard";
import Storage from "service/storage";
import configs from "configs";
import Web3 from "web3";
import LogController from "controller/LogController";
import { NotificationManager } from "react-notifications";
import Utility from "service/utility";
import { BigNumberMul } from "service/number";

class EthUtil {
  getNetwork() {
    const currentState = onboard.getState();
    if (currentState.network) {
      return currentState.network;
    }
    return parseInt(Storage.get(configs.STORAGE.SELECTED_NETWORK));
  }

  getAddress() {
    const currentState = onboard.getState();
    if (currentState.address) return currentState.address;
    return "";
  }

  getWeb3Reader(network?: any) {
    const net = network || this.getNetwork();

    const RPC_MAP = {
      [configs.ONBOARD_NETWORK_ID]: configs.RPC_URL,
      [configs.ONBOARD_RINKEBY_ID]: configs.RINKEBY_RPC_URL,
      [configs.ONBOARD_POLYGON_ID]: configs.POLYGON_RPC_URL,
      [configs.ONBOARD_MUMBAI_ID]: configs.MUMBAI_RPC_URL,
      [configs.ONBOARD_BSC_ID]: configs.BSC_RPC_URL,
      [configs.ONBOARD_BSC_TEST_ID]: configs.BSC_TEST_RPC_URL,
    };

    return new Web3(new Web3.providers.HttpProvider(RPC_MAP[net]))
  }

  async getTransactionResolvedTx(txHash: any) {
    const tx = await this.getTransaction(txHash)

    let interval: any = null;

    if (!tx?.blockNumber) {
      return new Promise((resolve) => {
        interval = setInterval(async () => {
          const txInterval = await this.getTransaction(txHash)

          if (txInterval?.blockNumber) {
            resolve(txInterval);
            clearInterval(interval);
            interval = null;
          }
        }, 5000);
      });
    }

    return tx;
  }

  async getTransaction(txHash: any, retry = 1) {
    const tx = await Utility.tryCatchRepeat(() => new web3.eth.getTransaction(txHash), retry, true, 1000)

    return tx;
  }

  async getTransactionReceipt(txHash: any, retry = 1) {
    const tx = await Utility.tryCatchRepeat(() =>new web3.eth.getTransactionReceipt(txHash), retry, true, 1000)

    return tx;
  }

  async checkAndWaitNextBlock(txHash?: any) {
    const block = await new web3.eth.getBlockNumber();
    const currentBlock: any = txHash ? (await this.getTransactionResolvedTx(txHash))?.blockNumber : block;

    let interval: any = null;

    if (block <= currentBlock) {
      return new Promise((resolve) => {
        interval = setInterval(async () => {
          const blockInterval = await new web3.eth.getBlockNumber();

          if (blockInterval > currentBlock) {
            resolve('true')
            clearInterval(interval)
            interval = null
          }
        }, 5000)
      })
    }

    return true;
  }

  async getTransactionOptions(
    contract: any,
    method: any,
    args: any,
    optionsArgs = {},
  ) {
    let gasPriceResponse: any = await this.getGasPrice()
    let gasLimitResponse: any = await this.getGasEstimate(contract, method, [
      ...args,
      optionsArgs,
    ])

    if (!gasPriceResponse.gasPrice) {
      gasPriceResponse = {}
    }

    if (!gasLimitResponse.gasLimit) {
      gasLimitResponse = {}
    } else {
      gasLimitResponse.gasLimit = Number(gasLimitResponse.gasLimit.toFixed(0))
    }

    return [
      ...args,
      {
        ...gasLimitResponse,
        ...gasPriceResponse,
        ...optionsArgs,
      },
    ]
  }

  async getGasEstimate(contract: any, method: any, args: any) {
    try {
      const response = await Utility.tryCatchRepeat(
        () => contract.estimateGas[method](...args),
        5,
        true,
      )

      return {
        gasLimit: Number(response) ? Number(response) * 1.2 : configs.GAS_LIMIT,
      }
    } catch (error) {
      return { gasLimit: configs.GAS_LIMIT }
    }
  }

  async getGasPrice(isGwei = true) {
    const GAS_PRICE_NETWORK_MAPPER: any = {
      137: {
        api: `https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=P4YYDB31HV1EF4PZZU62US9ZD74J7DG6AI`,
        gasPriceGetter: (data: any) => {
          if (data?.result?.FastGasPrice) {
            return Number(data.result.FastGasPrice);
          } else {
            return null;
          }
        },
        defaultGasPrice: 46,
        minGasPrice: 35,
      },
      80001: {
        defaultGasPrice: 100,
      },
      1: {
        api: "https://api.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=IW6HQX61IVIY58D6YMRW87AZUWUQNY2KFE",
        gasPriceGetter: (data: any) => {
          if (data?.result) {
            return Number(this.fromWei(data.result));
          } else {
            return null;
          }
        },
        defaultGasPrice: 29,
        minGasPrice: 29,
      },
      4: {
        api: "https://api-rinkeby.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=YourApiKeyToken",
        gasPriceGetter: (data: any) => {
          if (data?.result) {
            return Number(this.fromWei(data.result));
          } else {
            return null;
          }
        },
        defaultGasPrice: 29,
        minGasPrice: 29,
      },
      56: {
        api: "https://api.bscscan.com/api?module=gastracker&action=gasoracle&apikey=TI9F4VKBFXJFRRCGFV6HU2HPA7EEJKUWGE",
        gasPriceGetter: (data: any) => {
          if (data?.result?.FastGasPrice) {
            return Number(data.result.FastGasPrice);
          } else {
            return null;
          }
        },
        defaultGasPrice: 5,
        minGasPrice: 5,
      },
      97: {
        defaultGasPrice: 10,
        minGasPrice: 10,
      },
    }

    try {
      const network: any = this.getNetwork()
      const defaultGasPrice =
        GAS_PRICE_NETWORK_MAPPER?.[network]?.defaultGasPrice

      if (!GAS_PRICE_NETWORK_MAPPER?.[network]?.api) {
        return {
          gasPrice: isGwei
            ? defaultGasPrice && this.toWei(defaultGasPrice.toString(), 'gwei')
            : defaultGasPrice,
        }
      }

      const data = await Utility.tryCatchRepeat(() => fetch(GAS_PRICE_NETWORK_MAPPER[network]?.api));

      if (!data)
        return {
          gasPrice: isGwei
            ? defaultGasPrice && this.toWei(defaultGasPrice.toString(), 'gwei')
            : defaultGasPrice,
        }

      const gasPriceGwei =
        GAS_PRICE_NETWORK_MAPPER[network].gasPriceGetter(data);

      return {
        gasPrice: isGwei
          ? this.toWei(gasPriceGwei.toString(), 'gwei')
          : gasPriceGwei,
      }
    } catch (error) {
      this.catchError(error)

      return { gasPrice: undefined };
    }
  }

  async getBalance() {
    if (web3) {
      const web3Reader = this.getWeb3Reader();
      let balance = await web3Reader.eth.getBalance(this.getAddress());
      let ethBalance = this.fromWei(balance);
      return ethBalance;
    }

    return 0;
  }

  getChainIDByNetworkName(networkName?: string) {
    const net = networkName || Utility.getNetworkNameByNetworkId(this.getNetwork())

    if (net) {
      switch (net) {
        case 'ETH':
          return configs.ONBOARD_NETWORK_ID
        case 'RINKEBY':
          return configs.ONBOARD_RINKEBY_ID
        case 'MATIC':
          return configs.ONBOARD_POLYGON_ID
        // return configs.ONBOARD_MUMBAI_ID;
        case "BNB":
          return configs.ONBOARD_BSC_ID;
        // return configs.ONBOARD_BSC_TEST_ID
        default:
          return configs.ONBOARD_NETWORK_ID;
      }
    } else {
      return configs.ONBOARD_NETWORK_ID;
    }
  }

  getCurrencyByNetworkName(networkName?: string) {
    const net = networkName || Utility.getNetworkNameByNetworkId(this.getNetwork())
    
    if(net) {
      switch (net) {
        case 'ETH':
          return 'ETH'
        case 'RINKEBY':
          return 'ETH'
        case 'MATIC':
          return 'MATIC'
        // return configs.ONBOARD_MUMBAI_ID;
        case "BNB":
          // return configs.ONBOARD_BSC_ID;
          return "BNB";
        default:
          return "ETH";
      }
    } else {
      return "ETH";
    }
  }

  toWei(number: any, unit: any = "ether") {
    const UNIT_FIXED_MAPPER: any = {
      gwei: 9,
      ether: 18,
    };

    return web3.utils.toWei(
      Number(number).toFixed(UNIT_FIXED_MAPPER[unit]),
      unit
    );
  }

  fromWei(number: any, unit?: any) {
    return web3.utils.fromWei(number.toString(), unit);
  }

  async catchError(error: any) {
    if (
      error &&
      error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
    )
      return;

    if (window.location.hostname === "localhost") {
      console.log({ error });
    } else {
      LogController.addErrorLogs({
        message: error.message || error,
        hash: "",
        wallet: this.getAddress(),
        data: error?.response ? { ...error?.response } : { ...error },
      });
    }

    if (error?.response?.data?.error) {
      return NotificationManager.error(
        error.response.data.error,
        "Error",
        10000
      );
    }

    if (!error?.message) return;

    const METAMASK_ERROR_MAPPER = [
      {
        error: "Internal error",
        getMessage: async () =>
          `Network is full right now. Please click the "try again" method in 20 seconds`,
      },
      {
        error: "owner query for nonexistent token",
        getMessage: async () =>
          `Token doesn't exist. Make sure minting has been completed.`,
      },
      {
        error: "try again in a few minutes",
        getMessage: async () =>
          `Network is full right now. Please click the "try again" method in 20 seconds`,
      },
      {
        error: "header not found",
        getMessage: async () =>
          `Network is full right now. Please click the "try again" method in 20 seconds`,
      },
      {
        error: "transaction underpriced",
        getMessage: async () => {
          const gasPriceObj = await this.getGasPrice(false);
          return `Failed: gas fee is too low and the transaction is not sent to the blockchain. Increase the gas fee (recommended value is ${gasPriceObj.gasPrice}) and try again`;
        },
      },
    ];

    const errorMessage =
      typeof error.message === "object"
        ? JSON.stringify(error.message)
        : error.message;

    const messageObj = METAMASK_ERROR_MAPPER.find((item) => {
      if (errorMessage.indexOf(item.error) === -1) {
        return false;
      }

      return true;
    });

    if (!messageObj) return;

    const message = await messageObj.getMessage();

    if (message) {
      NotificationManager.error(message, "Error", 10000);
    }
  }

  async getDataFromTxReceipt(
    receipt: any,
    searchKey: any,
    contract: any,
    eventKey: any,
  ) {
    const events = await contract.getPastEvents(eventKey, {
      fromBlock: receipt.blockNumber,
      toBlock: receipt.blockNumber,
    })

    const data = events?.[0]?.returnValues?.[searchKey] || '0'

    return data
  }

  getDollarPrice = (ethValue: any, network: any, prices: any) => {
    if (network) {
      switch (network) {
        case configs.ONBOARD_POLYGON_ID:
          return Utility.toFixedOrPrecision(
            BigNumberMul(ethValue, prices.maticDollarPrice).toNumber()
          );

        case configs.ONBOARD_MUMBAI_ID:
          return Utility.toFixedOrPrecision(
            BigNumberMul(ethValue, prices.maticDollarPrice).toNumber()
          );

        case configs.ONBOARD_NETWORK_ID:
          return Utility.toFixedOrPrecision(
            BigNumberMul(ethValue, prices.ethDollarPrice).toNumber()
          );

        case configs.ONBOARD_RINKEBY_ID:
          return Utility.toFixedOrPrecision(
            BigNumberMul(ethValue, prices.ethDollarPrice).toNumber()
          );

        case configs.ONBOARD_BSC_ID:
          return Utility.toFixedOrPrecision(
            BigNumberMul(ethValue, prices.bnbDollarPrice).toNumber()
          );

        case configs.ONBOARD_BSC_TEST_ID:
          return Utility.toFixedOrPrecision(
            BigNumberMul(ethValue, prices.bnbDollarPrice).toNumber()
          );
        default:
          return 0;
      }
    }
  };

  getDataFromEvent(event: any, searchKey: any) {
    return event?.returnValues?.[searchKey] || '0'
  }

  async findTransactionSpeedUp(eventKey: any, contract: any, nonce: any) {
    const currentBlock = await new web3.eth.getBlockNumber()

    console.log({ currentBlock, eventKey, nonce })

    const events = await Utility.tryCatchRepeat(
      () => contract.getPastEvents(eventKey, {
        fromBlock: currentBlock - 1000,
        toBlock: 'latest',
      }),
      3,
      true,
    )
    
    const eventsArrayPromise: any = [];
    
    events.forEach((item: any) => {
      eventsArrayPromise.push(ethUtil.getTransaction(item.transactionHash))
    });

    const eventsArray = await Promise.all(eventsArrayPromise);

    console.log({ eventsArray, events });

    const txIndex = eventsArray.findIndex((item: any) => item.nonce === nonce)

    return txIndex === -1 ? null : { tx: eventsArray[txIndex], event: events[txIndex] }
  }

  async catchSpeedUpOrCancelTx(nonce: any, rejectCallback: Function, eventKey: any, contract: any) {
      await this.checkAndWaitNextBlock();

      const txCounts = await new web3.eth.getTransactionCount(
        this.getAddress(),
      )

      if (nonce > txCounts - 1) {
        rejectCallback();
        return;
      }

      const speedUpData = await Utility.tryCatchRepeat(
        () => this.findTransactionSpeedUp(eventKey, contract, nonce),
        2,
        true,
        10000,
      )
      
      console.log({speedUpData})
      
      if (!speedUpData) {
        rejectCallback()
        return;
      }

      return speedUpData;
  }

  async txErrorHandler(
    error: any,
    hash: any,
    processStatus: any,
    errorInterval: any,
    setProcessStatus: any,
    setNftStatus: any,
    progressStatus: any,
    failedStatus: any,
  ) {
    if(!hash) return;

    const tx = await new web3.eth.getTransaction(hash)

    if (!tx || processStatus) return

    if (error && error.code !== 4001 && error.code !== -32603) {

      NotificationManager.success(
        'Processing. Don´t close this modal!',
        'Alert',
      )

      setNftStatus(progressStatus)

      var counter = configs.TIMEOUT

      if (error) {
        errorInterval.current = setInterval(async () => {
          console.log('error - ' + counter)
          counter--

          if (counter % 60 === 0 && counter > 0) {
            NotificationManager.warning(
              'We are still waiting for the transaction to be mined. Be patient',
              'Waiting',
            )
          }

          if (counter < 0) {
            setProcessStatus(true)

            NotificationManager.info(
              '10 minutes pass and your transaction has not yet been mined, but can be mined later. Open this page again after a few minutes, you can also speed up the transaction or cancel the transaction and try again.',
              'Alert',
            )

            Utility.downloadFile(error.message || error)
            setNftStatus(failedStatus)
            LogController.addErrorLogs({
              message: error.message || error,
              hash,
              wallet: this.getAddress(),
              data: error,
            })
            clearInterval(errorInterval.current)
            errorInterval.current = null;
          }
        }, 1000)
      }
    }
  }
}

const ethUtil = new EthUtil();

export default ethUtil;
