/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Image,
  Nav,
  Tab,
  // Dropdown
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Layout from "components/Layout";
import TokenView from "components/token/TokenView";
import {
  BigTitle,
  MidTextTitle,
  B2NormalTextTitleGrey,
  SmallTextTitleGrey,
  SmallTextTitle,
  NormalTextTitle,
  SubDescription,
  FilterIcon,
} from "components/common/common.styles";
import TokenController from "controller/TokenController";
import CollectionController from "controller/CollectionController";
import LoadingBar from "components/common/LoadingBar";
import imgAvatar from "assets/imgs/avatar.svg";
import imgCopyIcon from "assets/imgs/copyicon.svg";
import NoItem from "components/common/noItem";

import "./MyItems.scss";
import { useAppSelector } from "store/hooks";
import { getWalletAddress } from "store/User/user.selector";
import {
  FaTrash,
  // FaFacebook,
  // FaMailBulk,
  // FaTelegram,
  // FaTwitter
} from "react-icons/fa";
import UserController from "controller/UserController";
import Utility from "service/utility";
import configs from "configs";
import CollectionItem from "components/collection/CollectionItem";
import ActivityItem from "components/token/ActivityItem";
import OnSaleItem from "components/myItems/OnSaleItem";
import UserItem from "components/user/userItem";
import TokenItem from "components/token/TokenItem";
import { FaTag, FaHeart, FaCheck } from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { RiAuctionFill } from "react-icons/ri";
import { ImDiamonds } from "react-icons/im";
// import { AiOutlineCloudUpload } from 'react-icons/ai';
import defaultCover from "assets/imgs/default_pic.png";
import verifyBadge from "assets/imgs/verify.png";

interface MyItemProps {}

const _categories = [
  { title: "On sale", count: 0, active: false, path: "on_sale" },
  { title: "Owned", count: 0, active: false, path: "owned" },
  { title: "Created", count: 0, active: false, path: "created" },
  { title: "Liked", count: 0, active: false, path: "liked" },
  { title: "Activity", count: 0, active: false, path: "activity" },
  { title: "Following", count: 0, active: false, path: "following" },
  { title: "Followers", count: 0, active: false, path: "followers" },
  { title: "Royalties", count: 0, active: false, path: "royalties" },
];

const _filters = [
  { name: "Listings", selected: false, filter: "listed", icon: <FaTag /> },
  {
    name: "Purchases",
    selected: false,
    filter: "purchased",
    icon: <ImDiamonds />,
  },
  {
    name: "Sales",
    selected: false,
    filter: "minted",
    icon: <BsLightningFill />,
  },
  {
    name: "Transfers",
    selected: false,
    filter: "transfered",
    icon: <BiTransfer />,
  },
  { name: "Bids", selected: false, filter: "offered", icon: <RiAuctionFill /> },
  { name: "Likes", selected: false, filter: "liked", icon: <FaHeart /> },
  {
    name: "Followings",
    selected: false,
    filter: "following",
    icon: <FaCheck />,
  },
];

const _userInfo = {
  id: "",
  name: "",
  wallet: "",
  date_create: "",
  avatar: "",
  cover: "",
  verified: false,
};

const _royalty = {
  royalties: 0,
  tokens: 0,
  collections: 0,
};

const MyItems: React.FC<MyItemProps> = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNumber] = useState(1);
  const [userInfo, setUserInfo] = useState(_userInfo);
  const [categories, setCategories] = useState(_categories);
  const [itemList, setItemList] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedTab, setSelectedTab] = useState("owned");
  const walletAddress = useAppSelector(getWalletAddress);
  const [walletHiddenText, setHiddenText] = useState(walletAddress);
  const [uploadCoverImage, setUploadCoverImage] = useState(null);
  const [backgroundCoverImage, setCoverImage] = useState<any>(null);
  const [filters] = useState(_filters);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [royalty, setRoyalty] = useState(_royalty);

  useEffect(() => {
    loadTokensAndCollections();
    setHiddenText(Utility.getHiddenWalletAddress(walletAddress));
  }, [categories, walletAddress]);

  useEffect(() => {
    loadItems();
  }, [selectedTab, pageNum, walletAddress, selectedFilter]);

  const getFilterClass = (val: string) => {
    if (selectedFilter === val) return "filter-btn active";
    return "filter-btn";
  };

  const getFilterColor = (type: String) => {
    if (selectedFilter === type) {
      switch (type) {
        case "following":
          return "rgb(109, 188, 0)";
        case "liked":
          return "rgb(255, 158, 18)";
        case "listed":
          return "rgb(0, 147, 255)";
        case "purchased":
          return "rgb(255, 199, 90)";
        case "minted":
          return "rgb(235, 88, 73)";
        case "transfered":
          return "rgb(177, 89, 220)";
        case "offered":
          return "rgb(247, 109, 192)";
        default:
          break;
      }
    }
  };

  const loadTokensAndCollections = async () => {
    if (walletAddress) {
      let data = await UserController.userStats(walletAddress);
      if (data.user) {
        setUserInfo(data.user);

        if (data.user.cover) {
          setCoverImage(`${Utility.mediaValidation(data.user.cover)}`);
        }
      }
      if (data.stats) {
        for (let i = 0; i < categories.length; i++) {
          const category = categories[i];
          if (data.stats[category.path]) {
            category.count = data.stats[category.path];
          } else {
            category.count = 0;
          }
        }
        setCategories(categories);
      }

      setLoadingUser(false);
    }

    let collections = await CollectionController.getMyCollections();
    setCollections(collections);
  };

  const resaleSucced = () => {
    loadTokensAndCollections();
    loadItems();
  };

  const loadItems = async () => {
    if (walletAddress && selectedTab !== "royalties") {
      setLoading(true);
      let { items, pages } = await TokenController.getItems(
        walletAddress,
        selectedTab,
        pageNum,
        selectedFilter
      );
      if (pageNum === 1) {
        setPages(pages);
      }
      setItemList(pageNum === 1 ? items : itemList.concat(items));
      setLoading(false);
    } else if (selectedTab === "royalties") {
      let data = await TokenController.getItems(walletAddress, selectedTab, 1);
      setRoyalty(data);
    }
  };

  const onSelectType = (type: any) => {
    setSelectedTab(type);
    setPages(1);
    setPageNumber(1);
  };

  const copyWalletAddress = async () => {
    setHiddenText("Copied!");
    setTimeout(() => {
      setHiddenText(Utility.getHiddenWalletAddress(walletAddress));
    }, 2000);
    await navigator.clipboard.writeText(walletAddress);
  };

  const copyName = async () => {
    if (userInfo) {
      let element = document.querySelector(".copy-icon");
      element?.classList.add("clicked");
      setTimeout(() => {
        element?.classList.remove("clicked");
      }, 1000);
      await navigator.clipboard.writeText(`${userInfo.name}`);
    }
  };

  const getUserImgAvatar = () => {
    if (userInfo.avatar) return Utility.mediaValidation(userInfo.avatar);
    return imgAvatar;
  };

  const fileChanged = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setUploadCoverImage(file);

      var reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setCoverImage(reader.result || "");
        },
        false
      );
      reader.readAsDataURL(file);
    }
  };

  const onUploadImage = () => {
    fileInputRef?.current?.click();
  };

  const onSaveImage = async () => {
    let data = {
      image: uploadCoverImage,
    };
    let formdata = Utility.getFormDataFromObject(data);
    await UserController.uploadCover(formdata)
      .then((res) => {
        if (res && res.status === 200) {
          NotificationManager.success(
            uploadCoverImage
              ? "Successfully uploaded!"
              : "Successfully removed!",
            "Success"
          );
          setUploadCoverImage(null);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          NotificationManager.error(err.response.data.error, "Error");
        }
      });
  };

  const onRemoveImage = async () => {
    if (window.confirm("Do you want remove cover image?")) {
      setCoverImage("");
      await onSaveImage();
    }
  };

  const onSelectFilter = (filter: String) => {
    if (selectedFilter === filter || filter === "") {
      setSelectedFilter("");
    } else {
      setSelectedFilter(`${filter}`);
    }
    setPageNumber(1);
  };

  return (
    <Layout className="myitems-container">
      {loadingUser ? (
        <div className="mt-5 mb-5 d-flex justify-content-center loading-bar">
          <LoadingBar />
        </div>
      ) : (
        <>
          <div className="section-1">
            <div
              className="intro"
              style={{
                backgroundImage: `url("${
                  backgroundCoverImage || defaultCover
                }")`,
              }}
            >
              <Image
                src={getUserImgAvatar()}
                className="avatar"
                roundedCircle
              />
              {userInfo && userInfo.verified && (
                <Image src={verifyBadge} className="verify-badge" />
              )}
              <div
                className="clip-board"
                onClick={() => {
                  copyWalletAddress();
                }}
              >
                <SubDescription id="wallet">{walletHiddenText}</SubDescription>
              </div>
              {uploadCoverImage ? (
                <Button
                  variant="outline-white"
                  className="btn-round cover-btn save-btn outline-btn"
                  onClick={() => onSaveImage()}
                >
                  <span>Save Cover</span>
                </Button>
              ) : (
                <Button
                  variant="outline-white"
                  className="btn-round cover-btn outline-btn"
                  onClick={() => onUploadImage()}
                >
                  <span>Add Cover</span>
                </Button>
              )}
              {backgroundCoverImage && (
                <div className="trach-btn text-danger text-right">
                  <FaTrash
                    className="pointer-cursor"
                    onClick={() => onRemoveImage()}
                  />
                </div>
              )}
              <input
                ref={fileInputRef}
                type="file"
                hidden
                accept={configs.IMG_FILE_ACCEPT}
                onChange={fileChanged}
              />
            </div>
          </div>
          <div className="section-2">
            <div className="d-flex justify-content-center px-3 px-md-0">
              <span className="title mr-4 faint-color font-matrice">{`${userInfo.name}`}</span>
              <Image
                className="copy-icon"
                src={imgCopyIcon}
                alt="COPY"
                onClick={() => copyName()}
              />
            </div>
          </div>
          <div className="section-3">
            <div className="d-flex justify-content-center align-items-center">
              <Link to="/profile">
                <Button
                  variant="outline-primary"
                  className="default-btn-size btn-black"
                >
                  Edit Profile
                </Button>
              </Link>
            </div>
          </div>
          <div className="section-4">
            <Tab.Container defaultActiveKey="owned" id="items_tabs">
              <div className="link-section px-md-5">
                <Nav defaultActiveKey="owned">
                  {categories.map((category, index) => (
                    <Nav.Item
                      key={index}
                      onClick={() => onSelectType(category.path)}
                    >
                      <Nav.Link eventKey={category.path}>
                        {category.title}
                        <span className={category.count ? "fill-count" : ""}>
                          {category.count}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <div className="item-columns">
                <Tab.Content>
                  <Tab.Pane eventKey="on_sale">
                    <div className="auction-section">
                      <BigTitle>On Sale Items</BigTitle>
                      {loading && pageNum === 1 ? (
                        <div className="text-center mt-5 loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="item-list mt-3 mt-md-5">
                          {itemList.length > 0 ? (
                            itemList.map((saleItem: any, i: number) => (
                              <OnSaleItem key={i} item={saleItem}></OnSaleItem>
                            ))
                          ) : (
                            <NoItem
                              title="No items found"
                              description="Come back soon! Or try to browse something for you on our marketplace"
                              btnLink="/"
                              btnLabel="Browse marketplace"
                            />
                          )}
                        </div>
                      )}
                      {loading && pageNum > 1 ? (
                        <div className="my-3 d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        itemList.length > 0 &&
                        pages > pageNum && (
                          <div className="my-3 d-flex justify-content-center">
                            <Button
                              variant="primary"
                              className="btn-round w-50 outline-btn"
                              onClick={() => setPageNumber(pageNum + 1)}
                            >
                              <span>Load More</span>
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="following">
                    <div className="auction-section">
                      <BigTitle>Followings</BigTitle>
                      {loading && pageNum === 1 ? (
                        <div className="text-center mt-5 loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="item-list mt-3 mt-md-5">
                          {itemList.length > 0 ? (
                            itemList.map((userItem, i) => (
                              <UserItem key={i} item={userItem} />
                            ))
                          ) : (
                            <NoItem
                              title="No followings found"
                              description="Come back soon! Or try to browse something for you on our marketplace"
                              btnLink="/"
                              btnLabel="Browse marketplace"
                            />
                          )}
                        </div>
                      )}
                      {loading && pageNum > 1 ? (
                        <div className="my-3 d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        itemList.length > 0 &&
                        pages > pageNum && (
                          <div className="my-3 d-flex justify-content-center">
                            <Button
                              variant="primary"
                              className="btn-round w-50 outline-btn"
                              onClick={() => setPageNumber(pageNum + 1)}
                            >
                              <span>Load More</span>
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="followers">
                    <div className="auction-section">
                      <BigTitle>Followers</BigTitle>
                      {loading && pageNum === 1 ? (
                        <div className="text-center mt-5 loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="item-list mt-3 mt-md-5">
                          {itemList.length > 0 ? (
                            itemList.map((userItem, i) => (
                              <UserItem key={i} item={userItem} />
                            ))
                          ) : (
                            <NoItem
                              title="No followers found"
                              description="Come back soon! Or try to browse something for you on our marketplace"
                              btnLink="/"
                              btnLabel="Browse marketplace"
                            />
                          )}
                        </div>
                      )}
                      {loading && pageNum > 1 ? (
                        <div className="my-3 d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        itemList.length > 0 &&
                        pages > pageNum && (
                          <div className="my-3 d-flex justify-content-center">
                            <Button
                              variant="primary"
                              className="btn-round w-50 outline-btn"
                              onClick={() => setPageNumber(pageNum + 1)}
                            >
                              <span>Load More</span>
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="liked">
                    <div className="tokens-section">
                      <BigTitle>Liked Tokens</BigTitle>
                      {loading && pageNum === 1 ? (
                        <div className="text-center mt-5 loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="item-list mt-3 mt-md-5">
                          {itemList.length > 0 ? (
                            itemList.map((item: any, i: number) => (
                              <TokenItem item={item} key={i} />
                            ))
                          ) : (
                            <NoItem
                              title="No tokens found"
                              description="Come back soon! Or try to browse something for you on our marketplace"
                              btnLink="/"
                              btnLabel="Browse marketplace"
                            />
                          )}
                        </div>
                      )}
                      {loading && pageNum > 1 ? (
                        <div className="my-3 d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        itemList.length > 0 &&
                        pages > pageNum && (
                          <div className="my-3 d-flex justify-content-center">
                            <Button
                              variant="primary"
                              className="btn-round w-50 outline-btn"
                              onClick={() => setPageNumber(pageNum + 1)}
                            >
                              <span>Load More</span>
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="created">
                    <div className="tokens-section">
                      <BigTitle>Created Tokens</BigTitle>
                      {loading && pageNum === 1 ? (
                        <div className="text-center mt-5 loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="item-list mt-3 mt-md-5">
                          {itemList.length > 0 ? (
                            itemList.map((item: any, i: number) => (
                              <TokenView
                                key={i}
                                item={item}
                                user={userInfo}
                                resaleSucced={() => resaleSucced()}
                              ></TokenView>
                            ))
                          ) : (
                            <NoItem
                              title="No tokens found"
                              description="Come back soon! Or try to browse something for you on our marketplace"
                              btnLink="/"
                              btnLabel="Browse marketplace"
                            />
                          )}
                        </div>
                      )}
                      {loading && pageNum > 1 ? (
                        <div className="my-3 d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        itemList.length > 0 &&
                        pages > pageNum && (
                          <div className="my-3 d-flex justify-content-center">
                            <Button
                              variant="primary"
                              className="btn-round w-50 outline-btn"
                              onClick={() => setPageNumber(pageNum + 1)}
                            >
                              <span>Load More</span>
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                    <div className="collections-section">
                      <BigTitle>Created Collections</BigTitle>
                      <div className="item-list mt-3 mt-md-5">
                        {collections.length > 0 ? (
                          collections.map(
                            (collection: any, i: number) =>
                              collection._id && (
                                <CollectionItem
                                  key={i}
                                  item={collection}
                                ></CollectionItem>
                              )
                          )
                        ) : (
                          <NoItem
                            title="No collections yet"
                            description=""
                            btnLink="/"
                            btnLabel="Browse marketplace"
                          />
                        )}
                      </div>
                      {/* <div className="notice-view">
                    <div className="notice">
                      <B2NormalTextTitleGrey>
                        Create collections with your own storefronts, upload digital creations, configure your commision, and sell NFTs to your fans - all for free! You can also manage smart contracts than you created outside of NFT’s.
                      </B2NormalTextTitleGrey>
                    </div>
                    <div className="btn-section">
                     <Link to="/collectible">
                        <Button
                          variant="primary"
                          className="default-btn-size btn-create outline-btn"
                        >
                          <span>Create NFT</span>
                        </Button>
                      </Link>
                    </div>
                  </div> */}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="owned">
                    {loading && pageNum === 1 ? (
                      <div className="mt-5 text-center loading-bar">
                        <LoadingBar />
                      </div>
                    ) : (
                      <Fragment>
                        <div className="tokens-section">
                          <BigTitle>My Tokens</BigTitle>
                          <div className="item-list mt-3 mt-md-5">
                            {itemList.length > 0 ? (
                              itemList.map((token: any, i: number) => (
                                <TokenView
                                  key={i}
                                  item={token}
                                  user={userInfo}
                                  resaleSucced={() => resaleSucced()}
                                ></TokenView>
                              ))
                            ) : (
                              <NoItem
                                title="No tokens found"
                                description="Come back soon! Or try to browse something for you on our marketplace"
                                btnLink="/"
                                btnLabel="Browse marketplace"
                              />
                            )}
                          </div>
                          {loading && pageNum > 1 ? (
                            <div className="my-3 d-flex justify-content-center loading-bar">
                              <LoadingBar />
                            </div>
                          ) : (
                            itemList.length > 0 &&
                            pages > pageNum && (
                              <div className="my-3 d-flex justify-content-center">
                                <Button
                                  variant="primary"
                                  className="btn-round w-50 outline-btn"
                                  onClick={() => setPageNumber(pageNum + 1)}
                                >
                                  <span>Load More</span>
                                </Button>
                              </div>
                            )
                          )}
                        </div>
                        <div className="collections-section">
                          <BigTitle>My Collections</BigTitle>
                          <div className="item-list mt-3 mt-md-5">
                            {collections.length > 0 ? (
                              collections.map(
                                (collection: any, i: number) =>
                                  collection._id && (
                                    <CollectionItem
                                      key={i}
                                      item={collection}
                                    ></CollectionItem>
                                  )
                              )
                            ) : (
                              <NoItem
                                title="No collections yet"
                                description=""
                                btnLink="/"
                                btnLabel="Browse marketplace"
                              />
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {/* <div className="notice-view">
                  <div className="notice">
                    <B2NormalTextTitleGrey>
                      Create collections with your own storefronts, upload digital creations, configure your commision, and sell NFTs to your fans - all for free! You can also manage smart contracts than you created outside of NFT’s.
                    </B2NormalTextTitleGrey>
                  </div>
                  <div className="btn-section">
                    <Link to="/collectible">
                      <Button
                        variant="primary"
                        className="default-btn-size btn-create outline-btn"
                      >
                        <span>Create NFT</span>
                      </Button>
                    </Link>
                  </div>
                </div> */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="activity">
                    <div className="tokens-section">
                      <BigTitle>My Activities</BigTitle>
                      <div className="item-list mt-3 mt-md-5">
                        <Row>
                          <Col md="8">
                            {loading && pageNum === 1 ? (
                              <div className="text-center mt-5 loading-bar">
                                <LoadingBar />
                              </div>
                            ) : (
                              <Row>
                                {itemList.length > 0 ? (
                                  itemList.map((item: any, i: number) => (
                                    <Col key={i} xl="6" className="pb-3">
                                      <ActivityItem item={item}></ActivityItem>
                                    </Col>
                                  ))
                                ) : (
                                  <NoItem
                                    title="No activities found"
                                    description="Come back soon! Or try to browse something for you on our marketplace"
                                    btnLink="/"
                                    btnLabel="Browse marketplace"
                                  />
                                )}
                              </Row>
                            )}
                            {loading && pageNum > 1 ? (
                              <div className="my-3 d-flex justify-content-center loading-bar">
                                <LoadingBar />
                              </div>
                            ) : (
                              itemList.length > 0 &&
                              pages > pageNum && (
                                <div className="my-3 d-flex justify-content-center">
                                  <Button
                                    variant="primary"
                                    className="btn-round w-50 outline-btn"
                                    onClick={() => setPageNumber(pageNum + 1)}
                                  >
                                    <span>Load More</span>
                                  </Button>
                                </div>
                              )
                            )}
                          </Col>
                          <Col md="4">
                            <div className="sticky-filters">
                              <div className="filter-header mb-4">
                                <NormalTextTitle className="mr-3">
                                  Filters
                                </NormalTextTitle>
                                {selectedFilter && (
                                  <NormalTextTitle
                                    className="filter-reset"
                                    onClick={() => {
                                      onSelectFilter("");
                                    }}
                                  >
                                    Reset filter
                                  </NormalTextTitle>
                                )}
                              </div>
                              <div className="filter-buttons">
                                {filters.map((filter, index) => {
                                  return (
                                    <div
                                      className={getFilterClass(filter.filter)}
                                      key={index}
                                      onClick={() => {
                                        onSelectFilter(`${filter.filter}`);
                                      }}
                                      style={{
                                        backgroundColor: getFilterColor(
                                          filter.filter
                                        ),
                                      }}
                                    >
                                      <FilterIcon className="mr-3">
                                        {filter.icon}
                                      </FilterIcon>
                                      <span>{filter.name}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* <div className="notice-view">
                    <div className="notice">
                      <B2NormalTextTitleGrey>
                        Create collections with your own storefronts, upload digital creations, configure your commision, and sell NFTs to your fans - all for free! You can also manage smart contracts than you created outside of NFT’s.
                      </B2NormalTextTitleGrey>
                    </div>
                    <div className="btn-section">
                       <Link to="/collectible">
                        <Button
                          variant="primary"
                          className="default-btn-size btn-create outline-btn"
                        >
                          <span>Create NFT</span>
                        </Button>
                      </Link>
                    </div>
                  </div> */}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="royalties">
                    <div className="royalties-section">
                      <div className="d-flex flex-column align-items-center">
                        <BigTitle>Royalties</BigTitle>
                        <SmallTextTitleGrey>
                          See the royalties you received from the sale of your
                          artwork
                        </SmallTextTitleGrey>
                        <div className="royal-section">
                          <div className="d-flex justify-content-between border-bottom">
                            <SmallTextTitleGrey>Summary</SmallTextTitleGrey>
                            <div className="d-flex">
                              <SmallTextTitleGrey className="mr-2">
                                {royalty.royalties}
                              </SmallTextTitleGrey>
                              <SmallTextTitle>ETH</SmallTextTitle>
                            </div>
                          </div>
                          <div className="mt-2 d-flex justify-content-between">
                            <div className="flex-grow-1 mr-2 d-flex justify-content-between border-bottom">
                              <SmallTextTitleGrey>Tokens</SmallTextTitleGrey>
                              <div className="d-flex">
                                <SmallTextTitleGrey className="mr-2">
                                  {royalty.tokens}
                                </SmallTextTitleGrey>
                              </div>
                            </div>
                            <div className="flex-grow-1 ml-2 d-flex justify-content-between border-bottom">
                              <SmallTextTitleGrey>
                                Collections
                              </SmallTextTitleGrey>
                              <div className="d-flex">
                                <SmallTextTitleGrey className="mr-2">
                                  {royalty.collections}
                                </SmallTextTitleGrey>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="question-section">
                      <MidTextTitle>What does it mean?</MidTextTitle>
                      <SmallTextTitleGrey className="mt-4">
                        Here you can see the tokens and collections that you
                        have created and put up for sale.
                        <br />
                        The percentage of each token and the amount that is
                        credited to you is also displayed.
                      </SmallTextTitleGrey>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </>
      )}
    </Layout>
  );
};

export default MyItems;
