/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Button, Row, Col, Form, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import {
  MidTextTitle,
  NormalTextTitle,
  SocialIcon,
  FlexJustifyBetweenDiv,
  DivideLineWhite
} from "./common/common.styles";
import { FaInstagram, FaDiscord, FaTwitter } from "react-icons/fa";
import configs from "configs";
import { NotificationManager } from "react-notifications";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const [postEmail, setEmail] = useState('');
  const languages = [
    {
      value: "eng",
      label: "English",
    }
  ];
  const onSubmit = async(e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      var emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (postEmail && !emailPattern.test(postEmail)) {
        NotificationManager.error(
          'Considered as not valid email',
          'Error'
        );
        return;
      }
      await axios.post(`${configs.MAXIMNFT_URL}?collection=false`, { email: postEmail }).then((res) => {
        if (res && res.status === 200) {
          NotificationManager.success(
            'Successfully',
            "Success"
          );
        }
      }).catch((err) => {
        if (err) {
          NotificationManager.error(
            "Failed",
            'Error'
          );
        }
      });
    }
  }
  return (
    <footer className="footer-container">
      <div className="px-xl-5">
        <Row className="pb-3 pb-md-5">
          <Col lg="4">
            <MidTextTitle className="font-matrice">Get the latest updates</MidTextTitle>
            <Form className="mt-4 d-flex align-items-center mb-3" onSubmit={onSubmit}>
              <FormControl required type="email" name="email" placeholder="Your e-mail" value={postEmail} onChange={(e) => setEmail(e.target.value)} />
              <Button className="default-btn-size btn-white" type="submit">
                Subscribe
              </Button>
            </Form>
          </Col>
          <Col lg="4" md="6" className="d-flex justify-content-between">
            <div className="mr-md-7 mb-3">
              <NormalTextTitle className="mb-2">EXPLORE</NormalTextTitle>
              <Link to="/explore">Explore</Link>
              <a href="https://docs.maximnft.com" target="_blank" rel="noreferrer">How it works</a>
              <a href="https://docs.maximnft.com/information/contact-us" target="_blank" rel="noreferrer">Support</a>
            </div>
            <div className="mb-3">
              <NormalTextTitle className="mb-2">ACTIVITY</NormalTextTitle>
              <a href="https://discord.gg/J7SnkCVcej" target="_blank" rel="noreferrer">Community</a>
              <a href="https://discord.gg/J7SnkCVcej" target="_blank" rel="noreferrer">Suggest a feature</a>
            </div>
          </Col>
          <Col lg="4" md="6">
            <MidTextTitle>Language</MidTextTitle>
            <div className="form-control__select">
              <Form.Control as="select" className="mt-4">
                {languages.map((lng, index) => {
                  return <option value={lng.value} key={index}>{lng.label}</option>;
                })}
              </Form.Control>
            </div>
          </Col>
        </Row>
        <DivideLineWhite></DivideLineWhite>
        <FlexJustifyBetweenDiv className="mt-4 flex-wrap">
          <div className="d-flex align-items-center mb-3 mx-auto mx-md-0">
            <span className="mr-4 mr-md-7">All rights reserved</span>
            <a href="https://docs.maximnft.com/information/terms-of-use" target="_blank" rel="noreferrer" className="mr-4 mt-0">
              Terms
            </a>
            <a className="mt-0 mr-4" href="https://docs.maximnft.com/information/privacy-policy" target="_blank" rel="noreferrer">Privacy</a>
          </div>
          <div className="d-flex mx-auto mx-md-0">
              <SocialIcon className="mr-3">
                <a href="https://discord.gg/J7SnkCVcej" target="_blank" rel="noreferrer">
                  <FaDiscord/>
                </a>
              </SocialIcon>
              <SocialIcon className="mr-3">
                <a href="https://www.instagram.com/themaximnft/" target="_blank" rel="noreferrer">
                  <FaInstagram ></FaInstagram>
                </a>
              </SocialIcon>
              <SocialIcon className="mr-3">
                <a href="https://twitter.com/maximnft" target="_blank" rel="noreferrer">
                  <FaTwitter></FaTwitter>
                </a>
              </SocialIcon>
          </div>
        </FlexJustifyBetweenDiv>
      </div>
    </footer>
  );
};

export default Footer;
