import React, { useEffect } from "react";
import Layout from "components/Layout";
import "./Terms.scss";
import { TermsContent } from './data';

interface TermsProps {}

const Terms: React.FC<TermsProps> = () => {
  useEffect(() => {}, []);

  return (
    <Layout className="container">
      <div dangerouslySetInnerHTML={{__html: TermsContent.data}} />
    </Layout>
  );
};

export default Terms;
