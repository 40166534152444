import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { BigNumberMul, isLessValue } from "service/number";
import { useAppDispatch, useAppSelector, useInterval } from "store/hooks";
import { getWalletBalance as getWalletBalanceSelector } from "store/User/user.selector";
import { getWalletBalance } from "store/User/user.slice";
import {
  B2NormalTextTitle,
  BigTitle,
  DivideLine,
  FlexJustifyBetweenDiv,
  NormalTextTitle,
} from "../common/common.styles";
import { web3 } from "ethereum/OnBoard";
import SmartContract721 from "ethereum/Contract721";
import SmartContract1155 from "ethereum/Contract1155";
import ethUtil from "ethereum/EthUtil";
import configs from "configs";
import OfferController from "controller/OfferController";
import useState from "react-usestateref";
import { getWalletAddress } from "store/User/user.selector";
import Utility from "service/utility";
interface PlaceBidModalProps {
  handleClose?: any;
  submitBid?: any;
  show: boolean;
  minPrice: any;
  highestBidder?: any;
  token: any;
  owner: any;
  offer: any;
  loadOffer: any;
  type: number;
  setShowPlaceBidModal: any;
  setTransProgressing: any;
  isTransProgressing: any;
  available_copies?: number;
}

const PlaceBidModal: React.FC<PlaceBidModalProps> = ({
  show,
  handleClose,
  minPrice,
  highestBidder,
  token,
  owner,
  type,
  offer,
  loadOffer,
  available_copies,
  setTransProgressing,
  isTransProgressing,
  setShowPlaceBidModal,
}) => {
  const balance = useAppSelector(getWalletBalanceSelector);
  const walletAddress = useAppSelector(getWalletAddress);
  const [price, setPrice] = useState<any>(minPrice);
  const [quantity, setQuantity] = useState<any>(1);
  const [, setProcessStatus, processStatusRef] = useState(false);
  const dispatch = useAppDispatch();
  const errorInterval = useInterval();
  const txInterval = useInterval();
  const [, setIsSpeedUpLoading, isSpeedUpLoadingRef] = useState(false);

  useEffect(() => {
    if (highestBidder && highestBidder.copies) {
      setQuantity(highestBidder.copies);
    }
    if (minPrice) {
      setPrice(parseFloat(Number(minPrice).toFixed(4)));
    }
  }, [highestBidder, minPrice]);

  const isBelowMinPrice = () => {
    return isLessValue(price, minPrice);
  };

  const getTotalBidPrice = () => {
    if (type === 1155) {
      const totalPrice = BigNumberMul(price, quantity).toString();
      return totalPrice;
    }
    return price;
  };

  const Bid721Callback = async (transactionHash: any) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    await OfferController.placeBid(offer._id, {
      price,
      hash: transactionHash,
    });

    if (transactionHash) {
      await ethUtil.checkAndWaitNextBlock(transactionHash);
    }

    loadOffer();

    NotificationManager.success(
      "You bid to this item successfully.",
      "Success"
    );

    dispatch(getWalletBalance());
    setIsSpeedUpLoading(false);
    setTransProgressing(false);
    setShowPlaceBidModal(false);
  };

  const Bid1155Callback = async (
    counts: any,
    transactionHash: any
  ) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    await OfferController.placeBid(offer._id, {
      price,
      copies: counts,
      hash: transactionHash,
    });

    if (transactionHash) {
      await ethUtil.checkAndWaitNextBlock(transactionHash);
    }

    loadOffer();

    NotificationManager.success(
      "You bid to this item successfully.",
      "Success"
    );

    dispatch(getWalletBalance());
    setIsSpeedUpLoading(false);
    setTransProgressing(false);
    setShowPlaceBidModal(false);
  };

  const CreateAndBid1155Callback = async (
    counts: any,
    auctionId: any,
    transactionHash: any
  ) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    const payload = {
      auction_chain_id: auctionId,
      price,
      copies: counts,
      hash: transactionHash,
      date_end: offer.date_end,
    };

    await OfferController.createAuctionFor1155(offer._id, payload);

    if (transactionHash) {
      await ethUtil.checkAndWaitNextBlock(transactionHash);
    }

    NotificationManager.success(
      "You bid to this item successfully.",
      "Success"
    );

    loadOffer();

    dispatch(getWalletBalance());
    setIsSpeedUpLoading(false);
    setTransProgressing(false);
    setShowPlaceBidModal(false);
  };

  const rejectCallback = (error?: any) => {
    console.log("Reject", { isTransProgressing, error });
    if (
      error?.message?.startsWith("Transaction was not mined within 50 blocks")
    )
      return;

    if (!isTransProgressing) return;

    clearInterval(txInterval.current);
    txInterval.current = null;
    clearInterval(errorInterval.current);
    errorInterval.current = null;

    setProcessStatus(true);
    loadOffer();
    setTransProgressing(false);
    NotificationManager.error(
      "Your attempt to bid for this item was not successful",
      "Error"
    );

    if (error) {
      ethUtil.catchError(error);
    }
  };

  const handleOnSpeedUpBid1155 = async (
    counts: any,
    nonce: any,
    contract: any,
    transactionHash: any
  ) => {
    if (isSpeedUpLoadingRef.current) return;
    console.log("Start check speedup for bid 1155 token");
    setIsSpeedUpLoading(true);

    const checkTx = async () => {
      const engineContract = SmartContract1155.getEngine1155Contract();

      console.log("Offer Auction", offer?.auction);

      if (offer?.auction?.auction_chain_id) {
        const auctionTx = await engineContract.methods
          .auctions(offer.auction.auction_chain_id)
          .call();

        console.log("Auction data", auctionTx);
        console.log(
          "Current bidder",
          auctionTx?.currentBidOwner?.toLowerCase()
        );
        console.log("Current user", walletAddress?.toLowerCase());

        if (
          auctionTx?.currentBidOwner?.toLowerCase() ===
          walletAddress?.toLowerCase()
        ) {
          return true;
        }
      }

      return false;
    };

    const speedUpData = await Utility.tryCatchRepeat(
      () => checkTx(),
      2,
      true,
      10000
    );

    if (!speedUpData) {
      rejectCallback();
      setIsSpeedUpLoading(false);
      return;
    }

    await Bid1155Callback(counts, transactionHash);
  };

  const handleOnSpeedUpCreateAndBid1155 = async (
    counts: any,
    nonce: any,
    contract: any
  ) => {
    if (isSpeedUpLoadingRef.current) return;

    setIsSpeedUpLoading(true);

    const speedUpData = await ethUtil.catchSpeedUpOrCancelTx(
      nonce,
      rejectCallback,
      "AuctionCreated",
      contract
    );

    if (!speedUpData) return setIsSpeedUpLoading(false);

    const auctionId = ethUtil.getDataFromEvent(speedUpData.event, 0);

    const transactionHash = speedUpData.tx.hash;

    await CreateAndBid1155Callback(
      counts,
      auctionId,
      transactionHash
    );
  };

  const handleOnTransactionHashBid1155 = async (
    counts: any,
    transactionHash: any
  ) => {
    const xSigmaContract = SmartContract1155.getEngine1155Contract();

    let nonce: any;

    if (transactionHash) {
      var counter = configs.TIMEOUT;

      txInterval.current = setInterval(async function () {
        if (!nonce) {
          const tx = await ethUtil.getTransaction(transactionHash);

          if (tx) {
            nonce = tx.nonce;
          }
        }

        counter--;
        if (counter % 20 === 0) {
          if (isSpeedUpLoadingRef.current) return;

          const tx = await ethUtil.getTransaction(transactionHash);
          const receipt = await ethUtil.getTransactionReceipt(transactionHash);

          console.log({ tx, receipt });

          if (!tx && !receipt) {
            await handleOnSpeedUpBid1155(
              counts,
              nonce,
              xSigmaContract,
              transactionHash
            );
          }

          if (receipt && receipt.status === true && !processStatusRef.current) {
            if (txInterval.current) {
              await Bid1155Callback(counts, transactionHash);
            }
          } else if (
            receipt &&
            receipt.status === false &&
            !processStatusRef.current
          ) {
            rejectCallback();
          }
        }

        if (processStatusRef.current) {
          clearInterval(errorInterval.current);
          errorInterval.current = null;
          clearInterval(txInterval.current);
          txInterval.current = null;
        }
      }, 1000);
    }
  };

  const handleOnTransactionHashCreateAndBid1155 = async (
    counts: any,
    transactionHash: any
  ) => {
    const xSigmaContract = SmartContract1155.getEngine1155Contract();

    let nonce: any;

    if (transactionHash) {
      var counter = configs.TIMEOUT;

      txInterval.current = setInterval(async function () {
        if (!nonce) {
          const tx = await ethUtil.getTransaction(transactionHash);

          if (tx) {
            nonce = tx.nonce;
          }
        }

        counter--;
        if (counter % 20 === 0) {
          if (isSpeedUpLoadingRef.current) return;

          const tx = await ethUtil.getTransaction(transactionHash);
          const receipt = await ethUtil.getTransactionReceipt(transactionHash);

          console.log({ tx, receipt });

          if (!tx && !receipt) {
            await handleOnSpeedUpCreateAndBid1155(
              counts,
              nonce,
              xSigmaContract
            );
          }

          if (receipt && receipt.status === true && !processStatusRef.current) {
            const auctionId = await ethUtil.getDataFromTxReceipt(
              receipt,
              0,
              xSigmaContract,
              "AuctionBid"
            );

            console.log("auctionId: " + auctionId);

            if (auctionId && txInterval.current) {
              await CreateAndBid1155Callback(
                counts,
                auctionId,
                transactionHash
              );
            }
          } else if (
            receipt &&
            receipt.status === false &&
            !processStatusRef.current
          ) {
            rejectCallback();
          }
        }

        if (processStatusRef.current) {
          clearInterval(errorInterval.current);
          errorInterval.current = null;
          clearInterval(txInterval.current);
          txInterval.current = null;
        }
      }, 1000);
    }
  };

  const handleOnSpeedUpBid721 = async (
    nonce: any,
    contract: any
  ) => {
    if (isSpeedUpLoadingRef.current) return;

    setIsSpeedUpLoading(true);

    const speedUpData = await ethUtil.catchSpeedUpOrCancelTx(
      nonce,
      rejectCallback,
      "AuctionBid",
      contract
    );

    if (!speedUpData) return setIsSpeedUpLoading(false);

    const transactionHash = speedUpData.tx.hash;

    await Bid721Callback(transactionHash);
  };

  const handleOnTransactionHashBid721 = async (
    transactionHash: any
  ) => {
    const xSigmaContract = SmartContract721.getEngine721Contract();

    let nonce: any;

    if (transactionHash) {
      var counter = configs.TIMEOUT;

      txInterval.current = setInterval(async function () {
        if (!nonce) {
          const tx = await ethUtil.getTransaction(transactionHash);

          if (tx) {
            nonce = tx.nonce;
          }
        }

        counter--;
        if (counter % 20 === 0) {
          if (isSpeedUpLoadingRef.current) return;

          const tx = await ethUtil.getTransaction(transactionHash);
          const receipt = await ethUtil.getTransactionReceipt(transactionHash);

          console.log({ tx, receipt });

          if (!tx && !receipt) {
            await handleOnSpeedUpBid721(nonce, xSigmaContract);
          }

          if (receipt && receipt.status === true && !processStatusRef.current) {
            if (txInterval.current) {
              await Bid721Callback(transactionHash);
            }
          } else if (
            receipt &&
            receipt.status === false &&
            !processStatusRef.current
          ) {
            rejectCallback();
          }
        }

        if (processStatusRef.current) {
          clearInterval(errorInterval.current);
          errorInterval.current = null;
          clearInterval(txInterval.current);
          txInterval.current = null;
        }
      }, 1000);
    }
  };

  const handleOnReceiptBid721 = async (receipt: any) => {
    if (receipt && receipt.status === true && !processStatusRef.current) {
      await Bid721Callback(receipt.transactionHash);
    } else if (
      receipt &&
      receipt.status === false &&
      !processStatusRef.current
    ) {
      rejectCallback();
    }
  };

  const handleOnReceiptCreateAndBid1155 = async (
    counts: any,
    receipt: any
  ) => {
    if (receipt && receipt.status === true && !processStatusRef.current) {
      const auctionId = ethUtil.getDataFromEvent(receipt.events.AuctionBid, 0);

      await CreateAndBid1155Callback(
        counts,
        auctionId,
        receipt.transactionHash
      );
    } else if (
      receipt &&
      receipt.status === false &&
      !processStatusRef.current
    ) {
      rejectCallback();
    }
  };

  const handleOnReceiptBid1155 = async (
    counts: any,
    receipt: any
  ) => {
    if (receipt && receipt.status === true && !processStatusRef.current) {
      await Bid1155Callback(counts, receipt.transactionHash);
    } else if (
      receipt &&
      receipt.status === false &&
      !processStatusRef.current
    ) {
      rejectCallback();
    }
  };

  const submitBid = async (price: any, quantity?: number) => {
    if (
      token.type === 1155 &&
      quantity &&
      highestBidder &&
      quantity < highestBidder.copies
    ) {
      NotificationManager.error(
        `You can't input below than ${highestBidder.copies}.`,
        "Error"
      );

      return;
    }

    setTransProgressing(true);

    try {
      if (web3 && offer) {
        const counts = quantity || 1;

        const totalPrice =
          token.type === 721 ? price : BigNumberMul(price, counts).toString();

        const chainId =
          token.type === 721 ? token.chain_id : offer.offer_chain_id;

        const bytecode =
          token.type === 721
            ? await SmartContract721.getEngine721Bytecode()
            : await SmartContract1155.getEngine1155Bytecode();

        const engineContract =
          token.type === 721
            ? SmartContract721.getEngine721Contract()
            : SmartContract1155.getEngine1155Contract();

        if (token.type === 721) {
          const auctionId = await SmartContract721.getAuctionId(chainId);

          const gasEstimate: any = await engineContract.methods
            .bid(auctionId)
            .estimateGas({
              data: bytecode,
              from: ethUtil.getAddress(),
              value: ethUtil.toWei(totalPrice),
            });

          if (gasEstimate) {
            let hash = "";
            setProcessStatus(false);

            await new web3.eth.sendTransaction({
              from: ethUtil.getAddress(),
              to: SmartContract721.getEngine721Address(),
              value: ethUtil.toWei(totalPrice),
              gas: gasEstimate + configs.GAS_LIMIT,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null,
              ...((await ethUtil.getGasPrice()) || {}),
              data: engineContract.methods.bid(auctionId).encodeABI(),
            })
              .on("transactionHash", async function (transactionHash: any) {
                hash = transactionHash;
                await handleOnTransactionHashBid721(
                  transactionHash
                );
              })
              .on("receipt", async function (receipt: any) {
                await handleOnReceiptBid721(receipt);
              })
              .on("error", async function (error: any) {
                await ethUtil.txErrorHandler(
                  error,
                  hash,
                  processStatusRef.current,
                  errorInterval,
                  setProcessStatus,
                  setTransProgressing,
                  true,
                  false
                );
              });
          }
        }

        if (token.type === 1155) {
          const auction = offer.auction;
          let hasBids = await SmartContract1155.hasBids(chainId);

          if (hasBids && auction) {
            const gasEstimate: any = await engineContract.methods
              .bid(auction.auction_chain_id, counts)
              .estimateGas({
                data: bytecode,
                from: ethUtil.getAddress(),
                value: ethUtil.toWei(totalPrice),
              });

            if (gasEstimate) {
              let hash = "";
              setProcessStatus(false);

              await new web3.eth.sendTransaction({
                from: ethUtil.getAddress(),
                to: SmartContract1155.getEngine1155Address(),
                value: ethUtil.toWei(totalPrice),
                gas: gasEstimate + configs.GAS_LIMIT,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
                ...((await ethUtil.getGasPrice()) || {}),
                data: engineContract.methods
                  .bid(auction.auction_chain_id, counts)
                  .encodeABI(),
              })
                .on("transactionHash", async function (transactionHash: any) {
                  hash = transactionHash;
                  await handleOnTransactionHashBid1155(
                    counts,
                    transactionHash
                  );
                })
                .on("receipt", async function (receipt: any) {
                  await handleOnReceiptBid1155(counts, receipt);
                })
                .on("error", async function (error: any) {
                  await ethUtil.txErrorHandler(
                    error,
                    hash,
                    processStatusRef.current,
                    errorInterval,
                    setProcessStatus,
                    setTransProgressing,
                    true,
                    false
                  );
                });
            }
          } else {
            let gasEstimate: any = await engineContract.methods
              .createAuctionAndBid(offer.offer_chain_id, counts)
              .estimateGas({
                data: bytecode,
                from: ethUtil.getAddress(),
                value: ethUtil.toWei(totalPrice),
              });

            if (gasEstimate) {
              let hash = "";
              setProcessStatus(false);

              await engineContract.methods
                .createAuctionAndBid(offer.offer_chain_id, counts)
                .send({
                  from: ethUtil.getAddress(),
                  value: ethUtil.toWei(totalPrice),
                  gas: gasEstimate + configs.GAS_LIMIT,
                  maxPriorityFeePerGas: null,
                  maxFeePerGas: null,
                  ...((await ethUtil.getGasPrice()) || {}),
                })
                .on("transactionHash", async function (transactionHash: any) {
                  hash = transactionHash;
                  await handleOnTransactionHashCreateAndBid1155(
                    counts,
                    transactionHash
                  );
                })
                .on("receipt", async function (receipt: any) {
                  await handleOnReceiptCreateAndBid1155(
                    counts,
                    receipt
                  );
                })
                .on("error", async function (error: any, receipt: any) {
                  await ethUtil.txErrorHandler(
                    error,
                    hash,
                    processStatusRef.current,
                    errorInterval,
                    setProcessStatus,
                    setTransProgressing,
                    true,
                    false
                  );
                });
            }
          }
        }
      }
    } catch (error: any) {
      rejectCallback(error);
    }
  };

  const placeBid = () => {
    if (isBelowMinPrice()) return;
    if (isLessValue(price, balance)) {
      if (type === 1155 && available_copies && quantity > available_copies) {
        NotificationManager.error(
          "You can't input quantity more than " + available_copies + ".",
          "Error"
        );
        return;
      }
      if (quantity < 1) {
        NotificationManager.error(
          "Please input at least 1 of quantity.",
          "Error"
        );
        return;
      }
      submitBid(price, quantity);
    } else {
      NotificationManager.error(
        "You have no enough balance to process transaction.",
        "Error"
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="buy-token-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          <BigTitle>Place a bid</BigTitle>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12" className="px-4">
            <B2NormalTextTitle>
              You are about to place a bid {token ? token.name : ""}
              <br />
              from {owner && owner.user ? owner.user.name : ""}
            </B2NormalTextTitle>
            <B2NormalTextTitle className="mt-4">Your bid</B2NormalTextTitle>
            <FlexJustifyBetweenDiv className="mt-2">
              <Form.Control
                required
                type="number"
                placeholder="Enter bid"
                value={price}
                min={minPrice}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </FlexJustifyBetweenDiv>
            {isBelowMinPrice() ? (
              <NormalTextTitle className="mt-2 danger-color">
                You can not input less than {minPrice} {token.blockchain}{" "}
              </NormalTextTitle>
            ) : (
              ""
            )}
            {type === 1155 && (
              <>
                <B2NormalTextTitle className="mt-4">
                  Enter quantity
                </B2NormalTextTitle>
                <FlexJustifyBetweenDiv className="mt-2">
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter quantity"
                    min={highestBidder ? highestBidder.copies : 1}
                    max={available_copies}
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </FlexJustifyBetweenDiv>
                {quantity < (highestBidder ? highestBidder.copies : 1) ? (
                  <NormalTextTitle className="mt-2 danger-color">
                    Please input valid quantity
                  </NormalTextTitle>
                ) : (
                  ""
                )}
                {available_copies && quantity > available_copies ? (
                  <NormalTextTitle className="mt-2 danger-color">
                    You can not input more than {available_copies}.
                  </NormalTextTitle>
                ) : (
                  ""
                )}
              </>
            )}

            <FlexJustifyBetweenDiv className="mt-3">
              <div>Your balance</div>
              <B2NormalTextTitle>
                {balance} {token.blockchain}
              </B2NormalTextTitle>
            </FlexJustifyBetweenDiv>
            <FlexJustifyBetweenDiv className="mt-2">
              <div>Total bid amount</div>
              <B2NormalTextTitle>
                {getTotalBidPrice()} {token.blockchain}
              </B2NormalTextTitle>
            </FlexJustifyBetweenDiv>
            <Button
              variant="primary"
              className="full-width mt-3 outline-btn"
              onClick={placeBid}
            >
              <span>Place a bid</span>
            </Button>
            <Button variant="link" className="full-width" onClick={handleClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PlaceBidModal;
