/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button, Image } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import {
  FlexAlignCenterDiv,
  B2NormalTextTitle,
  MBigTitle,
  B2NormalTextTitleGrey,
  BigTitle,
  SmallTextTitle
} from "components/common/common.styles";
import Layout from "components/Layout";
import "./Profile.scss";
import UserController from 'controller/UserController';
import FileUploader from "components/common/uploader/FileUploader";
import configs from "configs";
import imageAvatar from 'assets/imgs/avatar.svg';
import Utility from 'service/utility';
import { Link } from 'react-router-dom';
import { FaLongArrowAltLeft, FaLink, FaTrash } from "react-icons/fa";
import { RiLockLine } from "react-icons/ri";
import { getMyInfo } from "store/User/user.slice";
import { getWalletAddress } from "store/User/user.selector";
import { useAppSelector, useAppDispatch } from "store/hooks";
import ConnectWalletBtn from "components/common/button/ConnectWalletBtn";

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(getWalletAddress);
  const [displayName, setDisplayName] = useState('');

  const [bio, setBio] = useState('');
  const [twitterUsername, setTwitterUsername] = useState('');
  const [instagram, setInstagram] = useState('');
  const [email, setEmail] = useState('');
  const [link, setLink] = useState('');
  const [verified, setVerified] = useState(false);
  const [avatarImage, setAvatar] = useState<any>(null);
  const [uploadImage, setUploadImage] = useState<any>(null);
  const [delPreview, setDelPreview] = useState(false);
  useEffect(() => {
    const loadActivities = async () => {
      let data = await UserController.getSettings();
      if (data.name) {
        setDisplayName(data.name);
      }
      if (data.bio) {
        setBio(data.bio);
      }
      if (data.email) {
        setEmail(data.email);
      }
      if (data.avatar) {
        setUploadImage(`${Utility.mediaValidation(data.avatar)}`);
      }
      if (data.twitter) {
        setTwitterUsername(data.twitter);
      }
      if (data.instagram) {
        setInstagram(data.instagram);
      }
      if (data.link) {
        setLink(data.link)
      }
      if (data.verified) {
        setVerified(data.verified);
      }
    }
    loadActivities();
  }, []);

  const setAvatarFile = async (file: any) => {
    if (file) {
      setAvatar(file);
      let data = {
        avatar: file
      };
      let formdata = Utility.getFormDataFromObject(data);
      await UserController.uploadAvatar(formdata).then((res) => {
        if (res && res.status === 200) {
          NotificationManager.success(
            'Avatar Successfully uploaded!',
            "Success"
          );
          dispatch(getMyInfo(walletAddress));
        }
      }).catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          NotificationManager.error(
            err.response.data.error,
            'Error'
          );
        }
      });
    }
  }

  const removeAvatar = async () => {
    setUploadImage(`${configs.DEPLOY_URL}/content/avatar/default.png`);
    setAvatar(null);
    setDelPreview(true);
    let data = {
      avatar: null
    };
    let formdata = Utility.getFormDataFromObject(data);
    await UserController.uploadAvatar(formdata).then((res) => {
      if (res && res.status === 200) {
        NotificationManager.success(
          'Avatar Successfully removed!',
          "Success"
        );
        dispatch(getMyInfo(walletAddress));
      }
    }).catch((err) => {
      if (err.response && err.response.data && err.response.data.error) {
        NotificationManager.error(
          err.response.data.error,
          'Error'
        );
      }
    });
  }

  const setPreviewFile = (image: any) => {
    if (image) {
      setUploadImage(image);
    }
  }

  const onSaveSettings = async() => {
    let setting = {
      name: displayName,
      bio,
      email,
      twitter: twitterUsername,
      instagram,
      link,
      verified
    };
    if (!displayName) {
      NotificationManager.error(
        "Display name is required!",
        "Error"
      );
      return;
    }
    var emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email && !emailPattern.test(email)) {
      NotificationManager.error(
        'Considered as not valid email',
        'Error'
      );
      return;
    }
    
    await UserController.userSettings(setting).then((res) => {
      if (res && res.status === 200) {
        NotificationManager.success(
          'Profile Successfully updated!',
          "Success"
        );
        dispatch(getMyInfo(walletAddress));
      }
    }).catch((err) => {
      if (err.response && err.response.data && err.response.data.error) {
        NotificationManager.error(
          err.response.data.error,
          'Error'
        );
      }
    });
  }

  const onVerify = async() => {
    let res = await UserController.getVerify();
    if (res && res.status === 200) {
      NotificationManager.success(
        'Successfully verified!',
        "Success"
      );
      setVerified(true);
    }
  }

  return (
    <Layout className="profile-container">
      <Container>
        <Link to="/items" style={{ textDecoration: 'none' }}>
          <FlexAlignCenterDiv className="pointer-cursor mb-4 back-link">
            <FaLongArrowAltLeft />
            <B2NormalTextTitle className="ml-2 font-matrice">
              Profile
            </B2NormalTextTitle>
          </FlexAlignCenterDiv>
        </Link>
        <Row>
          <Col md="8">
            <MBigTitle className="faint-color font-matrice">EDIT PROFILE</MBigTitle>
            <B2NormalTextTitleGrey>Set your preferred display name, create your branded profile URL and manage other personal settings.</B2NormalTextTitleGrey>
          </Col>
        </Row>
        <Form>
          <Row className="row-reserve">
            <Col md="7">
              <div className="mt-3 mt-md-5">
                <Form.Label className="d-flex align-items-center">Display Name <B2NormalTextTitleGrey>(required)</B2NormalTextTitleGrey></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your display name"
                  className="mb-4"
                  value={displayName}
                  onChange={e => setDisplayName(e.target.value)}
                />
                <div className="form-group">
                  <Form.Label className="mt-3">Custom Url</Form.Label>
                  <span>maximnft.com/</span>
                  <Form.Control
                    type="text"
                    placeholder="Enter short Url"
                    value={link}
                    className="mb-4"
                    style={{ paddingLeft: 140 }}
                    onChange={e => setLink(e.target.value)}
                  />
                </div>
                <Form.Label className="mt-3">Bio</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Tell about yourself in a few words"
                  className="mb-4"
                  value={bio}
                  onChange={e => setBio(e.target.value)}
                  style={{ paddingTop: 14 }}
                />
                <div className="mb-4 form-group">
                  <Form.Label className="mt-3">Twitter Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Link your twitter account to gain more trust on the marketplace"
                    value={twitterUsername}
                    className="pr-5"
                    onChange={e => setTwitterUsername(e.target.value)}
                  />
                  <FaLink />
                </div>
                <div className="mb-4 form-group">
                  <Form.Label className="mt-3">Instagram Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Link your instagram account to gain more trust on the marketplace"
                    value={instagram}
                    className="pr-5"
                    onChange={e => setInstagram(e.target.value)}
                  />
                  <FaLink />
                </div>
                <div className="form-group mb-0">
                  <Form.Label className="mt-3">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Your email for marketplace notifications"
                    value={email}
                    className="pr-5"
                    onChange={e => setEmail(e.target.value)}
                  />
                  <RiLockLine />
                </div>
                <div className="mt-1 mb-4 d-flex align-items-center">
                  <B2NormalTextTitleGrey className="font-size-14">You must sign message to view or manage your email.</B2NormalTextTitleGrey>
                  <span className="ml-2 text-nowrap pointer-cursor faint-black-color font-size-14">Sign message</span>
                </div>
                <Form.Label className="mt-3">Verification</Form.Label>
                {
                  verified ? (
                    <SmallTextTitle>Verified Successfully</SmallTextTitle>
                  ) : (
                    <SmallTextTitle>
                      Proceed with verification process to get more visibility and gain trust with the MaximNFT Marketplace. Please allow up to several weeks for the process.
                      <span className="pointer-cursor faint-black-color ml-2" onClick={() => onVerify()}>Just Verify</span>
                    </SmallTextTitle>
                  )
                }
                <div className="mt-5">
                  <Button
                    variant="primary"
                    className="outline-btn mr-3 my-2"
                    onClick={() => onSaveSettings()}
                  >
                    <span>Update Profile</span>
                  </Button>
                  {
                    !walletAddress && (
                      <ConnectWalletBtn />
                    )
                  }
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="avatar-uploader">
                <div className="d-flex justify-content-center mt-3 mt-md-0">
                  <BigTitle>Upload File</BigTitle>
                </div>
                <div className="avatar-preview">
                  <Image src={uploadImage || imageAvatar} alt="avatar-preview" />
                  <div className="remove-avatar" onClick={() => removeAvatar()}>
                    <FaTrash className="pointer-cursor"></FaTrash>
                  </div>
                </div>
                <FileUploader
                  title="We recommend an image of at least 400x400. Max 30MB. Gifs work too."
                  accept={configs.IMG_FILE_ACCEPT}
                  setFile={(e: any) => setAvatarFile(e)}
                  setPreview={(e: any) => setPreviewFile(e)}
                  delPreview={delPreview && !avatarImage}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    </Layout>
  );
};

export default Profile;
