import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from "components/Layout";

import "./Page404.scss";

interface Page404Props {}

const Page404: React.FC<Page404Props> = () => {

  return (
    <Layout className="page404-container">
      <Container>
        <div className="text-center w-100">
          <h2 className="h5 h2-md font-weight-bold mb-5">404. Page Not Found</h2>
          <Link to="/">
            <Button
              type="button"
              variant="primary"
              className="font-weight-bold font-size-md outline-btn"
            >
              <span>Go to Home</span>
            </Button>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default Page404;