import React from "react";
import { Button, Image } from "react-bootstrap";
import { useAppSelector } from "store/hooks";
import { getWalletAddress } from "store/User/user.selector";
import {
  B1NormalTextTitle,
  FlexAlignCenterDiv,
  FlexJustifyBetweenDiv,
  NftAvatar,
  SmallTextTitleGrey,
} from "../common/common.styles";
import imgAvatar from "assets/imgs/avatar.svg";
import verifyBadge from "assets/imgs/verify.png";
import Utility from "service/utility";

interface TokenOwnerItemProps {
  offers?: any;
  offer?: any;
  owner: any;
  token?: any;
  buyClicked?: any;
  onClick?: any;
}

const TokenOwnerItem: React.FC<TokenOwnerItemProps> = ({
  offers,
  offer,
  owner,
  token,
  buyClicked,
  onClick,
}) => {
  const walletAddress = useAppSelector(getWalletAddress);
  const getAvatar = () => {
    if (owner && owner.user) return Utility.mediaValidation(owner.user.avatar);
    return imgAvatar;
  };

  return (
    <>
      {offers &&
        offers.length > 0 &&
        offers.map((offerItem: any, index: number) => (
          <FlexJustifyBetweenDiv
            className="mb-3 flex-nowrap pointer-cursor"
            key={index}
          >
            <FlexAlignCenterDiv onClick={() => onClick(owner.user)}>
              <NftAvatar
                className="mr-1 mr-md-3 no-border"
                imagePath={getAvatar()}
              >
                {owner.user.verified && (
                  <Image width="20" height="20" src={verifyBadge} />
                )}
              </NftAvatar>

              <div className="item-detail">
                <B1NormalTextTitle>
                  {owner.user ? owner.user.name : ""}
                  &nbsp;
                  <span className="grey-color" style={{ fontSize: 12 }}>{`${
                    owner.on_sale
                  }/${owner.copies} ${
                    owner.on_sale > 1 ? "editions" : "edition"
                  }`}</span>
                </B1NormalTextTitle>

                <SmallTextTitleGrey className="grey-color">
                  {offerItem.available_copies && offerItem.offer_price && (
                    <>
                      {offerItem.sales_copies +
                        "/" +
                        offerItem.total_copies +
                        (offerItem.sales_copies > 1
                          ? " editions On Sale for "
                          : " edition On Sale for ") +
                        Utility.toPlainString(offerItem.offer_price) +
                        " " +
                        token.blockchain}
                      <br />
                    </>
                  )}

                  {!(offerItem.available_copies && offerItem.offer_price) &&
                    offerItem.available_copies &&
                    offerItem.min_bid && (
                      <>
                        {offerItem.status === "pending" &&
                          offerItem.available_copies &&
                          offerItem.available_copies +
                            (offerItem.available_copies > 1
                              ? " editions On Sale"
                              : " edition On Sale")}
                        <br />
                      </>
                    )}

                  {!(offerItem.available_copies && offerItem.offer_price) &&
                    !(offerItem.available_copies && offerItem.min_bid) && (
                      <>
                        {owner.copies &&
                          owner.copies +
                            (owner.copies > 1
                              ? " editions Not on Sale "
                              : " edition Not on Sale")}
                        <br />
                      </>
                    )}

                  {offerItem.type &&
                    (offerItem.type === "both" ||
                      offerItem.type === "auction") &&
                    offerItem.min_bid && (
                      <>
                        {"bid from " +
                          offerItem.min_bid +
                          " " +
                          token.blockchain}
                      </>
                    )}
                </SmallTextTitleGrey>
              </div>
            </FlexAlignCenterDiv>

            {owner?.user &&
              owner?.user.wallet !== walletAddress &&
              (offerItem?.type === "direct" || offerItem?.type === "both") &&
              offerItem?.status === "pending" &&
              owner?.on_sale &&
              !!offerItem?.sales_copies &&
              !(
                !!offerItem?.auction &&
                offerItem?.sales_copies === 1 &&
                token?.type === 1155
              ) && (
                <Button
                  variant="primary outline-btn mr-3"
                  onClick={() => buyClicked(offerItem)}
                >
                  <span>Buy</span>
                </Button>
              )}
          </FlexJustifyBetweenDiv>
        ))}

      {!(offers && offers.length > 0) && (
        <FlexJustifyBetweenDiv className="mb-3 pointer-cursor">
          <FlexAlignCenterDiv onClick={() => onClick(owner.user)}>
            <NftAvatar className="mr-3" imagePath={getAvatar()}>
              {owner.user.verified && (
                <Image width="20" height="20" src={verifyBadge} />
              )}
            </NftAvatar>
            <div>
              <B1NormalTextTitle>
                {owner.user ? owner.user.name : ""}
              </B1NormalTextTitle>
              {owner && offer ? (
                <SmallTextTitleGrey className="grey-color">
                  {offer.offer_price ? (
                    <>
                      On Sale for&nbsp;
                      {Utility.toPlainString(offer.offer_price) +
                        " " +
                        token.blockchain}
                      <br />
                    </>
                  ) : (
                    !offer.min_bid && (
                      <>
                        Not on Sale
                        <br />
                      </>
                    )
                  )}
                  {offer.min_bid ? (
                    <>
                      {"bid from " +
                        Utility.toPlainString(offer.min_bid) +
                        " " +
                        token.blockchain}
                    </>
                  ) : (
                    ""
                  )}
                </SmallTextTitleGrey>
              ) : (
                <SmallTextTitleGrey className="grey-color">
                  {owner.copies &&
                    owner.copies +
                      (owner.copies > 1
                        ? " editions Not on Sale "
                        : " edition Not on Sale")}
                </SmallTextTitleGrey>
              )}
            </div>
          </FlexAlignCenterDiv>
          {offer &&
          owner &&
          owner.user &&
          owner.user.wallet !== walletAddress &&
          (offer.type === "direct" || offer.type === "both") &&
          offer.status === "pending" ? (
            <Button
              variant="primary outline-btn mr-3"
              onClick={() => buyClicked(offer)}
            >
              <span>Buy</span>
            </Button>
          ) : (
            ""
          )}
        </FlexJustifyBetweenDiv>
      )}
    </>
  );
};

export default TokenOwnerItem;
