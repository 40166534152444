import configs from 'configs'
import moment from 'moment'
import EthereumIcon from "assets/imgs/ethereum.svg";
import BinanceIcon from "assets/imgs/binance.svg";
import PolygonIcon from "assets/imgs/polygon-matic.svg";
import ethUtil from 'ethereum/EthUtil';
import EthereumWhiteIcon from "assets/imgs/ethereum-white.svg";
import BinanceWhiteIcon from "assets/imgs/binance-white.svg";
import PolygonWhiteIcon from "assets/imgs/polygon-matic-white.svg";
import DateTimeService from './dateTime';
import imgAvatar from "assets/imgs/avatar.svg";
class Utility {
  public static downloadFile(error: any) {
    var element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' +
        encodeURIComponent(
          typeof error === 'object' ? JSON.stringify(error) : error,
        ),
    )
    element.setAttribute(
      'download',
      moment(new Date()).format('YYYY_MM_DD_hh_mm_ss'),
    )

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  public static mediaValidation(url: string) {
    if (url && (url.includes('https://') || url.includes('http://'))) {
      return url
    } else {
      return configs.DEPLOY_URL + url
    }
  }
  public static isFileImage(file: File) {
    return file && file['type'].split('/')[0] === 'image'
  }

  public static tryCatchRepeat = async (promise: any, maxTries = 10, simple = false, promiseTimeout = 1000) => {
    let count = 0;
    let responseData;
    
    while (count < maxTries) {
      try {
        // eslint-disable-next-line no-await-in-loop
  
        const response = await promise();
        console.log('Try catch', {count,response})
        if (simple) {
          if (response) {
            count = maxTries;
            responseData = response;
          } else {
            throw response;
          }
        } else if (response) {
          // eslint-disable-next-line no-await-in-loop
          const data = await response.json();
  
          if (data.status === '0') {
            throw data.result;
          }
  
          count = maxTries;
          responseData = data;
        }
      } catch (e) {
        await new Promise((r) => setTimeout(r, promiseTimeout));
        count++;
      }
    }

    return responseData;
  }

  public static getImageByNetwork = (network: any) => {
    if (network) {
      switch (network) {
        case 1:
          return EthereumIcon;
        case 4:
          return EthereumIcon;
        case 137:
          return PolygonIcon;
        case 80001:
          return PolygonIcon;
        case 56:
          return BinanceIcon;
        case 97:
          return BinanceIcon;
        default:
          return EthereumIcon;
      }
    } else {
      return EthereumIcon;
    }
  }

  public static getLoggedInUserAvatar = (loggedInUserInfo: any) => {
    if (loggedInUserInfo) {
      if (loggedInUserInfo.avatar) {
        return Utility.mediaValidation(loggedInUserInfo.avatar);
      }
    }
    return imgAvatar;
  }

  public static getNetworkNameByNetworkId = (network?: any) => {
    const net = network || ethUtil.getNetwork();
    
    if (net) {
      switch (net) {
        case 1:
          return 'ETH';
        case 4:
          return 'ETH';
        case 137:
          return 'MATIC';
        case 80001:
          return 'MATIC';
        case 56:
          return 'BNB';
        case 97:
          return 'BNB';
        default:
          return 'ETH';
      }
    } else {
      return 'ETH';
    }
  }

  public static getImageByNetworkName = (network: any, isBlack = true) => {
    if (network) {
      switch (network) {
        case 'ETH':
          return isBlack ? EthereumIcon : EthereumWhiteIcon;
        case 'RINKEBY':
          return isBlack ? EthereumIcon : EthereumWhiteIcon;
        case 'MATIC':
          return isBlack ? PolygonIcon : PolygonWhiteIcon;
        case 'BNB':
          return isBlack ? BinanceIcon : BinanceWhiteIcon;
        default:
          return isBlack ? EthereumIcon : EthereumWhiteIcon;
      }
    } else {
      return isBlack ? EthereumIcon : EthereumWhiteIcon;
    }
  }

  public static getFormDataFromObject(obj: any) {
    var formData = new FormData()
    for (var key in obj) {
      if (obj[key] != null) formData.append(key, obj[key])
    }
    return formData
  }

  public static getParamStrFromObj(obj: any) {
    let paramStr = '?'
    for (var key in obj) {
      paramStr += `&${key}=${obj[key]}`
    }
    return paramStr
  }

  public static getHiddenWalletAddress(str: string) {
    if (!str || str.length < 20) return ''
    return (
      str.substring(0, 15) + '...' + str.substring(str.length - 4, str.length)
    )
  }

  public static getPassedTimeString(isoTime: any) {
    if (!isoTime) return ''
    let obj: any = this.getPassedTimeObject(isoTime)
    let str = ''
    for (let key in obj) {
      str = str + obj[key] + key[0]
      if (key !== 'sec') str += ' '
    }
    return str
  }

  public static toFixedOrPrecision(num: number | string) {
    if (Number(num) === 0) return `$0`;
    if (Number(num) > 0.01) return `\$${Number(Number(num).toFixed(2))}`;
    return '< $0.01'
  }

  public static getPassedTimeObject(isoTime: any) {
    let offerTimestamp = new Date(isoTime).getTime()
    let currentTimestamp = new Date().getTime()
    let diff: number = Number((offerTimestamp - currentTimestamp) / 1000)
    const day: number = Math.floor(diff / (3600 * 24)) || 0
    const dayRest: number = Math.floor(diff % (3600 * 24))
    const hr = Math.floor(dayRest / 3600) || 0
    let remainSecs = dayRest % 3600
    const min = Math.floor(remainSecs / 60) || 0
    let secs = Math.floor(remainSecs % 60) || 0
    if (!day && !hr && !min && !secs) {
      return null
    }
    return { day: day, hour: hr, min: min, sec: secs }
  }

  public static getPassedDurationObject(isoTime: any) {
    let currentTimestamp = new Date().getTime()
    let diff: number = Number((isoTime - currentTimestamp) / 1000)
    const day: number = Math.floor(diff / (3600 * 24)) || 0
    const dayRest: number = Math.floor(diff % (3600 * 24))
    const hr = Math.floor(dayRest / 3600) || 0
    let remainSecs = dayRest % 3600
    const min = Math.floor(remainSecs / 60) || 0
    let secs = Math.floor(remainSecs % 60) || 0
    return { day: day, hour: hr, min: min, sec: secs }
  }

  public static getDiffDate (date: any) {
    if (date) {
      const diff = DateTimeService.getDurationSecondsWithTwoDates(
        "now",
        date
      );

      if (diff > 0) {
        return date;
      }
    }

    return null;
  };

  public static toPlainString(number: any) {
    return (''+ +number).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function(a,b,c,d,e) {
        return e < 0
          ? b + '0.' + Array(1-e-c.length).join('0') + c + d
          : b + c + d + Array(e-d.length+1).join('0');
      });
  }
}

export default Utility
