import React from "react";
import moment from 'moment';
import {
  B1NormalTextTitle,
  B1NormalTextTitleGrey,
  SmallTextTitle,
  SmallTextTitleGrey,
  NftAvatar,
} from "../common/common.styles";
import imgAvatar from "assets/imgs/avatar.svg";
import { Image } from 'react-bootstrap';
import verifyBadge from 'assets/imgs/verify.png';
import Utility from 'service/utility';

interface HistoryItemProps {
  item: any;
  token?: any;
  onClick?: any;
}

const HistoryItem: React.FC<HistoryItemProps> = ({ item, token, onClick }) => {
  const getDetailDateTime = (history: any) => {
    return <div className="detail-info d-flex align-items-center flex-wrap"><SmallTextTitleGrey className="grey-color">by&nbsp;</SmallTextTitleGrey>
      <SmallTextTitle style={{ whiteSpace: 'nowrap', fontWeight: '500' }}>{history.user.name}</SmallTextTitle>
      <SmallTextTitleGrey className="grey-color">&nbsp;at {moment(history.date).format('M/D/YYYY, H:mm A')}</SmallTextTitleGrey>
    </div>;
  }
  const getTitle = (history: any) => {
    switch(history.type) {
      case 'liked':
        return <div className="d-flex align-items-center">
          <B1NormalTextTitleGrey>Liked</B1NormalTextTitleGrey>
          {/* <B1NormalTextTitle>{history.user.name}</B1NormalTextTitle> */}
        </div>;
      case 'listed':
        return <div className="d-flex align-items-center">
          <B1NormalTextTitleGrey>Listed for&nbsp;</B1NormalTextTitleGrey>
          <B1NormalTextTitle>{history.price} {token.blockchain}</B1NormalTextTitle>
          {history.copies && <B1NormalTextTitleGrey>&nbsp;{history.copies + (history.copies > 1 ? ' editions' : ' edition')}</B1NormalTextTitleGrey>}
        </div>;
      case 'purchased':
        return <div className="d-flex align-items-center">
          <B1NormalTextTitleGrey>Purchased for&nbsp;</B1NormalTextTitleGrey>
          <B1NormalTextTitle>{history.price} {token.blockchain}</B1NormalTextTitle>
          {history.copies && <B1NormalTextTitleGrey>&nbsp;{history.copies + (history.copies > 1 ? ' editions' : ' edition')}</B1NormalTextTitleGrey>}
        </div>;
      case 'minted':
        return <div className="d-flex align-items-center">
          <B1NormalTextTitleGrey>Minted</B1NormalTextTitleGrey>
          {/* <B1NormalTextTitle>{history.user.name}</B1NormalTextTitle> */}
        </div>;
      case 'offered':
        return <div className="d-flex align-items-center">
          <B1NormalTextTitleGrey>Bid&nbsp;</B1NormalTextTitleGrey>
          <B1NormalTextTitle>{history.price} {token.blockchain}</B1NormalTextTitle>
          {history.copies && <B1NormalTextTitleGrey>&nbsp;{history.copies + (history.copies > 1 ? ' editions' : ' edition')}</B1NormalTextTitleGrey>}
        </div>;
      default:
        break;
    }
  }

  const getAvatar = () => {
    if(item.user && item.user.avatar) return Utility.mediaValidation(item.user.avatar);
    return imgAvatar;
  }

  return (
    <div className="history-item mb-2 pointer-cursor" onClick={() => onClick(item.user)}>
      <NftAvatar imagePath={getAvatar()} className="mr-3 no-border">
        {item.user && item.user.verified && <Image width="20" height="20" src={verifyBadge} />}
      </NftAvatar>
      <div>
        {getTitle(item)}
        {getDetailDateTime(item)}
      </div>
    </div>
  );
};

export default HistoryItem;