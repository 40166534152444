import API from 'service/api';

class SearchController {
    public static tokenSearch(params: any, page: number) {
        return API.post(`/offers/search${page === 1 ? '' : '?page='+page}`, params).then(res => res.data);
    }
    public static collectionSearch(params: any, page: number) {
      return API.post(`/collections/search${page === 1 ? '' : '?page='+page}`, params).then(res => res.data);
    }
    public static userSearch(params: any, page: number) {
      return API.post(`/users/search${page === 1 ? '' : '?page='+page}`, params).then(res => res.data);
    }
}

export default SearchController;