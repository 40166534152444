import CreateNftStatusModal from "components/token/CreateNftStatusModal";
import configs from "configs";
import smartContract from "ethereum/Contract1155";
import ethMethods from "ethereum/EthMethods";
import ethUtil from "ethereum/EthUtil";
import { web3 } from "ethereum/OnBoard";
import { NftCreateStatus } from "model/NftCreateStatus";
import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import useState from "react-usestateref";
import { useAppDispatch, useInterval } from "store/hooks";
import { getWalletBalance } from "store/User/user.slice";

const CreateERC1155OfferModal: React.FC<any> = ({
  show,
  collectible,
  onClose,
  onSuccess,
  token,
  chainId,
  innerCreateNftStatus = NftCreateStatus.NONE,
  createNft,
  updateDataCallback,
}) => {
  const dispatch = useAppDispatch();
  const [, setProcessStatus, processStatusRef] = useState(false);
  const [, setIsSpeedUpLoading, isSpeedUpLoadingRef] = useState(false);
  const [createNftStatus, setCreateNftStatus, createNftStatusRef] = useState(
    NftCreateStatus.NONE
  );

  const errorInterval = useInterval();
  const txInterval = useInterval();

  useEffect(() => {
    if (!show || !!createNft) return;

    if (
      createNftStatus === NftCreateStatus.NONE ||
      createNftStatus === NftCreateStatus.APPROVE_FAILED
    ) {
      approveNft();
    }
  }, [show]);

  useEffect(() => {
    setCreateNftStatus(innerCreateNftStatus);
  }, [innerCreateNftStatus]);

  const createOfferCallback = async (offerId: any, transactionHash?: any) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    if (transactionHash) {
      await ethUtil.checkAndWaitNextBlock(transactionHash);
    }

    const result = await ethMethods.handleCreateOffer(
      token,
      offerId,
      1155,
      undefined,
      rejectCreateOfferCallback
    );

    if (!result) {
      return;
    }

    dispatch(getWalletBalance());
    setCreateNftStatus(NftCreateStatus.CREATEOFFER_SUCCEED);
    onClose();
    setIsSpeedUpLoading(false);
    onSuccess();
    setCreateNftStatus(NftCreateStatus.NONE);
    localStorage.removeItem("network");
    setTimeout(function () {
      // @ts-ignore
      document.getElementById("layout").scrollTo({
        // @ts-ignore
        top: 0,
        behavior: "smooth",
      });
    }, 200);
  };

  const approveTokenCallback = async (transactionHash: any) => {
    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);

    if (transactionHash) {
      await ethUtil.checkAndWaitNextBlock(transactionHash);
    }

    setCreateNftStatus(NftCreateStatus.APPROVE_SUCCEED);

    dispatch(getWalletBalance());
  };

  const rejectApproveTokenCallback = (error?: any) => {
    if (
      error?.message?.startsWith("Transaction was not mined within 50 blocks")
    )
      return;

    if (createNftStatusRef.current === NftCreateStatus.APPROVE_FAILED) return;

    clearInterval(txInterval.current);
    txInterval.current = null;
    clearInterval(errorInterval.current);
    errorInterval.current = null;

    setProcessStatus(true);
    if (updateDataCallback) updateDataCallback();
    setCreateNftStatus(NftCreateStatus.APPROVE_FAILED);
    NotificationManager.error("NFT approval did not succeed", "Error");

    if (error) {
      ethUtil.catchError(error);
    }
  };

  const rejectCreateOfferCallback = (error?: any) => {
    if (
      error?.message?.startsWith("Transaction was not mined within 50 blocks")
    )
      return;

    if (createNftStatusRef.current === NftCreateStatus.CREATEOFFER_FAILED)
      return;

    clearInterval(errorInterval.current);
    errorInterval.current = null;
    clearInterval(txInterval.current);
    txInterval.current = null;

    setProcessStatus(true);
    if (updateDataCallback) updateDataCallback();
    setCreateNftStatus(NftCreateStatus.CREATEOFFER_FAILED);
    NotificationManager.error("Offer is not created successfully", "Error");

    if (error) {
      ethUtil.catchError(error);
    }
  };

  const handleOnSpeedUpApproveNft = async (nonce: any, contract: any) => {
    if (isSpeedUpLoadingRef.current) return;

    setIsSpeedUpLoading(true);

    const speedUpData = await ethUtil.catchSpeedUpOrCancelTx(
      nonce,
      rejectApproveTokenCallback,
      "ApprovalForAll",
      contract
    );

    if (!speedUpData) return setIsSpeedUpLoading(false);

    const transactionHash = speedUpData.tx.hash;

    await approveTokenCallback(transactionHash);

    setIsSpeedUpLoading(false);
  };

  const handleOnSpeedUpCreateOffer = async (nonce: any, contract: any) => {
    if (isSpeedUpLoadingRef.current) return;

    setIsSpeedUpLoading(true);

    const speedUpData = await ethUtil.catchSpeedUpOrCancelTx(
      nonce,
      rejectCreateOfferCallback,
      "OfferCreated",
      contract
    );

    if (!speedUpData) return setIsSpeedUpLoading(false);

    const offerId = ethUtil.getDataFromEvent(speedUpData.event, 0);

    const transactionHash = speedUpData.tx.hash;

    await createOfferCallback(offerId, transactionHash);
  };

  const approveNft = async () => {
    setCreateNftStatus(NftCreateStatus.APPROVE_PROGRESSING);

    try {
      if (chainId && web3) {
        const approved = await smartContract.getApprovedForAll();

        if (approved) {
          setCreateNftStatus(NftCreateStatus.APPROVE_SUCCEED);
          return;
        }

        const xSigmaContract = smartContract.getXsigma1155Contract();

        const gasEstimate: any = await xSigmaContract.methods
          .setApprovalForAll(smartContract.getEngine1155Address(), true)
          .estimateGas({
            from: ethUtil.getAddress(),
            data: await smartContract.getEngine1155Bytecode(),
          });

        if (gasEstimate) {
          setProcessStatus(false);
          let hash = "";

          await new web3.eth.sendTransaction({
            from: ethUtil.getAddress(),
            to: smartContract.getXsigma1155Address(),
            gas: gasEstimate + configs.GAS_LIMIT,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
            ...((await ethUtil.getGasPrice()) || {}),
            data: xSigmaContract.methods
              .setApprovalForAll(smartContract.getEngine1155Address(), true)
              .encodeABI(),
          })
            .on("transactionHash", async function (transactionHash: any) {
              hash = transactionHash;
              let nonce: any;

              if (transactionHash) {
                var counter = configs.TIMEOUT;

                txInterval.current = setInterval(async function () {
                  if (!nonce) {
                    const tx = await ethUtil.getTransaction(transactionHash);

                    if (tx) {
                      nonce = tx.nonce;
                    }
                  }

                  counter--;

                  if (counter % 20 === 0) {
                    if (isSpeedUpLoadingRef.current) return;

                    const tx = await ethUtil.getTransaction(transactionHash);
                    const result = await ethUtil.getTransactionReceipt(
                      transactionHash
                    );

                    console.log({ tx, result });

                    if (!tx && !result) {
                      await handleOnSpeedUpApproveNft(nonce, xSigmaContract);
                    }

                    if (
                      result &&
                      result?.status === true &&
                      !processStatusRef.current &&
                      txInterval.current
                    ) {
                      await approveTokenCallback(transactionHash);
                    } else if (
                      result &&
                      result?.status === false &&
                      !processStatusRef.current
                    ) {
                      rejectApproveTokenCallback();
                    }
                  }

                  if (processStatusRef.current) {
                    clearInterval(errorInterval.current);
                    errorInterval.current = null;
                    clearInterval(txInterval.current);
                    txInterval.current = null;
                  }
                }, 1000);
              }
            })
            .on("receipt", async function (receipt: any) {
              if (
                receipt &&
                receipt.status === true &&
                !processStatusRef.current
              ) {
                console.log({ receipt });
                await approveTokenCallback(receipt.transactionHash);
              } else if (
                receipt &&
                receipt.status === false &&
                !processStatusRef.current
              ) {
                rejectApproveTokenCallback();
              }
            })
            .on("error", async function (error: any) {
              ethUtil.txErrorHandler(
                error,
                hash,
                processStatusRef.current,
                errorInterval,
                setProcessStatus,
                setCreateNftStatus,
                NftCreateStatus.APPROVE_PROGRESSING,
                NftCreateStatus.APPROVE_FAILED
              );
            });
        }
      }
    } catch (error) {
      rejectApproveTokenCallback(error);
    }
  };

  const createOffer = async () => {
    setCreateNftStatus(NftCreateStatus.CREATEOFFER_PROGRESSING);

    try {
      const engineContract = smartContract.getEngine1155Contract();

      if (web3 && chainId && token) {
        const auctionStart = 0;
        const isDirectSale = Number(collectible.offer_price) > 0;
        const isAuction = Number(collectible.min_bid_price) > 0;
        const duration = isDirectSale && !isAuction ? 0 : collectible.duration;

        const gasEstimate: any = await engineContract.methods
          .createOffer(
            smartContract.getXsigma1155Address(),
            chainId,
            collectible.copies,
            isDirectSale,
            isAuction,
            ethUtil.toWei(collectible.offer_price),
            ethUtil.toWei(collectible.min_bid_price),
            auctionStart,
            duration
          )
          .estimateGas({
            from: ethUtil.getAddress(),
            data: await smartContract.getEngine1155Bytecode(),
          });

        if (gasEstimate) {
          setProcessStatus(false);
          let hash = "";

          await engineContract.methods
            .createOffer(
              smartContract.getXsigma1155Address(),
              chainId,
              collectible.copies,
              isDirectSale,
              isAuction,
              ethUtil.toWei(collectible.offer_price),
              ethUtil.toWei(collectible.min_bid_price),
              auctionStart,
              duration
            )
            .send({
              from: ethUtil.getAddress(),
              gas: gasEstimate + configs.GAS_LIMIT,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null,
              ...((await ethUtil.getGasPrice()) || {}),
            })
            .on("transactionHash", async function (transactionHash: any) {
              hash = transactionHash;
              let nonce: any;

              if (transactionHash) {
                var counter = configs.TIMEOUT;

                txInterval.current = setInterval(async function () {
                  if (!nonce) {
                    const tx = await ethUtil.getTransaction(transactionHash);

                    if (tx) {
                      nonce = tx.nonce;
                    }
                  }

                  counter--;

                  if (counter % 20 === 0) {
                    if (isSpeedUpLoadingRef.current) return;

                    const tx = await ethUtil.getTransaction(transactionHash);
                    const result = await ethUtil.getTransactionReceipt(
                      transactionHash
                    );

                    console.log({ tx, result });

                    if (!tx && !result) {
                      await handleOnSpeedUpCreateOffer(nonce, engineContract);
                    }

                    if (
                      result &&
                      result?.status === true &&
                      !processStatusRef.current &&
                      txInterval.current
                    ) {
                      const tokenId = await ethUtil.getDataFromTxReceipt(
                        result,
                        0,
                        engineContract,
                        "OfferCreated"
                      );

                      console.log("tokenId: " + tokenId);

                      if (tokenId && txInterval.current) {
                        await createOfferCallback(tokenId, transactionHash);
                      }
                    } else if (
                      result &&
                      result?.status === false &&
                      !processStatusRef.current
                    ) {
                      rejectCreateOfferCallback();
                    }
                  }

                  if (processStatusRef.current) {
                    clearInterval(errorInterval.current);
                    errorInterval.current = null;
                    clearInterval(txInterval.current);
                    txInterval.current = null;
                  }
                }, 1000);
              }
            })
            .on("receipt", async function (receipt: any) {
              if (
                receipt &&
                receipt.status === true &&
                !processStatusRef.current &&
                txInterval.current
              ) {
                console.log(receipt);
                await createOfferCallback(
                  receipt.events.OfferCreated.returnValues[0],
                  receipt.transactionHash
                );
              } else if (
                receipt &&
                receipt.status === false &&
                !processStatusRef.current
              ) {
                rejectCreateOfferCallback();
              }
            })
            .on("error", async function (error: any) {
              ethUtil.txErrorHandler(
                error,
                hash,
                processStatusRef.current,
                errorInterval,
                setProcessStatus,
                setCreateNftStatus,
                NftCreateStatus.CREATEOFFER_PROGRESSING,
                NftCreateStatus.CREATEOFFER_FAILED
              );
            });
        }
      }
    } catch (error) {
      rejectCreateOfferCallback(error);
    }
  };

  return (
    <CreateNftStatusModal
      show={show}
      status={createNftStatus}
      collectible={collectible}
      onClose={onClose}
      createNft={createNft}
      approveNft={approveNft}
      createOffer={createOffer}
    ></CreateNftStatusModal>
  );
};

export default CreateERC1155OfferModal;
