/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import {
  // Dropdown,
  Button,
  Dropdown,
  Form,
  FormControl,
  Image,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tab,
  Tooltip,
} from "react-bootstrap";
import Layout from "components/Layout";
import CollectionController from "controller/CollectionController";
import LoadingBar from "components/common/LoadingBar";
import NoItem from "components/common/noItem";
import "./CollectionDetail.scss";
// import { FaFacebook, FaMailBulk, FaTelegram, FaTwitter } from "react-icons/fa";
import Utility from "service/utility";
import configs from "configs";
import OnSaleItem from "components/myItems/OnSaleItem";
import ReadMore from "components/common/ReadMore";
// import { AiOutlineCloudUpload } from 'react-icons/ai';
import TokenView from "components/token/TokenView";
import defaultCover from "assets/imgs/default_pic.png";
import verifyBadge from "assets/imgs/verify.png";
import DateTimeService from "service/dateTime";
import { BiInfoCircle } from "react-icons/bi";
import AuctionItem from "components/auction/AuctionItem";
import classNames from "classnames";

interface CollectionDetailProps {}

const _categories = [
  { title: "On sale", count: 0, active: false, path: "on_sale" },
  { title: "Owned", count: 0, active: false, path: "owned" },
];

const _collectionInfo = {
  _id: "",
  name: "",
  short: "",
  symbol: "",
  items: 0,
  floor_price: 0,
  volume: 0,
  date_create: "",
  date_launch: "",
  description: "",
  image: "",
  thumbnail: "",
  cover: "",
  blockchain: "",
  creator: {
    avatar: "",
    bio: "",
    cover: "",
    date_create: "",
    name: "",
    wallet: "",
    _id: "",
    verified: false,
  },
};

const CollectionDetail: React.FC<CollectionDetailProps> = () => {
  const history = useHistory();
  const params: any = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingCollection, setLoadingCollection] = useState(true);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNumber] = useState(1);
  const [collectionInfo, setCollectionInfo] = useState(_collectionInfo);
  const [categories, setCategories] = useState(_categories);
  const [itemList, setItemList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("owned");
  const [walletAddress, setWalletAddress] = useState("");
  const [walletHiddenText, setHiddenText] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [remainTimeObj, setRemainTimeObj] = useState<any>({});
  const [postEmail, setEmail] = useState("");
  const [items_count, setCount] = useState(0);

  useEffect(() => {
    const loadTokensAndCollections = async () => {
      try {
        setCollectionId(params.id);

        if (collectionId && collectionId !== "xsigma") {
          setLoadingCollection(true);

          let data = await CollectionController.getById(collectionId);

          if (data.collection) {
            setCollectionInfo(data.collection);
            setWalletAddress(data.collection.creator.wallet);
          }

          if (data.stats) {
            for (let i = 0; i < categories.length; i++) {
              const category = categories[i];
              if (data.stats[category.path]) {
                category.count = data.stats[category.path];
              } else {
                category.count = 0;
              }
            }
            setCategories(categories);
          }

          setLoadingCollection(false);
        }

        if (collectionId === "xsigma") {
          pageNum > 1 ? setLoading(true) : setLoadingCollection(true);

          let data =
            pageNum > 1
              ? await CollectionController.getById(collectionId, pageNum)
              : await CollectionController.getById(collectionId);

          if (data.collection) {
            setCollectionInfo({ ...collectionInfo, ...data.collection });
          }

          if (data.items_count) {
            setCount(data.items_count);
          }

          if (pageNum === 1 && data.pages) {
            setPages(data.pages);
          }

          setWalletAddress("");
          setItemList(
            pageNum === 1 ? data.tokens : itemList.concat(data.tokens)
          );

          pageNum > 1 ? setLoading(false) : setLoadingCollection(false);
        }
      } catch (err) {
        if (err) {
          history.push("/404");
        }
      }
    };
    loadTokensAndCollections();
    setHiddenText(Utility.getHiddenWalletAddress(walletAddress));
  }, [categories, collectionId, walletAddress, pageNum]);

  useEffect(() => {
    if (!loadingCollection) {
      loadItems();
    }
  }, [selectedTab, pageNum, loadingCollection]);

  const loadItems = async () => {
    if (params.id !== "xsigma" && collectionId && collectionId !== "") {
      setLoading(true);
      let { items, pages } = await CollectionController.getCollections(
        collectionId,
        selectedTab,
        pageNum
      );
      if (pageNum === 1) {
        setPages(pages);
      }
      setItemList(pageNum === 1 ? items : itemList.concat(items));
      setLoading(false);
    }
  };

  const onSelectType = (type: any) => {
    setSelectedTab(type);
    setPages(1);
    setPageNumber(1);
  };

  const copyWalletAddress = async () => {
    setHiddenText("Copied!");
    setTimeout(() => {
      setHiddenText(Utility.getHiddenWalletAddress(walletAddress));
    }, 2000);
    await navigator.clipboard.writeText(walletAddress);
  };

  const getUserImgAvatar = () => {
    if (collectionInfo.creator && collectionInfo.creator.avatar)
      return Utility.mediaValidation(collectionInfo.creator.avatar);
    return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
  };

  const getCoverImage = () => {
    if (collectionInfo.cover || collectionInfo.image) {
      return Utility.mediaValidation(
        collectionInfo.cover || collectionInfo.image
      );
    }
    return defaultCover;
  };

  useEffect(() => {
    setRemainTimeObj(
      Utility.getPassedTimeObject(
        Utility.getDiffDate(collectionInfo?.date_launch)
      )
    );
  }, [collectionInfo]);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (Utility.getDiffDate(collectionInfo?.date_launch)) {
        let obj = Utility.getPassedTimeObject(
          Utility.getDiffDate(collectionInfo?.date_launch)
        );
        setRemainTimeObj(obj);
      }
    }, 1000);

    return () => {
      if (timeInterval) clearInterval(timeInterval);
    };
  }, [collectionInfo, remainTimeObj]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() !== false) {
      var emailPattern =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (postEmail && !emailPattern.test(postEmail)) {
        NotificationManager.error("Considered as not valid email", "Error");
        return;
      }
      await axios
        .post(`${configs.MAXIMNFT_URL}?collection=true`, {
          email: postEmail,
          collectionID: collectionInfo._id,
        })
        .then((res) => {
          if (res && res.status === 200) {
            NotificationManager.success("Successfully", "Success");
          }
        })
        .catch((err) => {
          if (err) {
            NotificationManager.error("Failed", "Error");
          }
        });
    }
  };

  return (
    <Layout className="collection-detail-container">
      {loadingCollection ? (
        <div className="mt-auto mb-auto d-flex justify-content-center loading-bar">
          <LoadingBar />
        </div>
      ) : (
        <>
          <div className="collection-detail__head">
            <div
              className="collection-detail__intro"
              style={{ backgroundImage: `url("${getCoverImage()}")` }}
            ></div>
            <div className="collection-detail__body">
              <Image
                src={getUserImgAvatar()}
                className="collection-detail__avatar"
              />

              <div className="collection-detail__title">
                {Utility.getDiffDate(collectionInfo?.date_launch) && (
                  <div className="collection-detail__status">UPCOMING</div>
                )}
                {collectionId !== "xsigma" ? (
                  <>
                    {collectionInfo && collectionInfo.creator.verified && (
                      <Image src={verifyBadge} className="verify-badge" />
                    )}
                    {collectionInfo.name}
                  </>
                ) : (
                  "xSigma"
                )}
              </div>

              {collectionInfo.description && (
                <div className="collection-detail__description">
                  <ReadMore text={collectionInfo.description} maxChars={150} />
                </div>
              )}

              {!Utility.getDiffDate(collectionInfo?.date_launch) && (
                <div className="collection__details">
                  <div className="collection__details-container">
                    <div className="collection__details-item">
                      <div className="collection__details-title">Items</div>
                      <div className="collection__details-value">
                        {collectionInfo?.items || 0}
                      </div>
                    </div>
                    <div className="collection__details-item">
                      <div className="collection__details-title">
                        Sales Volume
                      </div>
                      <div className="collection__details-value">
                        <Image
                          src={Utility.getImageByNetworkName(
                            collectionInfo.blockchain,
                            false
                          )}
                          width="16"
                        />
                        {Number(collectionInfo?.volume?.toFixed(4)) || 0}
                      </div>
                    </div>
                    <div className="collection__details-item">
                      <div className="collection__details-title">
                        Floor Price
                      </div>
                      <div className="collection__details-value">
                        <Image
                          src={Utility.getImageByNetworkName(
                            collectionInfo.blockchain,
                            false
                          )}
                          width="16"
                        />
                        {Number(collectionInfo?.floor_price?.toFixed(4)) || 0}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {Utility.getDiffDate(collectionInfo?.date_launch) && (
                <div className="collection-detail__timer-wrapper">
                  <div className="collection-detail__timer-title">
                    The sales open in
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column">
                      <span className="collection-detail__timer-time">
                        {remainTimeObj.day ? remainTimeObj.day : "0"}
                      </span>
                      <span className="collection-detail__timer-label">
                        Days
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="collection-detail__timer-time">
                        {remainTimeObj.hour ? remainTimeObj.hour : "0"}
                      </span>
                      <span className="collection-detail__timer-label">
                        Hours
                      </span>{" "}
                    </div>
                    <div className="d-flex flex-column">
                      <span className="collection-detail__timer-time">
                        {remainTimeObj.min ? remainTimeObj.min : "0"}
                      </span>
                      <span className="collection-detail__timer-label">
                        Minutes
                      </span>
                    </div>
                  </div>
                  <div className="text-center d-flex justify-content-center">
                    <Dropdown>
                      <Dropdown.Toggle className="outline-btn mx-auto outline-btn--white">
                        <span>Subscribe</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu renderOnMount={true}>
                        <Form className="mx-auto d-flex" onSubmit={onSubmit}>
                          <FormControl
                            style={{ width: 300 }}
                            required
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={postEmail}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Button className="outline-btn mx-auto" type="submit">
                            <span>Subscribe</span>
                          </Button>
                        </Form>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
              <div className="collection-detail__btn-wrapper">
                <div className="collection-detail__btn">
                  <BiInfoCircle />
                  <span>ERC1155</span>
                </div>
                {collectionInfo.blockchain && (
                  <div className="collection-detail__btn">
                    <Image
                      src={Utility.getImageByNetworkName(
                        collectionInfo.blockchain,
                        false
                      )}
                      height="22"
                      width="22"
                    />
                    <span>{collectionInfo.blockchain}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!Utility.getDiffDate(collectionInfo?.date_launch) && (
            <div className="collection-detail__tokens">
              <Tab.Container
                defaultActiveKey={params.id === "xsigma" ? "tokens" : "owned"}
                id="items_tabs"
              >
                <div className="link-section px-md-5">
                  <Nav
                    defaultActiveKey={
                      params.id === "xsigma" ? "tokens" : "owned"
                    }
                  >
                    {collectionId === "xsigma" ? (
                      <Fragment>
                        <Nav.Item onClick={() => onSelectType("xsigma")}>
                          <Nav.Link eventKey="tokens">
                            xSigma Tokens
                            <span
                              className={itemList.length ? "fill-count" : ""}
                            >
                              {items_count}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Fragment>
                    ) : (
                      categories.map((category, index) => (
                        <Nav.Item
                          key={index}
                          className={classNames({ disabled: loading })}
                          onClick={() => {
                            onSelectType(category.path);
                          }}
                        >
                          <Nav.Link eventKey={category.path}>
                            {category.title}
                            <span
                              className={category.count ? "fill-count" : ""}
                            >
                              {category.count}
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      ))
                    )}
                  </Nav>
                </div>
                <div className="item-columns">
                  <Tab.Content>
                    <Tab.Pane eventKey="on_sale">
                      {loading && pageNum === 1 ? (
                        <div className="d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="auction-section">
                          <div className="item-list">
                            {itemList.length > 0 ? (
                              itemList.map((saleItem, i) => (
                                <AuctionItem
                                  item={saleItem}
                                  key={i}
                                ></AuctionItem>
                              ))
                            ) : (
                              <NoItem
                                title="No items found"
                                description="Come back soon! Or try to browse something for you on our marketplace"
                                btnLink="/"
                                btnLabel="Browse marketplace"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="owned">
                      {loading && pageNum === 1 ? (
                        <div className="d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="collections-section">
                          <div className="item-list">
                            {itemList.length > 0 ? (
                              itemList.map(
                                (collection: any, i: number) =>
                                  collection._id && (
                                    <TokenView
                                      key={i}
                                      item={collection}
                                      user={collection.creator}
                                    ></TokenView>
                                  )
                              )
                            ) : (
                              <NoItem
                                title="No items found"
                                description="Come back soon! Or try to browse something for you on our marketplace"
                                btnLink="/"
                                btnLabel="Browse marketplace"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="tokens">
                      {loading && pageNum === 1 ? (
                        <div className="d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        <div className="collections-section">
                          <div className="item-list">
                            {itemList.length > 0 ? (
                              itemList.map(
                                (token: any, i: number) =>
                                  token._id && (
                                    <TokenView
                                      key={i}
                                      item={token}
                                      user={token.creator}
                                    ></TokenView>
                                  )
                              )
                            ) : (
                              <NoItem
                                title="No items found"
                                description="Come back soon! Or try to browse something for you on our marketplace"
                                btnLink="/"
                                btnLabel="Browse marketplace"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    {loading && pageNum > 1 ? (
                      <div className="d-flex justify-content-center loading-bar">
                        <LoadingBar />
                      </div>
                    ) : (
                      itemList.length > 0 &&
                      pages > pageNum && (
                        <div className="my-3 d-flex justify-content-center">
                          <Button
                            variant="primary"
                            className="btn-round w-50 outline-btn"
                            onClick={() => setPageNumber(pageNum + 1)}
                          >
                            <span>Load More</span>
                          </Button>
                        </div>
                      )
                    )}
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default CollectionDetail;
