/* eslint-disable react-hooks/exhaustive-deps */
import CollectionController from "controller/CollectionController";
import React, { useEffect, useState } from "react";
import {
  FlexColumnContainer,
  FlexSlideContainer,
} from "../common/common.styles";
import CollectionItem from "./CollectionItem";
import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import LoadingBar from "components/common/LoadingBar";
import { Button } from "react-bootstrap";
interface CollectionsProps {
  type: string;
  isSlider: boolean;
}

const Collections: React.FC<CollectionsProps> = ({ type, isSlider }) => {
  const [loading, setLoading] = useState(false);
  const [collectionList, setCollections] = useState<any[]>([]);
  const [pageNum, setPageNumber] = useState(1);
  const [showItems, setShowItems] = useState(5);
  const [increaseVal, setIncreaseVal] = useState(3);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    const loadCollections = async () => {
      try {
        setLoading(true);
        let data = await CollectionController.getList(type, pageNum);

        if (
          data &&
          data.collections &&
          data.collections.length + collectionList.length >= showItems
        ) {
          setShowArrows(true);
        } else {
          setShowArrows(false);
        }

        setCollections([...collectionList, ...data.collections]);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    loadCollections();
  }, [type, pageNum]);

  const onNextCollections = () => {
    let slideElement = document.getElementById(`slider_${type}`);
    if (slideElement && slideElement.scrollWidth > slideElement.offsetWidth) {
      slideElement.scrollTo({
        left: slideElement.scrollWidth,
        behavior: "smooth",
      });
      loadMoreCollections();
    }
  };

  const loadMoreCollections = () => {
    if (showItems + increaseVal < collectionList.length) {
      setShowItems(showItems + increaseVal);
    } else {
      setPageNumber(pageNum + 1);
      setShowItems(showItems + increaseVal);
    }
  };

  const onPrevCollections = () => {
    let slideElement = document.getElementById(`slider_${type}`);
    if (slideElement && slideElement.scrollWidth > slideElement.offsetWidth) {
      slideElement.scrollTo({ left: 0, behavior: "smooth" });
    }
  };

  return isSlider ? (
    <FlexSlideContainer className="slide-container" id={`slider_${type}`}>
      {showArrows && (
        <div
          className="arrow-btn left pointer-cursor"
          onClick={() => onPrevCollections()}
        >
          <HiOutlineChevronDoubleLeft />
        </div>
      )}
      {collectionList.map(
        (collection, index) =>
          index < showItems && <CollectionItem item={collection} key={index} />
      )}
      {showArrows && (
        <div
          className="arrow-btn right pointer-cursor"
          onClick={() => onNextCollections()}
        >
          <HiOutlineChevronDoubleRight />
        </div>
      )}
    </FlexSlideContainer>
  ) : (
    <>
      <FlexColumnContainer>
        {loading && pageNum === 1 ? (
          <div className="loading-bar my-5 ml-auto mr-auto d-flex justify-content-center">
            <LoadingBar />
          </div>
        ) : (
          collectionList.map(
            (collection, index) =>
              index < showItems && (
                <CollectionItem item={collection} key={index} />
              )
          )
        )}
      </FlexColumnContainer>
      {loading && pageNum > 1 ? (
        <div className="loading-bar my-5 ml-auto mr-auto d-flex justify-content-center">
          <LoadingBar />
        </div>
      ) : (
        showArrows && (
          <div className="my-3 d-flex justify-content-center">
            <Button
              variant="primary"
              className="btn-round w-50 outline-btn"
              onClick={() => loadMoreCollections()}
            >
              <span>Load More</span>
            </Button>
          </div>
        )
      )}
    </>
  );
};

export default Collections;
