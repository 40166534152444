/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Image,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { onboard } from "ethereum/OnBoard";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ConnectWalletBtn from "./common/button/ConnectWalletBtn";
import {
  B1NormalTextTitle,
  FlexAlignCenterDiv,
  NormalTextTitle,
  SmallTextTitleGrey,
} from "./common/common.styles";
import {
  disconnectUserWallet,
  setSearchKeyValue,
} from "store/User/user.slice";
import imgAvatar from "assets/imgs/avatar.svg";
import logoImg from "assets/imgs/maximnft_logo_white.png";

import {
  getSearchKey,
  getWalletAddress,
  getWalletBalance,
  isAuthenticated,
  getMyInfo,
} from "store/User/user.selector";
import { AiOutlineClose } from "react-icons/ai";
import Utility from 'service/utility';
import EthUtil from 'ethereum/EthUtil';
import EthereumIcon from "assets/imgs/ethereum.svg";
import BinanceIcon from "assets/imgs/binance.svg";
import PolygonIcon from "assets/imgs/polygon-matic.svg";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const history = useHistory();
  const searchKeyValue = useAppSelector(getSearchKey);
  const walletAddress = useAppSelector(getWalletAddress);
  const balance = useAppSelector(getWalletBalance);
  const isAuth = useAppSelector(isAuthenticated);
  const userInfo = useAppSelector(getMyInfo);
  const dispatch = useAppDispatch();
  const [searchKey, setSearchKeyword] = useState("");
  const location = useLocation();

  const handleChange = (e: any) => {
    setSearchKeyword(e.target.value);
  };
  const onKeyDown = (e: any) => {
    if (e.charCode === 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    dispatch(setSearchKeyValue(searchKey));
    history.push("/search");
  };

  const getDropdownAvatar = () => {
    return (
      <div className="header-avatar">
        <Image
          src={
            userInfo.avatar
              ? Utility.mediaValidation(userInfo.avatar)
              : imgAvatar
          }
        ></Image>
      </div>
    );
  };

  const getDropdownCreateNFT = () => {
    return (
      <Button className="btn-black mr-2 mr-lg-4">
        Create NFT
      </Button>
    );
  };

  const disConnect = () => {
    onboard.walletReset();
    dispatch(disconnectUserWallet());
    document.querySelector(".navbar-collapse")?.classList.remove("show");
    document.querySelector(".navbar-toggler")?.classList.add("collapsed");
  };

  useEffect(() => {
    if (location.pathname === '/search') {
      setSearchKeyword(searchKeyValue);
    } else {
      setSearchKeyword('');
    }
    if (location.pathname !== '/' && !location.pathname.includes('/tokens/')) {
      localStorage.setItem('home_filter', '');
    }
  }, [searchKeyValue, location.pathname]);

  const onDisplaySearch = () => {
    let element = document.querySelector('.layout.search');
    if (element) {
      document.getElementById('layout')?.classList.remove('search');
    } else {
      document.getElementById('layout')?.classList.add('search');
      document.querySelector(".navbar-collapse")?.classList.remove("show");
      document.querySelector(".navbar-toggler")?.classList.add("collapsed");
      onSearch();
    }
  }

  const onClearSearchKey = () => {
    setSearchKeyword('');
    dispatch(setSearchKeyValue(''));
  }

  const getPriceType = () => {
    const network = EthUtil.getNetwork();
    if (network) {
      switch (network) {
        case 1:
          return 'ETH';
        case 4:
          return 'ETH';
        case 137:
          return 'MATIC';
        case 80001:
          return 'MATIC';
        case 56:
          return 'BNB';
        case 97:
          return 'BNB';
        default:
          return 'ETH'
      }
    } else {
      return 'ETH';
    }
  }

  const getBlockImage = () => {
    const network = EthUtil.getNetwork();
    if (network) {
      switch (network) {
        case 1:
          return EthereumIcon;
        case 4:
          return EthereumIcon;
        case 137:
          return PolygonIcon;
        case 80001:
          return PolygonIcon;
        case 56:
          return BinanceIcon;
        case 97:
          return BinanceIcon;
        default:
          return EthereumIcon;
      }
    } else {
      return EthereumIcon;
    }
  }

  return (
    <div className="header-container">
      <Navbar>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span />
          <span />
          <span />
          <span />
        </Navbar.Toggle>
        <Navbar.Brand as={Link} to="/">
          <img className="logo" src={logoImg} alt="logo" />
        </Navbar.Brand>
        <FlexAlignCenterDiv className="header-search-form">
          <FaSearch className="search-icon" style={{ cursor: "pointer" }} onClick={() => onDisplaySearch()} />
          <FormControl
            type="text"
            placeholder="Search by creator, item, collection"
            className="ml-sm-2"
            value={searchKey}
            onChange={(e) => handleChange(e)}
            onKeyPress={(e?: any) => onKeyDown(e)}
          />
          {
            searchKey && (
              <AiOutlineClose className="clear-icon pointer-cursor" onClick={() => onClearSearchKey()} />
            )
          }
        </FlexAlignCenterDiv>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto align-items-center">
            <Nav.Item>
              <Nav.Link
                eventKey="1"
                as={Link}
                to="/explore"
                className="mr-lg-3"
                active={
                  location.pathname === "/explore" || location.pathname === "/"
                }
              >
                Explore
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                  as={Link}
                  to="/upcoming-drops"
                  className="mr-lg-3"
                  active={
                    location.pathname === "/upcoming-drops"
                  }
                >
                  Upcoming Drops
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <a href="https://docs.maximnft.com" target="_blank" rel="noreferrer" className="mr-lg-3 nav-link" >Guides</a>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/advertising"
                className="mr-lg-3"
                active={
                  location.pathname === "/advertising"
                }
              >
                Advertising
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/tokenization"
                className="mr-lg-3"
                active={
                  location.pathname === "/tokenization"
                }
              >
                Tokenization
              </Nav.Link>
            </Nav.Item> */}
            {walletAddress && isAuth && (
              <Nav.Item>
                <Nav.Link
                  eventKey="2"
                  as={Link}
                  to="/items"
                  className="mr-lg-3"
                  active={location.pathname === "/items"}
                >
                  My Items
                </Nav.Link>
              </Nav.Item>
            )}

            {walletAddress && isAuth && (
              <Nav.Item>
                <Nav.Link
                  eventKey="3"
                  as={Link}
                  to="/activity"
                  className="mr-lg-4"
                  active={location.pathname === "/activity"}
                >
                  Activity
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item className="d-flex buttons">
              {/* <NavDropdown
                title={getDropdownCreateNFT()}
                id="header-nav-create-dropdown"
                alignRight={false}
                onToggle={(e) =>  !e && setTooltipText(e)}
              >
                <div>
                  <NavDropdown.Item as={Link} to="/collectible">
                    Create new
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <div className="px-4 py-1 pointer-cursor grey-color d-flex align-items-center">
                    Import NFTs
                    <span className="coming-soon">SOON</span>
                    <OverlayTrigger
                      key="tooltip_title"
                      trigger={'click'}
                      placement={'top'}
                      show={tooltipShow}
                      overlay={
                        <Tooltip id={`tooltip_import_soon`}>
                          Import the NFTs that were created elsewhere
                        </Tooltip>
                      }
                      onToggle={() => setTooltipText(!tooltipShow)}
                    >
                      <span className="quiz ml-auto">?</span>
                    </OverlayTrigger>
                  </div>
                </div>
              </NavDropdown> */}
              {walletAddress && isAuth ? (
                <NavDropdown
                  title={getDropdownAvatar()}
                  id="header-nav-dropdown"
                  alignRight={true}
                  className="user-dropdown py-2"
                >
                  <div className="px-4">
                    {userInfo && userInfo.name && (
                      <B1NormalTextTitle className="mb-2 mt-1 font-matrice">
                        {userInfo.name}
                      </B1NormalTextTitle>
                    )}
                    <FlexAlignCenterDiv>
                      <Image src={getBlockImage()} width="22" />
                      <div className="ml-2">
                        <NormalTextTitle>Balance</NormalTextTitle>
                        <SmallTextTitleGrey>{balance} {getPriceType()}</SmallTextTitleGrey>
                      </div>
                    </FlexAlignCenterDiv>
                  </div>

                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/items">
                    My Items
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/profile">
                    Edit Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={disConnect}>
                    Disconnect
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <ConnectWalletBtn />
              )}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
