import engine721ABI from "ethereum/abis/ERC721/engine721ABI";
import xSigma721ABI from "ethereum/abis/ERC721/xSigma721ABI";
import { web3 } from './OnBoard';
import configs from 'configs';
import EthUtil from './EthUtil';
import Utility from 'service/utility';

class Contract721 {
    getXsigma721Address(network?: any) {
        const net = network || EthUtil.getNetwork();

        if (net) {
          switch(net) {
            case configs.ONBOARD_POLYGON_ID:
              return configs.MATIC_XSIGMA721;
            case configs.ONBOARD_MUMBAI_ID:
              return configs.MUMBAI_XSIGMA721;
            case configs.ONBOARD_NETWORK_ID:
              return configs.XSIGMA721_ADDRESS;
            case configs.ONBOARD_RINKEBY_ID:
              return configs.RINKEBY_XSIGMA721;
            case configs.ONBOARD_BSC_ID:
              return configs.BNB_XSIGMA721;
            case configs.ONBOARD_BSC_TEST_ID:
              return configs.BNB_TEST_XSIGMA721;
            default:
              return configs.XSIGMA721_ADDRESS;
          }
        }
    }
    
    getEngine721Address (network?: any) {
        const net = network || EthUtil.getNetwork();

        if (net) {
          switch(net) {
            case configs.ONBOARD_POLYGON_ID:
              return configs.MATIC_ENGINE721;
            case configs.ONBOARD_MUMBAI_ID:
              return configs.MUMBAI_ENGINE721;
            case configs.ONBOARD_NETWORK_ID:
              return configs.ENGINE721_ADDRESS;
            case configs.ONBOARD_RINKEBY_ID:
            return configs.RINKEBY_ENGINE721;
            case configs.ONBOARD_BSC_ID:
              return configs.BNB_ENGINE721;
            case configs.ONBOARD_BSC_TEST_ID:
              return configs.BNB_TEST_ENGINE721;
            default:
              return configs.ENGINE721_ADDRESS;
          }
        }
    }

    async getXsigma721Bytecode () {
        return new web3.eth.getCode(this.getXsigma721Address());
    }

    async getEngine721Bytecode () {
        return new web3.eth.getCode(this.getEngine721Address());
    }

    getXsigma721Contract(network?: any) {
        const XSIGMA_ADDRESS = this.getXsigma721Address(network);

        return new web3.eth.Contract(xSigma721ABI, XSIGMA_ADDRESS);
    }

    getEngine721Contract(network?: any) {
        const ENGINE_ADDRESS = this.getEngine721Address(network);
        
        return new web3.eth.Contract(engine721ABI, ENGINE_ADDRESS);
    }

    getXsigma721ReadContract(network?: any) {
        const XSIGMA_ADDRESS = this.getXsigma721Address(network);
        const web3Reader = EthUtil.getWeb3Reader(network);

        const abi: any = xSigma721ABI;

        return new web3Reader.eth.Contract(abi, XSIGMA_ADDRESS);
    }

    getEngine721ReadContract(network?: any) {
        const ENGINE_ADDRESS = this.getEngine721Address(network);
        
        const web3Reader = EthUtil.getWeb3Reader(network);

        const abi: any = engine721ABI;

        return new web3Reader.eth.Contract(abi, ENGINE_ADDRESS);
    }

    async getApproved(tokenId: any) {
        if (web3) {
            const xSigmaContract = this.getXsigma721Contract();
            const ENGINE_ADDRESS = this.getEngine721Address();
            try {
                const approved = await xSigmaContract.methods.getApproved(tokenId).call();
                
                if (approved.toLowerCase() !== ENGINE_ADDRESS?.toLowerCase()) {
                    return false;
                }

                return true;
            } catch (err: any) {
                Utility.downloadFile(err.message || err);
            }
        }

        return false;
    }

    async createNft(tokenURI: any , royalties: any , locked_content: string = '') {
        const network = EthUtil.getNetwork();
        const XSIGMA_721_ADDRESS = this.getXsigma721Address(network);

        if (web3) {
            const bytecode = await new web3.eth.getCode(XSIGMA_721_ADDRESS);
            const xSigmaContract = this.getXsigma721Contract();
            
            try {
                const gasEstimate: any = await xSigmaContract.methods.createItem(tokenURI, royalties, locked_content).estimateGas({
                    from: EthUtil.getAddress(),
                    data: bytecode
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                const result: any = await xSigmaContract.methods.createItem(tokenURI, royalties , locked_content).send({
                    from: EthUtil.getAddress(),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    ...gasPrice,
                    maxPriorityFeePerGas: null, 
                    maxFeePerGas: null
                });
                if(result.status === true) {
                    const { Transfer } = result.events;
                    if(Transfer && Transfer["returnValues"]) return { success: true , tokenId: Transfer["returnValues"]["tokenId"] };
                }
            }catch(err: any) {
                Utility.downloadFile(err.message || err);
                return { success: false, error: err }
            }
        }
        return { success: false, error: 'Failed to create NFT!' };
    }

    async approve(tokenId: any) {
        const ENGINE_721_ADDRESS = this.getEngine721Address();

        if (web3) {
            const bytecode = await new web3.eth.getCode(ENGINE_721_ADDRESS);
            const xSigmaContract = this.getXsigma721Contract();

            try {
                const gasEstimate: any = await xSigmaContract.methods.approve(ENGINE_721_ADDRESS, tokenId).estimateGas({
                    from: EthUtil.getAddress(),
                    data: bytecode
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                await xSigmaContract.methods.approve(ENGINE_721_ADDRESS, tokenId).send({
                    from: EthUtil.getAddress(),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null, 
                    maxFeePerGas: null,
                    ...gasPrice,
                });
                return true;
            }catch(err: any) {
                Utility.downloadFile(err.message || err);
            }
        }
        return false;
    }

    async createOffer(tokenId: any, isDirectSale: boolean , isAuction: boolean , price: any, minPrice: any, startTime: any, duration: any) {
        const XSIGMA_721_ADDRESS = this.getXsigma721Address();
        const ENGINE_721_ADDRESS = this.getEngine721Address();

        if (web3) {
            const bytecode = await new web3.eth.getCode(ENGINE_721_ADDRESS);
            const engineContract = this.getXsigma721Contract();
            try {
                const gasEstimate: any = await engineContract.methods.createOffer(XSIGMA_721_ADDRESS,tokenId,isDirectSale,isAuction,EthUtil.toWei(price),EthUtil.toWei(minPrice),startTime,duration).estimateGas({
                    from: EthUtil.getAddress(),
                    data: bytecode
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                await engineContract.methods.createOffer(XSIGMA_721_ADDRESS,tokenId,isDirectSale,isAuction,EthUtil.toWei(price),EthUtil.toWei(minPrice),startTime,duration).send({
                    from: EthUtil.getAddress(),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    ...gasPrice,
                });
                return true;
            }catch(err: any) {
                Utility.downloadFile(err.message || err);
            }
        }
        return false;
    }

    async getAuctionId(tokenId: any, network?: any) {
        if (web3) {
            const engineContract = this.getEngine721ReadContract(network);

            try {
                let auctionId = await engineContract.methods.getAuctionId(tokenId).call();
                return Number(auctionId);
            } catch (err: any) {
                Utility.downloadFile(err.message || err);
                return 0;
            }
        }
        return 0;
    }

    async bid(tokenId:any, price: any) {
        const ENGINE_721_ADDRESS = this.getEngine721Address();

        if (web3) {
            let auctionId = await this.getAuctionId(tokenId);

            if (auctionId !== null) {
                const bytecode = await new web3.eth.getCode(ENGINE_721_ADDRESS);
                const engineContract = this.getEngine721Contract();

                try {
                    const gasEstimate: any = await engineContract.methods.bid(auctionId).estimateGas({
                        data: bytecode,
                        from: EthUtil.getAddress(),
                        value: EthUtil.toWei(price)
                    });

                    const gasPrice = await EthUtil.getGasPrice() || {}        

                    const result: any = await engineContract.methods.bid(auctionId).send({
                        from: EthUtil.getAddress(),
                        value: EthUtil.toWei(price),
                        gas: gasEstimate + configs.GAS_LIMIT,
                        maxPriorityFeePerGas: null, 
                        maxFeePerGas: null,
                        ...gasPrice,
                    });

                    if (result.status === true) {
                        return { success: true, transactionHash: result.transactionHash };
                    }
                } catch(err: any) {
                    Utility.downloadFile(err.message || err);
                }
            }
        }
        return { success: false, error: 'Failed to bid to this item!' };
    }
    async directBuy(tokenId: any, price: any) {
        const ENGINE_721_ADDRESS = this.getEngine721Address();

        if (web3) {
            const engineContract = this.getEngine721Contract();
            const bytecode = await new web3.eth.getCode(ENGINE_721_ADDRESS);

            try {
                const gasEstimate: any = await engineContract.methods.buy(tokenId).estimateGas({
                    data: bytecode,
                    from: EthUtil.getAddress(),
                    value: EthUtil.toWei(price)
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                const result: any = await engineContract.methods.buy(tokenId).send({
                    from: EthUtil.getAddress(),
                    to: ENGINE_721_ADDRESS,
                    value: EthUtil.toWei(price),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null, 
                    maxFeePerGas: null,
                    ...gasPrice,
                })

                if (result.status === true) {
                    return { success: true , transactionHash: result.transactionHash };
                }
            } catch(err: any) {
                Utility.downloadFile(err.message || err);
            }
        }
        return { success: false, error: 'Failed to buy this item directly!' };
    }
}

const smartContract = new Contract721();

export default smartContract;