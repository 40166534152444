import API from 'service/api';
import configs from "configs";

class TokenController {

    public static getTokens(type?: string) {
        let url = configs.API.TOKEN_URL;
        if(type) {
            url = `${url}/${type}`;
        }
        return API.get(url).then(res => {
            if(res.data && res.data.tokens) return res.data.tokens
            return []
        });
    }
    public static getById(id: any, offerId?: any) {
        return API.get(`${configs.API.TOKEN_URL}/${id}${offerId ? `?offer=${offerId}` : ''}`).then(res => res.data);
    }
    public static getMyTokens() {
        let url = configs.API.MY_TOKEN_URL;
        return API.get(url).then(res => {
            if(res.data && res.data.tokens) return res.data.tokens
            return []
        });
    }
    public static getItems(walletAdress: String, type: String, page: number, filter?: String) {
        let url = configs.API.USERS_URL;
        return API.get(`${url}/${walletAdress}/items/${type}${page > 1 ? '?page='+page : ''}${filter ? (page > 1 ? '&' : '?')+'type='+filter : ''}`).then(res => {
            return res.data
        });
    }
    public static setLike(id: String) {
        let url = configs.API.TOKEN_URL;
        return API.get(`${url}/${id}/like`).then(res => res);
    }
    public static setUnLike(id: String) {
        let url = configs.API.TOKEN_URL;
        return API.get(`${url}/${id}/unlike`).then(res => res);
    }
}

export default TokenController;