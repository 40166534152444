import Onboard from "bnc-onboard";
import Web3 from "web3";
import configs from "../configs";
import smartContract721 from "./Contract721";
import smartContract1155 from "./Contract1155";
import ethUtil from "./EthUtil";
// set a variable to store instantiated web3
let web3: any;
declare let window: any;

const wallets = [
  { walletName: "metamask", preferred: true },
  { walletName: "coinbase", preferred: false },
  { walletName: "trust", preferred: false, rpcUrl: configs.RPC_URL },
  { walletName: "authereum" },
  {
    walletName: "trezor",
    appUrl: configs.WALLET_APP_URL,
    email: configs.CONTACT_EMAIL,
    rpcUrl: configs.RPC_URL,
  },
  {
    walletName: "ledger",
    rpcUrl: configs.RPC_URL,
  },
  {
    walletName: "lattice",
    rpcUrl: configs.RPC_URL,
    appName: configs.WALLET_APP_NAME,
  },
  {
    walletName: "cobovault",
    rpcUrl: configs.RPC_URL,
    appName: configs.WALLET_APP_NAME,
  },
  {
    walletName: "fortmatic",
    apiKey: configs.FORTMATIC_KEY,
  },
  {
    walletName: "portis",
    apiKey: configs.PORTIS_KEY,
    label: "Login with Email",
  },
  {
    walletName: "walletConnect",
    infuraKey: configs.INFURA_KEY,
  },
  { walletName: "opera" },
  { walletName: "operaTouch" },
  { walletName: "torus" },
  { walletName: "status" },
  {
    walletName: "walletLink",
    rpcUrl: configs.RPC_URL,
    appName: configs.WALLET_APP_NAME,
  },
  { walletName: "imToken", rpcUrl: configs.RPC_URL },
  { walletName: "meetone" },
  { walletName: "mykey", rpcUrl: configs.RPC_URL },
  { walletName: "huobiwallet", rpcUrl: configs.RPC_URL },
  { walletName: "hyperpay" },
  { walletName: "wallet.io", rpcUrl: configs.RPC_URL },
  { walletName: "atoken" },
  { walletName: "frame" },
  { walletName: "ownbit" },
  { walletName: "alphawallet" },
  { walletName: "gnosis" },
  { walletName: "xdefi" },
  { walletName: "bitpie" },
];

const initializationOptions = {
  dappId: configs.ONBOARD_API_KEY,
  networkId:
    window && window.ethereum && window.ethereum.networkVersion
      ? parseInt(window.ethereum.networkVersion)
      : localStorage.getItem(configs.STORAGE.SELECTED_NETWORK)
      ? parseInt(`${localStorage.getItem(configs.STORAGE.SELECTED_NETWORK)}`)
      : configs.ONBOARD_NETWORK_ID,
  darkMode: false,
  subscriptions: {
    address: (address: any) => {
      const state = onboard.getState();
      
      if (state.address && localStorage.getItem('sellectedWallet')) {
        window.location.reload()
      }
    },
    wallet: (wallet: any) => {
      // instantiate web3 when the user has selected a wallet
      web3 = new Web3(wallet.provider);

      if (web3) {
        window.contracts = {
          erc721Engine: smartContract721.getEngine721ReadContract(),
          erc721EngineRW: smartContract721.getEngine721Contract(),
          erc721: smartContract721.getXsigma721ReadContract(),
          erc721RW: smartContract721.getXsigma721Contract(),

          erc1155: smartContract1155.getXsigma1155ReadContract(),
          erc1155RW: smartContract1155.getXsigma1155Contract(),
          erc1155Engine: smartContract1155.getEngine1155ReadContract(),
          erc1155EngineRW: smartContract1155.getEngine1155Contract(),

          web3Reader: ethUtil.getWeb3Reader(),
          web3
        };
      }
    },
  },
  walletSelect: {
    wallets: wallets,
  },
};

const onboard = Onboard(initializationOptions);

export { onboard, web3 };
