/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import InputRange from "react-input-range";
import { FaCheck } from "react-icons/fa";
import {
  B1NormalTextTitle,
  FlexAlignCenterDiv,
} from "components/common/common.styles";
import Layout from "components/Layout";
import AuctionItem from "components/auction/AuctionItem";
import { BiFilterAlt } from "react-icons/bi";
import LoadingBar from "components/common/LoadingBar";
import SearchController from "controller/SearchController";
import CollectionItem from "components/collection/CollectionItem";
import UserItem from "components/user/userItem";
import NoItem from "components/common/noItem";
import "./Search.scss";
import { useAppSelector } from "store/hooks";
import { getSearchKey } from "store/User/user.selector";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import OfferController from "controller/OfferController";

interface SearchViewProps {}

const _params = {
  name: "",
  sort: "",
  price_min: 0,
  price_max: 1,
  date_start: "",
  date_end: "",
  date_from: "",
  verified: false,
  category: "",
};

const _range = { min: 0, max: 1 };

const SearchView: React.FC<SearchViewProps> = () => {
  const history: any = useHistory();
  const searchKey = useAppSelector(getSearchKey);
  const [pageNum, setPageNumber] = useState(1);
  const [pages, setPages] = useState(1);
  const [tokens, setTokens] = useState([]);
  const [collectionList, setCollections] = useState([]);
  const [userList, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryType, setCategoryType] = useState("");
  const [sectionType, setSectionType] = useState("items");
  const [sortType, setSortType] = useState("recent");
  const [params, setParams] = useState(_params);
  const [range, setRange] = useState(_range);
  const [rangeLimit, setRangeLimit] = useState(_range);
  const [isFilterSidebarShow, setIsFilterSidebarShow] = useState(false);

  const sectionTypes = [
    { value: "items", text: "Items" },
    { value: "users", text: "Users" },
    { value: "collections", text: "Collections" },
  ];
  const sortByOptions = [
    {
      value: "recent",
      text: "Recently added",
    },
    {
      value: "cheap",
      text: "Cheapest",
    },
    {
      value: "costly",
      text: "Highest price",
    },
    {
      value: "like",
      text: "Most liked",
    },
  ];
  const categoryTypes = [
    { value: "", text: "All" },
    { value: "art", text: "Art" },
    { value: "photography", text: "Photography" },
    { value: "games", text: "Games" },
    { value: "metaverses", text: "Metaverses" },
    { value: "music", text: "Music" },
    { value: "domains", text: "Domains" },
  ];

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("layout")?.classList.add("search");
    }, 500);
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        let searchLimit = await OfferController.getSearchLimit();

        if (searchLimit) {
          setRangeLimit({
            min: Number(searchLimit.min_price.toFixed(0)),
            max: Number(searchLimit.max_price.toFixed(0)),
          });

          setRange({
            min: Number(searchLimit.min_price.toFixed(0)),
            max: Number(searchLimit.max_price.toFixed(0)),
          });
        }
      } catch (err) {}
    };

    loadData();
  }, []);

  useEffect(() => {
    if (sectionType && pageNum) {
      handleSearch();
    }
  }, [sectionType, pageNum, searchKey]);

  const onSelectSort = (sType: any) => {
    params.sort = sType.value;
    setParams(params);
    setPageNumber(1);
    handleSearch();
  };

  const onChangeVerified = (value: boolean) => {
    params.verified = value;
    setParams(params);
    setPageNumber(1);
    handleSearch();
  };

  const onChangeDate = (type: string, date: any) => {
    if (type === "date_start") {
      params.date_start = date;
    } else if (type === "date_end") {
      params.date_end = date;
    }
    setParams(params);
    setPageNumber(1);
    handleSearch();
  };

  const onChangeRange = () => {
    params.price_min = range.min;
    params.price_max = range.max;
    setParams(params);
    setPageNumber(1);
    handleSearch();
  };

  const handleSearch = async () => {
    setLoading(true);
    if (sectionType === "items") {
      let param: any = {
        name: searchKey,
        sort: params.sort,
        price_min: params.price_min,
        price_max: params.price_max,
        date_start: params.date_start,
        date_end: params.date_end,
        category: params.category,
        verified: params.verified,
      };
      if (params.category === "") delete param.category;
      if (!params.verified) delete param.verified;
      if (history.location.state && history.location.state.category) {
        param.category = history.location.state.category;
        setCategoryType(history.location.state.category);
        let state = { ...history.location.state };
        delete state.category;
        history.replace({ ...history.location, state });
      }
      let { offers, pages } = await SearchController.tokenSearch(
        param,
        pageNum
      );
      if (pageNum === 1) {
        setPages(pages);
      }
      setTokens(pageNum === 1 ? offers : tokens.concat(offers));
    }
    if (sectionType === "collections") {
      let param = {
        name: searchKey,
        date_from: params.date_start,
      };
      let { collections, pages } = await SearchController.collectionSearch(
        param,
        pageNum
      );
      if (pageNum === 1) {
        setPages(pages);
      }
      setCollections(
        pageNum === 1 ? collections : collectionList.concat(collections)
      );
    }
    if (sectionType === "users") {
      let param = {
        name: searchKey,
        verified: params.verified,
      };
      let { users, pages } = await SearchController.userSearch(param, pageNum);
      if (pageNum === 1) {
        setPages(pages);
      }
      setUsers(pageNum === 1 ? users : userList.concat(users));
    }
    setLoading(false);
  };

  const onSelectSectionType = (type: string) => {
    setSectionType(type);
    setIsFilterSidebarShow(false);
    setPages(1);
    setPageNumber(1);
  };

  useEffect(() => {
    const layoutElement = document.querySelector("#layout");

    if (layoutElement) {
      layoutElement.classList.toggle("scroll-hidden", isFilterSidebarShow);
    }
  }, [isFilterSidebarShow]);

  return (
    <Layout className="search-container">
      <div className="search-view">
        <div className="d-flex mt-3">
          {sectionTypes.map((eType, index) => {
            return (
              <div
                className={`nft-type-btn mr-2 ${
                  sectionType === eType.value ? "nft-type-selected" : ""
                }`}
                key={index}
                onClick={() => {
                  onSelectSectionType(eType.value);
                }}
              >
                {eType.text}
              </div>
            );
          })}
          <div
            className="filters-view"
            onClick={() => setIsFilterSidebarShow(!isFilterSidebarShow)}
          >
            <BiFilterAlt />
          </div>
        </div>
        <div
          className="show-content d-flex mt-5"
          style={{ position: "relative" }}
        >
          <div
            className={classNames("search-view-filter pr-4", {
              show: isFilterSidebarShow,
            })}
          >
            {sectionType === "items" && (
              <B1NormalTextTitle className="mb-4 font-matrice">
                SORT BY
              </B1NormalTextTitle>
            )}
            {sectionType === "items" &&
              sortByOptions.map((sType, index) => {
                return (
                  <div
                    className="pointer-cursor mt-2"
                    key={index}
                    onClick={() => {
                      setSortType(sType.value);
                      onSelectSort(sType);
                    }}
                  >
                    {sType.text}
                    {sType.value === sortType && (
                      <FaCheck className="fa-check ml-5"></FaCheck>
                    )}
                  </div>
                );
              })}
            {sectionType !== "collections" && (
              <Fragment>
                <B1NormalTextTitle className="font-matrice mt-5">
                  OPTIONS
                </B1NormalTextTitle>
                <FlexAlignCenterDiv
                  className="mt-3"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Verified only
                  <Form.Check
                    type="switch"
                    id="is-verified"
                    className="ml-3 pointer-cursor"
                    onChange={(e) => onChangeVerified(e.target.checked)}
                    checked={params.verified}
                  />
                </FlexAlignCenterDiv>
              </Fragment>
            )}
            {sectionType === "items" && (
              <Fragment>
                <B1NormalTextTitle className="font-matrice mt-5 mb-3">
                  PRICE
                </B1NormalTextTitle>
                <div className="px-3">
                  <InputRange
                    maxValue={rangeLimit.max}
                    minValue={rangeLimit.min}
                    value={range}
                    step={1}
                    onChange={(val: any) => setRange(val)}
                    onChangeComplete={() => onChangeRange()}
                  />
                </div>
                <B1NormalTextTitle className="font-matrice mt-5 mb-2">
                  CATEGORIES
                </B1NormalTextTitle>
                <div>
                  {categoryTypes.map((cType, index) => {
                    return (
                      <div
                        className={`category-type-btn mt-2 mr-2 pointer-cursor ${
                          categoryType === cType.value
                            ? "category-type-selected"
                            : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setCategoryType(cType.value);
                          params.category = cType.value;
                          setParams(params);
                          setPageNumber(1);
                          handleSearch();
                        }}
                      >
                        {cType.text}
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            )}
            {sectionType !== "users" && (
              <Fragment>
                <B1NormalTextTitle className="mt-5 font-matrice">
                  DATE
                </B1NormalTextTitle>
                <p className="mt-3">Date Starting</p>
                <Form.Control
                  type="date"
                  onChange={(e) => onChangeDate("date_start", e.target.value)}
                />
                {sectionType === "items" && (
                  <Fragment>
                    <p className="mt-3">Date Ending</p>
                    <Form.Control
                      type="date"
                      onChange={(e) => onChangeDate("date_end", e.target.value)}
                      className="mb-4"
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
          <div
            className={classNames("search-view-btn", {
              show: isFilterSidebarShow,
            })}
            onClick={() => setIsFilterSidebarShow(false)}
          >
            Show results
          </div>
          <div className="search-content pr-sm-4 pr-md-0 pl-sm-4 pl-md-5">
            {loading && pageNum === 1 ? (
              <div className="loading-bar-center loading-bar">
                <LoadingBar />
              </div>
            ) : (
              <Row>
                {sectionType === "items" &&
                  (tokens.length > 0 ? (
                    tokens.map((token, index) => {
                      return <AuctionItem item={token} key={index} />;
                    })
                  ) : (
                    <NoItem
                      title="No items found"
                      description="Check out the featured drops!"
                      btnLink="/"
                      btnLabel="Browse marketplace"
                    />
                  ))}
                {sectionType === "collections" &&
                  (collectionList.length > 0 ? (
                    collectionList.map((collection, index) => {
                      return <CollectionItem item={collection} key={index} />;
                    })
                  ) : (
                    <NoItem
                      title="No collections found"
                      description="Check out the featured drops!"
                      btnLink="/"
                      btnLabel="Browse marketplace"
                    />
                  ))}
                {sectionType === "users" &&
                  (userList.length > 0 ? (
                    userList.map((user, index) => {
                      return <UserItem item={user} key={index} />;
                    })
                  ) : (
                    <NoItem
                      title="No users found"
                      description="Check out the featured drops!"
                      btnLink="/"
                      btnLabel="Browse marketplace"
                    />
                  ))}
              </Row>
            )}
            {loading && pageNum > 1 ? (
              <div className="my-3 d-flex justify-content-center loading-bar">
                <LoadingBar />
              </div>
            ) : (
              pages > pageNum &&
              ((tokens.length > 0 && sectionType === "items") ||
                (userList.length > 0 && sectionType === "users") ||
                (collectionList.length > 0 &&
                  sectionType === "collections")) && (
                <Row className="my-3 justify-content-center w-100">
                  <Button
                    variant="primary"
                    className="btn-round w-50 outline-btn"
                    style={{ minWidth: 200 }}
                    onClick={() => setPageNumber(pageNum + 1)}
                  >
                    <span>Load More</span>
                  </Button>
                </Row>
              )
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SearchView;
