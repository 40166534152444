/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import useState from "react-usestateref";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Button, Col, Form, Row, Image } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";

import {
  B1NormalTextTitle,
  B2NormalTextTitle,
  B3NormalTextTitle,
  NormalTextTitle,
  BigTitle,
  FlexAlignCenterDiv,
  FlexJustifyBetweenDiv,
  MBigTitle,
  SubDescription,
  NftAvatar,
} from "components/common/common.styles";
import Layout from "components/Layout";
import "./CreateCollectible.scss";
import { FaLongArrowAltLeft, FaPlus } from "react-icons/fa";
import FileUploader from "components/common/uploader/FileUploader";
import { Link } from "react-router-dom";

import ChooseCollectionItem from "components/collection/ChooseCollectionItem";
import CreateCollectionModal from "components/collection/CreateCollectionModal";
import { getMyInfo } from "store/User/user.selector";
import Utility from "service/utility";
import CollectionController from "controller/CollectionController";
import LoadingSpinner from "components/common/LoadingSpinner";
import configs from "configs";
import {
  loadMyCollections,
  connectUserWallet,
  getWalletBalance,
} from "store/User/user.slice";
import {
  getMyCollections,
  getWalletAddress,
  isAuthenticated,
} from "store/User/user.selector";
import ConnectWalletBtn from "components/common/button/ConnectWalletBtn";
import {
  getBNBUSDTCurrency,
  getETHUSDTCurrency,
  getMATICUSDTCurrency,
  getNftCategories,
  getNftServiceFee,
} from "store/Nft/nft.selector";

import EthereumIcon from "assets/imgs/ethereum.svg";
import BinanceIcon from "assets/imgs/binance.svg";
import PolygonIcon from "assets/imgs/polygon-matic.svg";
import verifyBadge from "assets/imgs/verify.png";
import { switchNetwork } from "../../store/User/user.slice";
import EthUtil from "ethereum/EthUtil";
import { EXPIRE_DATE_OPTIONS } from "constant/constant";
import CreateERC721Modal from "./CreateERC721Modal";
import ethUtil from "ethereum/EthUtil";

interface CreateERC721Props {}

const CreateERC721: React.FC<CreateERC721Props> = () => {
  const dispatch = useAppDispatch();
  const loggedInUserInfo = useAppSelector(getMyInfo);
  const collectionItems = useAppSelector(getMyCollections);
  const nftCategories = useAppSelector(getNftCategories);
  const serviceFee = useAppSelector(getNftServiceFee);
  const walletAddress = useAppSelector(getWalletAddress);
  const isAuth = useAppSelector(isAuthenticated);
  const [validated, setValidated] = useState(false);
  const [previewThumbnail, setPreview] = useState<any>(null);
  const [isNftImage, setIsNftImage] = useState(true);
  const [isInstantPrice, setIsInstantPrice] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [expiryOption, setExpiryOption] = useState("259200");
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const ethDollarPrice = useAppSelector(getETHUSDTCurrency);
  const maticDollarPrice = useAppSelector(getMATICUSDTCurrency);
  const bnbDollarPrice = useAppSelector(getBNBUSDTCurrency);

  const [collectible, setCollectible] = useState<any>({
    name: "",
    description: "",
    media: "",
    type: 721,
    preview: null,
    royalties: 0,
    min_bid_price: 0,
    categories: "",
    offer_price: 0,
    collection: null,
    is_auction: true,
    locked: "",
    offchain: true,
    duration: "259200",
  });

  const [showCollectionDialog, setShowCollectionDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [createNftDialog, setCreateNftDialog] = useState(false);

  const [network, setNetwork] = useState<any>({
    name: "Ethereum",
    value: "ETH",
    key: configs.ONBOARD_NETWORK_ID,
  });

  const categorySelected = (val: string) => {
    if (selectedCategories.includes(val)) {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== val)
      );
    } else {
      setSelectedCategories([...selectedCategories, val]);
    }
  };

  const setNftFile = (file: any) => {
    if (file) {
      setCollectible({ ...collectible, media: file });
      if (Utility.isFileImage(file)) {
        setIsNftImage(true);
      } else {
        setIsNftImage(false);
      }
    } else {
      setIsNftImage(true);
      setCollectible({ ...collectible, media: "" });
    }
  };

  const instantReceiveAmount = () => {
    if (serviceFee) {
      let remainPer = 100 - serviceFee;
      return (collectible.offer_price * remainPer) / 100;
    }
    return collectible.offer_price;
  };

  const createCollection = async (collection: any) => {
    setIsLoading(true);
    
    let formData = Utility.getFormDataFromObject({
      ...collection,
      blockchain: network.value,
    });

    try {
      await CollectionController.create(formData);
      loadCollections();
      setIsLoading(false);
      setShowCollectionDialog(false);
      NotificationManager.success(
        "Collection is created successfully.",
        "Success"
      );
    } catch (error) {
      setIsLoading(false);
      NotificationManager.error("Create Collection Failed!", "Error");
      EthUtil.catchError(error);
    }
  };

  const handleChange = (e: any) => {
    let fieldName = e.target.name;
    if (
      fieldName === "offer_price" ||
      fieldName === "min_bid_price" ||
      fieldName === "royalties"
    ) {
      const regex = /^[0-9]\d*(?:[.]\d*)?$/;
      if (e.target.value !== "" && !regex.test(e.target.value)) {
        e.preventDefault();
        return;
      }
    }
    if (fieldName === "royalties" && e.target.value > 50) {
      e.preventDefault();
      return;
    }
    if (e.target.type === "checkbox") {
      let fieldVal = e.target.checked;
      setCollectible({ ...collectible, [fieldName]: fieldVal });
    } else {
      let fieldVal = e.target.value;
      if (fieldVal.length > 1 && fieldVal[0] === "0" && fieldVal[1] !== ".") {
        fieldVal = fieldVal.substring(1);
      }
      setCollectible({ ...collectible, [fieldName]: fieldVal });
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!walletAddress) {
      dispatch(connectUserWallet());
      return;
    }
    dispatch(getWalletBalance());
    setValidated(true);
    const form = e.currentTarget;
    const validFields = document.querySelectorAll(
      ".was-validated :invalid ~ .invalid-feedback"
    );
    if (validFields.length > 0) {
      setTimeout(function () {
        // @ts-ignore
        document.getElementById("layout").scrollTo({
          // @ts-ignore
          top:
            // @ts-ignore
            document.getElementById("layout")?.clientHeight +
            validFields[0].getBoundingClientRect().top,
          behavior: "smooth",
        });
      }, 200);
    }
    if (form.checkValidity() !== false) {
      if (!collectible.media) {
        setTimeout(function () {
          // @ts-ignore
          document.getElementById("layout").scrollTo({
            // @ts-ignore
            top: 0,
            behavior: "smooth",
          });
        }, 200);
        NotificationManager.error("No Media File!", "Error");
        return;
      }
      if (collectible.media && collectible.media.size > 30000000) {
        setTimeout(function () {
          // @ts-ignore
          document.getElementById("layout").scrollTo({
            // @ts-ignore
            top: 0,
            behavior: "smooth",
          });
        }, 200);
        NotificationManager.error("Please upload media below 30MB!", "Error");
        return;
      }
      if (!isNftImage && !previewThumbnail) {
        setTimeout(function () {
          // @ts-ignore
          document.getElementById("layout").scrollTo({
            // @ts-ignore
            top: 0,
            behavior: "smooth",
          });
        }, 200);
        NotificationManager.error("Please upload cover image!", "Error");
        return;
      }
      if (
        !isNftImage &&
        previewThumbnail &&
        collectible.preview &&
        collectible.preview.size > 30000000
      ) {
        setTimeout(function () {
          // @ts-ignore
          document.getElementById("layout").scrollTo({
            // @ts-ignore
            top: 0,
            behavior: "smooth",
          });
        }, 200);
        NotificationManager.error(
          "Please upload cover image below 30MB!",
          "Error"
        );
        return;
      }

      if (!selectedCategories.length) {
        NotificationManager.error(
          "Please select at least one category!",
          "Error"
        );
        return;
      }

      const networkID = EthUtil.getNetwork();

      if (networkID !== network.key) {
        switchNetwork(network.key);
        return;
      }

      setCreateNftDialog(true);
    }
  };

  const loadCollections = () => {
    dispatch(loadMyCollections());
  };

  useEffect(() => {
    setCollectible({ ...collectible, duration: parseFloat(expiryOption) });
  }, [expiryOption]);

  useEffect(() => {
    loadCollections();
  }, []);

  useEffect(() => {
    setCollectible({
      ...collectible,
      categories: selectedCategories.join("|"),
    });
  }, [selectedCategories]);

  const changeBlockchain = (net: any) => {
    setNetwork(net);
  };

  return (
    <Layout className="create-collectible-container">
      <Link to="/collectible">
        <FlexAlignCenterDiv className="pointer-cursor back-link">
          <FaLongArrowAltLeft />
          <B2NormalTextTitle className="ml-2">
            MANAGE COLLECTIBLE TYPE
          </B2NormalTextTitle>
        </FlexAlignCenterDiv>
      </Link>
      {isSucceed ? (
        <Row>
          <Col>
            <MBigTitle className="mt-5 faint-color">
              {" "}
              Congratulations! NFT is successfully created!{" "}
            </MBigTitle>
            <FlexAlignCenterDiv className="mt-5">
              <Link to="/items">
                <Button className="default-btn-size mr-4 outline-btn">
                  <span>My Items</span>
                </Button>
              </Link>
              <Link to="/explore">
                <Button className="default-btn-size outline-btn">
                  <span>Explore</span>
                </Button>
              </Link>
            </FlexAlignCenterDiv>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <MBigTitle className="mt-5 faint-color">
              Create single collectible
            </MBigTitle>
            <BigTitle className="mt-4 mb-3">Blockchain</BigTitle>
            <div className="blockchain-list">
              <div
                className={
                  network.name === "Ethereum"
                    ? "blockchain-item selected"
                    : "blockchain-item"
                }
                onClick={() => {
                  changeBlockchain({
                    name: "Ethereum",
                    value: "ETH",
                    key: configs.ONBOARD_NETWORK_ID,
                  });
                  setCollectible({ ...collectible, collection: null });
                }}
              >
                <div className="blockchain-icon">
                  <Image src={EthereumIcon} />
                </div>
                <div className="blockchain-name">Ethereum</div>
              </div>
              <div
                className={
                  network.name === "Polygon"
                    ? "blockchain-item selected"
                    : "blockchain-item"
                }
                onClick={() => {
                  changeBlockchain({
                    name: "Polygon",
                    value: "MATIC",
                    key: configs.ONBOARD_POLYGON_ID,
                  });
                  setCollectible({ ...collectible, collection: null });
                }}
              >
                <div className="blockchain-icon">
                  <Image src={PolygonIcon} />
                </div>
                <div className="blockchain-name">Polygon (MATIC)</div>
              </div>
              <div
                className={
                  network.name === "BSC"
                    ? "blockchain-item selected"
                    : "blockchain-item"
                }
                onClick={() => {
                  changeBlockchain({
                    name: "BSC",
                    value: "BNB",
                    key: configs.ONBOARD_BSC_ID,
                  });
                  setCollectible({ ...collectible, collection: null });
                }}
              >
                {/* <span>SOON</span> */}
                <div className="blockchain-icon">
                  <Image src={BinanceIcon} />
                </div>
                <div className="blockchain-name">Binance Smart Chain</div>
              </div>
            </div>
            <Form noValidate validated={validated} onSubmit={submitForm}>
              <Row className="mt-5 row-reserve">
                <Col xl="5" lg="6">
                  <BigTitle className="mb-4">
                    Upload file{" "}
                    <span className="text-danger font-size-base">&#8727;</span>
                  </BigTitle>
                  <FileUploader
                    title="PNG, JPEG, GIF, WEBP, MP4 or MP3. Max 30MB."
                    accept={configs.NFT_FILE_ACCEPT}
                    setFile={(e: any) => setNftFile(e)}
                    setPreview={(e: any) => setPreview(e)}
                  ></FileUploader>
                  {collectible &&
                    collectible.media &&
                    collectible.media.size > 30000000 && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        Please upload below 30MB.
                      </Form.Control.Feedback>
                    )}
                  {!isNftImage && (
                    <>
                      <BigTitle className="mt-4 mb-4">
                        Upload cover{" "}
                        <span className="text-danger font-size-base">
                          &#8727;
                        </span>
                      </BigTitle>
                      <FileUploader
                        title="PNG, GIF. Max 30MB."
                        accept={configs.IMG_FILE_ACCEPT}
                        setFile={(e: any) =>
                          setCollectible({ ...collectible, preview: e })
                        }
                        setPreview={(e: any) => setPreview(e)}
                      ></FileUploader>
                    </>
                  )}
                  {collectible &&
                    collectible.preview &&
                    collectible.preview.size > 30000000 && (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        Please upload below 30MB.
                      </Form.Control.Feedback>
                    )}
                  <FlexJustifyBetweenDiv className="mt-4">
                    <BigTitle>Enable Auction</BigTitle>
                    <Form.Check
                      type="switch"
                      id="put-up-sale-switch"
                      checked={collectible.is_auction}
                      name="is_auction"
                      onChange={(e) => handleChange(e)}
                    />
                  </FlexJustifyBetweenDiv>
                  <B2NormalTextTitle className="grey-color mt-2">
                    You will receive bids for this item.
                  </B2NormalTextTitle>
                  {collectible.is_auction && (
                    <div>
                      <B1NormalTextTitle className="mt-3">
                        Minimum bid price{" "}
                        <span className="text-danger">&#8727;</span>
                      </B1NormalTextTitle>
                      <Form.Row className="mt-1">
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            required
                            type="text"
                            placeholder="Enter Minimum Bid"
                            name="min_bid_price"
                            value={collectible.min_bid_price || ""}
                            onChange={(e) => handleChange(e)}
                            pattern="^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$"
                            maxLength={10}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please input valid minimum bid price.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <B1NormalTextTitle>
                        Expiry Date <span className="text-danger">&#8727;</span>
                      </B1NormalTextTitle>
                      <Form.Row className="mt-1">
                        <Form.Group as={Col} md="6">
                          <Form.Control
                            as="select"
                            value={expiryOption}
                            onChange={(e) => {
                              setExpiryOption(e.target.value);
                            }}
                          >
                            {EXPIRE_DATE_OPTIONS.map(
                              (eOpt: any, index: any) => {
                                return (
                                  <option value={eOpt.value} key={index}>
                                    {eOpt.label}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                    </div>
                  )}
                  <FlexJustifyBetweenDiv className="mt-4">
                    <BigTitle>Instant Sell Price</BigTitle>
                    <Form.Check
                      type="switch"
                      id="instant-sell-price-switch"
                      checked={isInstantPrice}
                      onChange={(e) => {
                        setIsInstantPrice(e.target.checked);
                        collectible.offer_price = 0;
                      }}
                    />
                  </FlexJustifyBetweenDiv>
                  <B2NormalTextTitle className="grey-color mt-2 mb-5">
                    Enter a price to allow users to instantly purchase your NFT.
                  </B2NormalTextTitle>
                  {isInstantPrice && (
                    <Form.Row className="mt-4">
                      <Form.Group as={Col} md="12">
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter price for one piece"
                          name="offer_price"
                          value={collectible.offer_price || ""}
                          pattern="^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$"
                          maxLength={10}
                          onChange={(e) => handleChange(e)}
                        />
                        <B2NormalTextTitle className="mt-3">
                          <span className="o-5">Service Fee</span>&nbsp;&nbsp;
                          {serviceFee} %
                        </B2NormalTextTitle>
                        <B2NormalTextTitle className="mt-2">
                          <span className="o-5">You will receive</span>
                          &nbsp;&nbsp;
                          {instantReceiveAmount()
                            ? `${instantReceiveAmount()} ${network.value}`
                            : ""}
                        </B2NormalTextTitle>
                      </Form.Group>
                    </Form.Row>
                  )}
                  <FlexJustifyBetweenDiv className="mt-4">
                    <BigTitle>Unlock once purchased</BigTitle>
                    <Form.Check
                      type="switch"
                      id="unlock-once-purchased-switch"
                      checked={isLocked}
                      onChange={(e) => setIsLocked(e.target.checked)}
                    />
                  </FlexJustifyBetweenDiv>
                  <B2NormalTextTitle className="grey-color mt-2 mb-5">
                    Content will be unlocked after successful transaction
                  </B2NormalTextTitle>
                  {isLocked && (
                    <Form.Row className="mt-4">
                      <Form.Group as={Col} md="12">
                        <Form.Control
                          required
                          type="text"
                          placeholder="Digital key, code to redeem or link to a file..."
                          name="locked"
                          onChange={(e) => handleChange(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please input valid price.
                        </Form.Control.Feedback>
                        <B2NormalTextTitle className="grey-color mt-3">
                          Tip: Markdown syntax is supported
                        </B2NormalTextTitle>
                      </Form.Group>
                    </Form.Row>
                  )}
                </Col>
                <Col xl="7" lg="6" className="preview-area">
                  <BigTitle>PREVIEW</BigTitle>
                  <div className="auction-item p-4">
                    {loggedInUserInfo && (
                      <div className="auction-item__owner-wrapper">
                        <NftAvatar
                          className="auction-owner-avatar"
                          imagePath={Utility.getLoggedInUserAvatar(
                            loggedInUserInfo
                          )}
                        >
                          {loggedInUserInfo.verified && (
                            <Image
                              style={{ width: 12, height: 12 }}
                              src={verifyBadge}
                            />
                          )}
                        </NftAvatar>

                        <span className="auction-item__owner">
                          BY <span>{loggedInUserInfo?.name || "NAME"}</span>
                        </span>
                      </div>
                    )}
                    <div className="token-img-area">
                      <div className="pre-token-img">
                        {previewThumbnail ? (
                          <img src={previewThumbnail} alt="tokenImage" />
                        ) : (
                          <div className="no-thumbnail"></div>
                        )}
                      </div>
                    </div>
                    <div className="token-details-area">
                      <B1NormalTextTitle className="mt-3">
                        {collectible.name}
                      </B1NormalTextTitle>
                      <SubDescription className="mt-2"></SubDescription>
                      <FlexAlignCenterDiv className="mt-2">
                        {collectible.is_auction ? (
                          <div className="auction-item__btn">
                            <span className="auction-item__btn-text">Bid</span>
                            <span className="auction-item__btn-price">
                              ~{" "}
                              {ethUtil.getDollarPrice(
                                collectible.min_bid_price,
                                ethUtil.getChainIDByNetworkName(network.value),
                                {
                                  ethDollarPrice,
                                  maticDollarPrice,
                                  bnbDollarPrice,
                                }
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className="auction-item__btn">
                            <span className="auction-item__btn-text">
                              Buy Now
                            </span>
                            <span className="auction-item__btn-price">
                              {ethUtil.getDollarPrice(
                                collectible.offer_price,
                                ethUtil.getChainIDByNetworkName(network.value),
                                {
                                  ethDollarPrice,
                                  maticDollarPrice,
                                  bnbDollarPrice,
                                }
                              )}
                            </span>
                          </div>
                        )}
                      </FlexAlignCenterDiv>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BigTitle className="mt-4">Choose collection</BigTitle>
                  <div className="collection-types d-flex mt-4">
                    <div
                      className="choose-collection-item p-4 mr-4 text-center"
                      onClick={() => {
                        setShowCollectionDialog(true);
                      }}
                    >
                      <div className="c-nft-avatar add-collection-btn">
                        <FaPlus></FaPlus>
                      </div>
                      <B3NormalTextTitle className="faint-color mt-3">
                        Create
                      </B3NormalTextTitle>
                      <B2NormalTextTitle className="mt-3">
                        ERC - 721
                      </B2NormalTextTitle>
                    </div>
                    {collectionItems
                      .filter((collection: any) => {
                        return (
                          collection.blockchain === network.value ||
                          collection.name === "xSigma"
                        );
                      })
                      .map((cItem: any, index: number) => {
                        return (
                          <ChooseCollectionItem
                            item={cItem}
                            key={index}
                            onSelected={() => {
                              setCollectible({
                                ...collectible,
                                collection: cItem._id,
                              });
                            }}
                            isSelected={collectible.collection === cItem._id}
                          />
                        );
                      })}
                  </div>
                  <BigTitle className="mt-4">
                    Choose categories{" "}
                    <span className="text-danger font-size-base">&#8727;</span>
                  </BigTitle>
                  <FlexAlignCenterDiv className="category-list mt-4">
                    {nftCategories.map((eType, index) => {
                      return (
                        eType.value !== "all" && (
                          <div
                            className={classNames("nft-type-btn mr-4", {
                              "nft-type-selected": selectedCategories.includes(
                                eType.value
                              ),
                            })}
                            key={index}
                            onClick={() => {
                              categorySelected(eType.value);
                            }}
                          >
                            {eType.label}
                          </div>
                        )
                      );
                    })}
                  </FlexAlignCenterDiv>
                  <Row>
                    <Col xl="5" lg="6">
                      <Form.Row className="mt-4">
                        <Form.Group as={Col} md="6">
                          <Form.Label>
                            <BigTitle>
                              Name <span className="text-danger">&#8727;</span>
                            </BigTitle>
                          </Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={collectible.name}
                            onChange={(e) => handleChange(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please input valid name
                          </Form.Control.Feedback>
                          <B1NormalTextTitle className="mt-2 grey-color">
                            Example: Grand Ape Eleven
                          </B1NormalTextTitle>
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                          <Form.Label>
                            <BigTitle>Royalties</BigTitle>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Royalties"
                            name="royalties"
                            value={collectible.royalties || ""}
                            min="0"
                            max="50"
                            maxLength={2}
                            pattern="^\d+$"
                            onChange={(e) => handleChange(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Royalties should be in range from 0% - 50%.
                          </Form.Control.Feedback>
                          <B1NormalTextTitle className="mt-2 grey-color">
                            Suggested: 1%, 2.5%, 5%
                          </B1NormalTextTitle>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row className="mt-4">
                        <Form.Group as={Col} md="12">
                          <Form.Label>
                            <BigTitle>Description</BigTitle>
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Description"
                            name="description"
                            value={collectible.description}
                            rows={1}
                            onChange={(e) => handleChange(e)}
                            style={{ paddingTop: 14 }}
                          />
                          {/* <B1NormalTextTitle className="mt-2 grey-color">
                            With preserved line-breaks
                          </B1NormalTextTitle> */}
                        </Form.Group>
                      </Form.Row>
                      <FlexAlignCenterDiv className="mt-4">
                        {!isAuth && (
                          <div className="mr-4">
                            <ConnectWalletBtn />
                          </div>
                        )}
                        <Button
                          className="default-btn-size outline-btn"
                          type="submit"
                        >
                          <span>Create NFT</span>
                        </Button>
                      </FlexAlignCenterDiv>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>

            <CreateCollectionModal
              show={showCollectionDialog}
              handleClose={() => {
                setShowCollectionDialog(false);
              }}
              createCollection={(e: any) => {
                createCollection(e);
              }}
            ></CreateCollectionModal>

            {isLoading && <LoadingSpinner></LoadingSpinner>}

            <CreateERC721Modal
              show={createNftDialog}
              network={network}
              collectible={collectible}
              expiryOption={expiryOption}
              onClose={() => setCreateNftDialog(false)}
              onSuccess={() => setIsSucceed(true)}
            ></CreateERC721Modal>
          </Col>
        </Row>
      )}
    </Layout>
  );
};

export default CreateERC721;
