export const TermsContent = {
  data: `<p>
  <strong>xSigma NFT Marketplace Terms of Use</strong>
</p>
<p>
  <strong>Latest Update: October 7, 2021</strong>
</p>
<p>
  Thank you for visiting _______________________ (the “<strong>Site</strong>
  ”). These terms of use (this “<strong>Agreement</strong>”) are an important
legally binding agreement between you (“<strong>you</strong>”, “    <strong>your</strong>” or “<strong>User</strong>”) and xSigma Corporation,
  a British Virgin Islands corporation, having its registered address at OMC
Chambers, Wickhams Cay 1, Road Town, Tortola, British Virgin Islands (“<strong>xSigma</strong>”, “<strong>we</strong>”, “<strong>us</strong>” or “    <strong>our</strong>”).
</p>
<p>
  Please see the glossary at the end of this Agreement for definitions of
  capitalized terms.
</p>
<p>
  <em>Marketplace - Public Ledger Ethereum Contract Auctions</em>
</p>
<p>
  <em></em>
  <br/>
</p>
<p>
  The xSigma has published a an open source, decentralized smart contract on
the Ethereum blockchain that enables buyers (each, a “    <strong>Buyer</strong>”) and sellers (each, a “<strong>Seller</strong>”) to
  engage directly with each other for the purchase and sale of non-fungible
  tokens (“<strong>NFT</strong>s”) (the “<strong>Marketplace</strong>”). Like
  any Ethereum protocol, the Marketplace is accessible via the Ethereum
  blockchain and your own Ethereum Wallet. xSigma does not control the
  day-to-day operation of the Marketplace, as it is decentralized and
  automated. The Marketplace contract is public and available for review on
  the Ethereum blockchain prior to any Transaction. For greater certainty, an
  NFT consists of two elements: (i) a unique non-fungible token on the
  Ethereum blockchain; and (ii) a license to an image, video or other
  artwork.
</p>
<p>
  <em>Portal – Simply a user-friendly information portal</em>
</p>
<p>
  <em></em>
  <br/>
</p>
<p>
  The Site is different from the Marketplace. The Site provides an interface
  by which you can access information on the Ethereum blockchain concerning
  your own Transactions, it also facilitates your approval of your own
  Transactions on the Ethereum blockchain using an Approved Wallet (each such
  interaction, being a “<strong>Transaction</strong>” and our portal on the
  Site for assisting in such Transactions being, the “<strong>Portal</strong>
  ”). We do not control the Ethereum blockchain nor any of your Transactions,
  Digital Assets or Wallets. At all times you hold and retain exclusive
  control over your Transactions, Digital Assets and Wallets; we control none
  of them. The services provided by the Site are referred to in this
  Agreement as the “<strong>Services</strong>”.
</p>
<p>
  <em>xSigma Portal Services – Not a wallet, not an exchange.</em>
</p>
<p>
  <em></em>
  <br/>
</p>
<p>
  The Services are not those of a cryptocurrency Wallet or exchange, hosted
  or otherwise. By using the Portal, you are choosing to use the Ethereum
  blockchain - which you are doing of your own free will. There are other
  ways to engage with the Marketplace or other contracts on the Ethereum
  blockchain, other than by using Portal. You are entirely responsible for
  the Transactions that you choose to submit for execution through the Portal
  and your Approved Wallet and the risks associated with your use of the
  Ethereum blockchain (please see the “Risks” section below for additional
  information).
</p>
<p>
  WE WILL NEVER ASK FOR YOUR WALLET PRIVATE KEYS OR OTHER WALLET-RELATED
  CODES.
</p>
<p>
  This Agreement includes the provisions set out above and below, the xSigma
  Privacy Policy [LINK TO PRIVACY POLICY] and such other disclosure as may
  appear on the Site.
</p>
<p>
  BY CLICKING ‘I AGREE’ OR ‘ACCEPT’, USING AN APPROVED WALLET IN CONJUNCTION
  WITH THE SERVICES, OR OTHERWISE UTILIZING THE SERVICES, YOU ARE AGREEING TO
  BE LEGALLY BOUND BY THIS AGREEMENT, SO PLEASE READ IT CAREFULLY PRIOR TO
  DOING SO.
</p>
<p>
  xSigma reserves the right, at its sole discretion, to modify or replace
  this Agreement at any time by posting a new version of this Agreement to
  the Site. Your continued use of the Services for thirty (30) days following
  a posting of a new version hereof to the Site is deemed to be your
  acceptance of the new version. If, however, we amend this Agreement in
  order to comply with Applicable Laws, then the amendment shall take effect
  immediately on being posted to the Site. The most current version of this
  Agreement will be posted on the Site; please review this Agreement from
  time to time. You are responsible for reviewing and becoming familiar with
  any such modifications. If you do not agree to the modification of this
  Agreement, you must cease using the Services.
</p>
<ul>
  <li>
      Users and Accounts
  </li>
</ul>
<p>
  A User is prohibited from accessing or using the Services unless the User:
  (i) has accepted this Agreement; (ii) is located and domiciled in an
  Approved Region; (iii) is eighteen (18) years of age or older; (iv) is not
  a Prohibited User (as defined below); and (v) is not otherwise prohibited
  from using the Services.
</p>
<p>
  Users may not access the Site or Services through automated or nonhuman
  means, whether through a bot, script, or otherwise.
</p>
<p>
  You represent that you are legally permitted to use the Services in your
  jurisdiction, including owning Digital Assets, including but not limited to
  cryptographic tokens of value and interacting with the Services or the Site
  in any way. You represent that you are responsible for ensuring compliance
  with the Applicable Laws of your jurisdiction and acknowledge that xSigma
  is not liable for your compliance with such Applicable Laws.
</p>
<p>
  The Account is not created, hosted or administered by us. Instead, it
  represents only a selection of your own Transactions on the Ethereum
  blockchain that relate to the Marketplace, over which we have no control.
  Using your own Ethereum Wallet, you can access the Account without the
  Services. Unless otherwise indicated on the Site, none of the Site,
  Services or Portal have any login other than that which occurs by your
  logging into your Approved Wallet. xSigma may, but is not obligated to,
  offer or require enhanced security features for the Services. For any
  enhanced security features, User shall ensure the security of User’s Device
  or account (e.g. computer or phone) is associated with the enhanced
  security features. Any mention of, or linking to, third parties by xSigma
  that provide such security features is offered as a convenience and shall
  not be considered an endorsement or requirement of xSigma to use such 2FA
  Provider.
</p>
<p>
  Subject to operation of both the Ethereum blockchain and your Approved
  Wallet, a User can access the Services to retrieve and send data concerning
  their Transactions via the Portal. A User has exclusive responsibility for
  use of their Account and shall not disclose their private keys or permit
  any third party to use their Account or Approved Wallet. Users are
  exclusively responsible for those selections, even if they contain errors
  on the part of the User or cause losses to the User. Users are exclusively
  responsible for all Transactions in their Accounts. xSigma cannot itself
  nor can it enable any third party to access your Account or your Approved
  Wallet as they are each controlled exclusively by you.
</p>
<p>
  The Services are accessible by simply browsing to the Site and logging in
  to your own third-party Approved Wallet.
</p>
<p>
  User shall immediately notify xSigma if User suspects that the Portal is
  not operational or any errors as to content or operations.
</p>
<ul>
  <li>
      Prohibited Users
  </li>
</ul>
<p>
  Services are not offered to absolutely everyone.
</p>
<p>
The following Persons are prohibited from using the Services (each, a “    <strong>Prohibited User</strong>”): (i) Persons who appear on the United
  States Department of the Treasury, Office of Foreign Assets Control (OFAC),
  Specially Designated Nationals List (SDN), United States Commerce
  Department’s Denied Persons or other similar lists; (ii) Persons who are
  nationals or residents of Cuba, Iran, North Korea, Sudan, Syria or any
  other country, territory, or other jurisdiction that is embargoed by the
British Virgin Islands, the United States or listed at    <strong>Exhibit “A”</strong> of this Agreement; (iii) Persons who are less
  than 18 years of age; (iv) Persons who are not residents of and located in
  an Approved Region; and (v) Persons, or their Affiliates, who have been
  subject to an Account termination for cause. xSigma reserves the right to
  add or remove Approved Regions at its sole discretion.
</p>
<p>
  The Services are not available in all jurisdictions. xSigma prohibits the
  use of the Services in certain jurisdictions, which jurisdictions may vary
  at xSigma’s sole discretion. xSigma may implement controls to restrict
  access to the Services from any jurisdiction from which use of the Services
  is prohibited. You shall comply with such restrictions, even if the methods
  imposed by xSigma to prevent the use of the Services are not communicated
  to you or are not effective or can be bypassed.
</p>
<p>
  You are prohibited from accessing or using the Services in, from or through
  a location where your use of the Services would violate any Applicable Law.
  You represent and warrant that you are not a citizen or resident of any
  such jurisdiction, nor will you use the Services while located in any such
  jurisdiction.
</p>
<ul>
  <li>
      Prohibited Activities
  </li>
</ul>
<p>
  Services cannot be used for absolutely any purpose.
</p>
<p>
  User shall use the Services only for good faith Transactions for its own
  purposes and not on behalf of third parties. User shall not use the
  Services to, directly or indirectly, assist in any activity that is illegal
  in the British Virgin Islands, the U.S. or the jurisdiction of User, or use
  the Services in relation to any other Prohibited Activity (all of which are
  listed below in the Glossary). User shall use the Services only for their
  own Account and their own Digital Assets and shall not use the Services to
  transact with the Account or Digital Assets of any third party. xSigma
  reserves the right to add or remove Prohibited Activities at its sole
  discretion.
</p>
<p>
  If xSigma determines in its sole discretion that a User has engaged in any
  prohibited use, xSigma may address such prohibited use through an
  appropriate sanction, in its sole and absolute discretion. Such sanction
  may include, but is not limited to, User termination as discussed in the
  Term and Termination section below.
</p>
<p>
  You are prohibited from: (i) carrying out a Marketplace Transaction and
  accepting payment other than through the Marketplace; (ii) accepting
  payment for an NFT that is sold on the Marketplace via a third party; (iii)
  using your Ethereum Wallet to custody or trade in Digital Assets of another
  person; (iv) making any false or misleading representations concerning an
  NFT or any Transaction; or (v) attempting to use the Marketplace for and
  purpose other than that for which it is intended.
</p>
<p>
  You agree not to access the Marketplace, whether with the Portal or
  otherwise, in a manner that is inconsistent with the terms hereof.
</p>
<ul>
  <li>
      Risks – Ethereum, NFTs and Marketplace
  </li>
</ul>
<p>
  By using the Services, you are accessing or using the Ethereum blockchain
  and the Marketplace specifically.
</p>
<p>
  You acknowledge and agree that there are inherent risks associated with
  Digital Assets (e.g. NFTs), and the Ethereum blockchain more generally and
  that such risks extend to your use of the Services. These risks include,
  but are not limited to, the failure of hardware, software or internet
  connections, the risk of malicious software introduction and the risk of
  unauthorized access to your Account, Wallets or Digital Assets. It is your
  responsibility to familiarize yourself with the risks involved with the
  Ethereum blockchain and the Marketplace. Use of the Services does not
  remove these risks. xSigma is not responsible for any communication
  failures, disruptions, errors, distortions or delays when using the
  Services, however they may be caused.
</p>
<p>
  XSIGMA STATES THAT NO NFT HAS ANY SPECIFIC INTRINSIC VALUE.
</p>
<p>
  You acknowledge that we make no representation whatsoever as to the value,
  present or future, or any Digital Asset nor the operation of the
  Marketplace. Transactions can take time to be confirmed and may, in some
  cases, not be completed. For example, if you initiate a Transaction to
  delegate Digital Assets to a third party and you provide an incorrect or
  incomplete address, the Digital Assets in question may be lost and may not
  be recoverable. If you attempt to send or receive Digital Assets that are
  not supported by your Account or your Wallet, the Digital Assets may be
  lost entirely and may not be recoverable. In no event will xSigma be liable
  to you or to any third party for any of the foregoing.
</p>
<p>
  You agree that it is your responsibility to independently ascertain the
  value of your Digital Assets, without relying on the Services. xSigma is
  not, itself, offering to purchase or sell any Digital Assets from you or to
  you under this Agreement.
</p>
<ul>
  <li>
      Services
  </li>
</ul>
<p>
  The Services include the right, subject to the terms of this Agreement, to:
</p>
<ul>
  <li>
      Access the Site to use the Services;
  </li>
  <li>
      Send and receive User Data to and from Third Party Servicers, such as,
      for example, Approved Wallet Providers; and
  </li>
  <li>
      Send and receive User Data to and from the Ethereum blockchain, in so
      far as it is operational.
  </li>
</ul>
<ul>
  <li>
      Transactions
  </li>
</ul>
<p>
  The Services allow Users to send and receive User Data in support of a
  variety of Transactions (e.g. the purchase of an NFT from another user with
  Ethereum). Transactions are subject to the following rules and disclaimers,
  without limitation:
</p>
<ul>
  <li>
      xSigma makes no representations as to the Services being able to
      complete a given Transaction, notably because it is not capable of
      performing any Transaction. Transactions are completed by you in your
      Account using your Approved Wallet;
  </li>
  <li>
      xSigma shall have no liability if a Transaction is delayed or fails on
      account of errors, lack of processing capacity, or other failure of any
      third party;
  </li>
  <li>
      xSigma does not control your Wallet and shall have no liability to User
      for their failures, fees and other risks associated with User’s use of
      the same;
  </li>
  <li>
      Without prior notice or consent of User, xSigma reserves the right to
      impose, in its sole discretion, User, geographic, quantum, velocity,
      kind and other limits on User Data concerning certain Transactions in
      order to protect User, Other Users, xSigma and its suppliers from
      legal, fraud, security or other risks;
  </li>
  <li>
      xSigma is not able to reverse any Transaction. xSigma bears no
      responsibility or liability for Transactions initiated by User that
      contain incorrect, erroneous or improperly formatted User Data or are
      intended for a different type or destination;
  </li>
  <li>
      User has the exclusive responsibility to ensure that their Transactions
      are carried out: (i) in compliance with the requirements set out herein
      and in the Account; (ii) in compliance with any applicable Third Party
      Servicer requirements; (iii) using addresses and information that are
      true, correct, and free of error; and (iv) in compliance with any other
      applicable Third Party Servicer Agreement; and
  </li>
  <li>
      xSigma reserves the right, in its sole discretion, to prohibit User
      Data transfers concerning any Transaction in order to reduce the risk
      of fraud, security breaches, or other risks and also to comply with
      Applicable Law.
  </li>
</ul>
<p>
  As for terms of sale of NFTs between Buyer and Seller, they each agree that
  such terms are on a as-is, where-is basis, without representations or
  warranties and without a right of refund, return or exchange. Each Buyer
  and Seller is a third party beneficiary under this Agreement entitled to
  enforce the terms hereof against an Other User provided that xSigma
  liability shall remain limited as per the terms hereof.
</p>
<p>
  Failure by User to adhere to the foregoing rules may result in the
  permanent loss of their Digital Assets , for which xSigma shall have no
  liability.
</p>
<ul>
  <li>
      Commissions
  </li>
</ul>
<p>
  The Marketplace operates automatically to (i) receive the Buyer’s Ethereum;
  (ii) pay the purchase price of an NFT to the Seller’s Ethereum Wallet;
  (iii) transfer the Seller’s NFT to the Buyer’s Ethereum Wallet; and (iv)
  settle commissions for the Transaction to xSigma. The amount of commissions
  off-set from Transactions is posted on the Site, and will vary between
  approximately 1% and 3%. Commissions are settled in Ethereum and are
  non-refundable.
</p>
<ul>
  <li>
      xSigma Status
  </li>
</ul>
<p>
  The Services are not a cryptocurrency Wallet, whether hosted or unhosted
  nor do they consist of the issuance or custody of NFTs or securities.
  xSigma never takes possession or control of any of your private keys
  associated with your Digital Assets, Account or Wallet. xSigma is not a
  money services business. xSigma is not a bank. xSigma is not a payment
  processor. xSigma is not an issuer, broker or investment advisor.
</p>
<p>
  The Services may be unavailable or subject to certain restrictions in
  certain states or jurisdictions at the sole discretion of xSigma without
  prior notice to you and without your consent.
</p>
<p>
  The Marketplace and Digital Assets could be impacted by one or more
  regulatory inquiries or regulatory action, which could impede or limit the
  ability of xSigma to continue to develop, or which could impede or limit
  your ability to access or use the Services or the underlying blockchain
  network.
</p>
<p>
  xSigma has no qualifications or registrations in accounting, securities or
  other professional services. The Services shall not be construed as
  financial advice and you agree to consult your own lawyer, accountant and
  other professional advisors prior to engaging in Transactions.
</p>
<ul>
  <li>
      Third Party Servicers
  </li>
</ul>
<p>
  As per the Transactions, User also authorizes xSigma to send and receive
  User Data to and from other Third Party Servicers.
</p>
<p>
  If User authorizes the Services to take instructions from User, or in
  respect of an Account, via a Third Party Servicer, then such instructions
  shall be deemed delivered by User directly.
</p>
<p>
  User represents that each Third Party Servicer is party to a Third Party
  Servicer Agreement with User and that User assumes exclusive responsibility
  for acts and omissions of all Third Party Servicers whether under the Third
  Party Servicer Agreement, with respect to User Data or otherwise. The use
  of a Third Party Servicer does not relieve User of any of its
  responsibilities or obligations under this Agreement. User is liable for
  any and all fees charged by Third Party Servicers. Unless indicated to the
  contrary in the Account, fees for Services do not include fees applicable
  under Third Party Servicer Agreements.
</p>
<ul>
  <li>
      Personal Information Consent
  </li>
</ul>
<p>
  User authorizes xSigma to, directly or through third parties, make
  inquiries and take any other actions xSigma deems reasonably necessary to
  verify User identity, and the information User has provided. Where xSigma
  is not entirely satisfied that it has verified User identity, it reserves
  the right to limit User access to some or all Services. User consents to
  xSigma collecting, storing, processing and disclosing information and other
  User Data as required to perform the Services and in accordance with the
  xSigma Privacy Policy.
</p>
<ul>
  <li>
      Unacceptable use or behavior
  </li>
</ul>
<p>
  User alone is responsible for User actions, conduct, and behavior while
  using the Services. User shall not:
</p>
<ul>
  <li>
      Use or attempt to use any Other User’s account;
  </li>
  <li>
      Engage in activity, or use the Services in any manner, that imposes an
      excessive burden on xSigma, or exposes xSigma to excessive security,
      financial or reputational risk, each as determined by xSigma in its
      sole discretion;
  </li>
  <li>
      Engage in any behaviors that disrupt or negatively affect or inhibit
      Other Users from fully enjoying the Services or those of Third Party
      Servicers;
  </li>
  <li>
      Detrimentally interfere with, intercept, or expropriate xSigma systems,
      data, or information;
  </li>
  <li>
      Attempt to modify the Marketplace other than as permitted by its own
      blockchain-based terms;
  </li>
  <li>
      Publish or distribute any false or deceptive information concerning
      xSigma or the Marketplace;
  </li>
  <li>
      Directly or indirectly assist in any activity that is illegal or
      harmful to any Other User or other third party;
  </li>
  <li>
      Use the Services to make any prediction or promise to any Person
      concerning the future value of any Digital Asset or reference the
      Services in any such prediction or promise;
  </li>
  <li>
      Transmit or upload to xSigma or the Services any malware, virus, trojan
      horses, worms, or other harmful programs;
  </li>
  <li>
      Circumvent, or attempt to circumvent, any content filtering techniques
      used by xSigma;
  </li>
  <li>
      Access, or attempt to access, any feature of the Services that User is
      not authorized to access;
  </li>
  <li>
      harass, stalk, threaten, defame, abuse, violate or infringe the legal
      rights of others, or otherwise be deceptive or objectionable, each as
      determined by xSigma in its sole discretion;
  </li>
  <li>
      Post or distribute unsolicited content, promotions, campaigns, or
      commercial messages (SPAM), or any message or user content designed to
      advertise a third party product or service or deceive Other Users or
      other third parties;
  </li>
  <li>
      Post content or collect content containing private information of any
      Other User or third party, including but not limited to phone numbers,
      email addresses, mailing addresses;
  </li>
  <li>
      Violate any intellectual property rights; or
  </li>
  <li>
      Induce or encourage any third party to engage in any activities or
      behaviors prohibited in this section.
  </li>
</ul>
<ul>
  <li>
      Network Fees
  </li>
</ul>
<p>
  You are solely responsible for paying all network fees associated with
  Transactions and all fees of Third Party Servicers, as the case may be.
  xSigma will not advance nor fund network fees on your behalf, nor will
  xSigma be responsible should the network fee paid be insufficient or
  excessive. Your Wallet must hold sufficient funds to cover the Transaction
  and its associated network fees, failing which the Transaction may be
  blocked, might fail, or result in your value being suspended temporarily or
  forever.
</p>
<ul>
  <li>
      Term and Termination
  </li>
</ul>
<p>
  User can terminate this Agreement at any time for any reason or for no
  reason by ceasing to use the Services. On termination, any User Data
  concerning then-pending Transactions may be subject to at least a 30 day
  fraud-prevention hold term, and such other restrictions as may be necessary
  to reduce the risk of fraud or security breaches.
</p>
<p>
  At any time and in its sole discretion, without prior notice, and without
  any liability to you, xSigma may temporarily or permanently, amend,
  suspend, restrict, or terminate access to any or all of the Services for
  any reason or for no reason. xSigma is under no obligations to disclose its
  reason for any termination or suspension of the Services for User or
  generally. You will not rely on the Services as the sole means of
  communicating Transaction User Data with respect to the Ethereum
  blockchain.
</p>
<p>
  xSigma will not be liable for any losses suffered by User resulting from
  any suspension or termination of the Services. You agree that your Digital
  Assets will not be possible to access via the Services during a suspension
  of Services. So long as the Ethereum blockchain is operational (meaning
  there are miners mining on the blockchain), your Digital Assets are always
  accessible by way of your Wallet, with or without the Services.
</p>
<p>
  In the event of any termination of this Agreement, discontinuation of all
  Services, or termination of your right to access all Services: (i) xSigma
  may cancel sending of User Data in respect of any Transactions that are
  pending at the time of the termination of your right to access all Services
  or discontinuation of the Services;(ii) xSigma may delete User Data without
  any liability to User, provided that xSigma also reserves the right to
  retain User Data for seven (7) years following termination; or (iii) making
  a report to law enforcement or other authorities. xSigma reserves the right
  to retain aggregated anonymized User Data for longer periods in order to
  improve Services, prevent fraud, and produce aggregated statistics that do
  not identify you or your User Data.
</p>
<ul>
  <li>
      Third party content
  </li>
</ul>
<p>
  In the Services, you may view or otherwise access Third Party Content.
  xSigma does not control or endorse any Third Party Content. You acknowledge
  that Third Party Content, when coupled with Third Party Servicer Services
  and your Approved Wallet, may result in your carrying out Transactions.
  Transactions are not initiated by or controlled by xSigma. xSigma shall
  have no liability for Third Party Content, including without limitation any
  material that may be erroneous, incomplete, misleading, offensive, or
  otherwise objectionable. xSigma has no liability for the way you may
  interpret or use Third Party Content, or what actions you may take as a
  result of having been exposed to such Third Party Content. Further, User
  interaction with such Third Party Content is between User and the third
  parties and shall be subject to the third parties’ terms of use. User
  acknowledges and agrees that use of any Third Party Content is at their own
  risk, and that xSigma is not responsible for any loss or damage in
  connection with such use.
</p>
<ul>
  <li>
      Taxes
  </li>
</ul>
<p>
  It is your sole responsibility to comply with Applicable Laws related to
  your use of the Services including, without limitation, to pay any and all
  taxes that apply to any Transactions. It is your sole responsibility to
  report and remit the correct tax amount to any and all tax authorities. As
  the Services do not include collecting or controlling any Digital Assets,
  and there are no fees charged by xSigma for the Services, xSigma shall not
  collect, withhold or report any amounts or information concerning your
  Transactions or possible tax implications thereof. xSigma reserves the
  right to set-off taxes payable on Transaction and remit the amount thereof
  to taxing authorities, but it has no obligation to do so.
</p>
<ul>
  <li>
      Errors
  </li>
</ul>
<p>
  Given that we do not control your or Other User Digital Assets, despite our
  best efforts, we are not able to undo any Transaction or retrieve any
  misappropriated Digital Assets. Nonetheless, we shall use commercially
  reasonable efforts to investigate disputes or errors related to our
  Services and respond to your reports of the same within thirty (30) days.
</p>
<ul>
  <li>
      Electronic notices
  </li>
</ul>
<p>
  User agrees to receive all communications, including but not limited to,
  this Agreement, other applicable policies, updates to the same, statements,
  reports, invoices, receipts, notices, disclosures, legal or regulatory
statements, or documents (collectively, the “    <strong>Communications</strong>”) with respect to User’s Account and User’s
  use of the Services via electronic delivery where User has provided and
  address for that purpose.
</p>
<p>
  By consenting to electronic delivery, User authorizes xSigma to deliver
  Communications by (i) email notification, or (ii) sending User an email
  message containing a link to, or a notice that directs User to, the Site
  where the communication or document can be read or printed.
</p>
<p>
  xSigma shall have no liability and User shall fully indemnify xSigma for
  any and all losses, liabilities, costs, expenses, demands, charges and
  claims of any kind resulting from User’s reliance on email or User’s
  Devices or failures in User’s email systems or Devices. If User prefers not
  to accept these risks, then User must not create an Account nor use the
  Services.
</p>
<p>
  It is the responsibility of User to provide xSigma with an up to date,
  true, accurate, and complete email address and to make sure xSigma emails
  are not deleted or otherwise missed on account of spam filters.
</p>
<p>
  User’s consent to receipt of electronic statements, reports and other
  Communications shall be valid until User withdraws such consent by
  notifying xSigma in writing, in which case: (i) xSigma shall provide
  statements, reports and other Communications on paper; and (ii) xSigma may
  suspend or terminate the Account and access to the Services.
</p>
<ul>
  <li>
      Risk disclosures, assumption of risks, and release of xSigma
  </li>
</ul>
<p>
  Other than as expressly set forth in this Agreement, xSigma shall not be
  liable for any risks inherent in the Services or losses that may be
  incurred by User as a result thereof. User agrees to and does hereby: (i)
  release xSigma from any and all liability related to or arising from risks
  inherent in the Services, Digital Assets, except as expressly set forth in
  this Agreement, and (ii) assume all risks inherent in the Services, Digital
  Assets, including, but not limited to:
</p>
<ul>
  <li>
      Risk of the network ceasing to give effect to Transactions for a given
      Digital Asset. The market for Digital Assets may also collapse without
      warning or reason.
  </li>
  <li>
      Trading in Digital Asset (e.g. NFTs) entails significant risks of
      financial loss. You should not commit funds to trading in Digital Asset
      that you are not prepared to lose entirely. Market prices for Digital
      Asset can be volatile and highly unpredictable. Whether the future
      market price for a Digital Asset will move up or down is a speculation
      and unknowable.
  </li>
  <li>
      There is risk that changes in Applicable Law could adversely affect
      User’s right to legally use, transfer, exchange, and/or obtain value
      from Digital Assets . It may be illegal, now or in the future, to own,
      hold, sell or use Digital Assets in one or more countries, including
      the United States.
  </li>
  <li>
      Transactions are, as a rule, irreversible. Consequently, losses due to
      fraudulent or accidental Transactions are generally not recoverable.
      Once a Transaction has been recorded on the Ethereum blockchain it is
      not reversible. It is possible that, through computer or human error,
      or through theft or fraud, User Digital Assets could be transferred in
      incorrect amounts or to unauthorized third parties.
  </li>
  <li>
      Some Transactions shall be deemed to be made when recorded on the
      network, which is not necessarily the date or time that the User
      initiated the Transaction.
  </li>
  <li>
      There is no guarantee that the markets for any Digital Asset will be
      active and liquid or permit you to establish or liquidate positions in
      the Digital Asset when desired or at favorable prices.
  </li>
  <li>
      Digital Assets may be the target of fraud and cyber-attacks. On your
      Devices and Wallets, such fraud and attacks can result in total loss to
      the User. Where User’s Account is compromised such efforts may fail and
      could result in total loss of User Digital Assets. Attacks on the
      Ethereum blockchain or on xSigma may also result in unplanned and
      significant unscheduled downtime during which Transactions will not be
      executed. Absent fraud or intentional wrongdoing on the part of xSigma,
      xSigma shall not be liable for User losses on account of any of the
      foregoing circumstances or compromised Account credentials.
  </li>
  <li>
      The Services and Transactions rely on the internet, that includes third
      party hardware, software, and internet connections, all of which may
      suffer communication failures, disruptions, errors, distortions or
      delays that might affect User’s ability to access and use the Services.
  </li>
  <li>
      Advances in code cracking or technical advances such as the development
      of quantum computers may also present risks to cryptocurrencies and the
      Services, which could result in the loss or theft of your cryptographic
      tokens or property. xSigma might or might not update the Services to
      account for such advances and to incorporate additional security
      measures, but does not guarantee or otherwise represent full security
      of the system.
  </li>
</ul>
<p>
  User shall carry out their own independent analysis and assessment of the
  risks specific to the Digital Assets and the Services.
</p>
<ul>
  <li>
      Intellectual Property Rights
  </li>
</ul>
<p>
  Unless otherwise expressly indicated by us as being open source, all
  copyright and intellectual property rights in all content and materials
  (including but not limited to our logo, designs, text, graphics, files,
  videos, displayed text, source code, databases, functionality, software,
  Site designs, audio, video, photographs, trademarks, service marks, logos
  contained therein collectively “<strong>xSigma Materials</strong>”)
  contained on our Site, the Services and Portal are the proprietary property
  of xSigma, or our suppliers, licensors, or third party affiliates, and are
  protected by British Virgin Islands and international property laws.
</p>
<p>
  We grant you a limited, non-exclusive, non-sublicensable, revocable license
  to access and use the xSigma Materials for your personal use in the
  Services. Such license does not permit the: (i) distribution of xSigma
  Materials, (ii) resale of xSigma Materials, (iii) making derivative works
  of xSigma Materials, or (iv) decompilation, disassembling, reverse
  engineering, or any other attempt to discover the source code of the Site,
  Services, or xSigma Materials. This license shall automatically terminate
  if your access to the Services is suspended or terminated by us. “xSigma”,
  any product or service names, logos, and other marks used on the Site or in
  xSigma Materials, or that we otherwise use in connection with the Services
  (collectively, the “<strong>xSigma Marks</strong>”) are trademarks owned by
  xSigma and our licensors. Without our prior written consent, you may not
  copy, imitate, or use xSigma trademarks. User also agrees that it will not,
  both during the term of this Agreement and after it ends, either: (i)
  challenge, or assist others to challenge, any of the xSigma Marks or the
  registration thereof; or (ii) register, or attempt to register, any trade
  marks, service marks, trade names, or logos that are confusingly similar to
  the xSigma Marks.
</p>
<p>
  We reserve the right to limit, deny, or terminate access to our Services
  with respect to any Person who infringes on the intellectual property
  rights of others. If you knowingly misrepresent that any activity or xSigma
  materials are infringing on the intellectual property rights of another
  party, you may be liable to xSigma for certain costs or damages.
</p>
<p>
  xSigma shall own and have exclusive rights (including but not limited to
  intellectual property rights) to any feedback, ideas, suggestions, or other
  materials or information that you provide through chat, email, posting
  through our Services, or otherwise (“<strong>Feedback</strong>”). You
  acknowledge and agree that any Feedback you submit is not confidential. You
  shall not be entitled to, and hereby waive any claim for, acknowledgement
  or compensation related to any Feedback you provide. You grant xSigma the
  right to unrestricted use and dissemination of any Feedback you provide,
  without acknowledgement or compensation to you.
</p>
<p>
  You hereby grant xSigma a royalty free, irrevocable, perpetual, and
  exclusive worldwide license to use, copy, modify, display, publish, or
  distribute, in any form, any text, images, data, or materials or other
  content that you provide to xSigma using the Services, or submit, post, or
otherwise send to xSigma that is not Feedback (the “    <strong>User Content</strong>”). xSigma may remove any User Content from
  the Site or the Services at any time for any or no reason.
</p>
<ul>
  <li>
      Disclaimer of warranties
  </li>
</ul>
<p>
  THE SERVICES ARE PROVIDED TO YOU ON A STRICTLY “AS IS,” “WHERE IS,” AND
  “WHERE AVAILABLE” BASIS. XSIGMA EXPRESSLY REFUSES TO REPRESENT OR WARRANT
  THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY,
  OR FITNESS FOR A PARTICULAR PURPOSE OF THE SERVICES, THE SITE, OR THE
  INFORMATION CONTAINED THEREIN.
</p>
<p>
  YOU ACKNOWLEDGE THAT INFORMATION, Digital Assets THAT YOU STORE OR TRANSFER
  USING THE ETHEREUM BLOCKCHAIN MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED,
  OR TEMPORARILY UNAVAILABLE DUE TO A VARIETY OF CAUSES INCLUDING, BUT NOT
  LIMITED TO, INTERNET OUTAGES, FORCE MAJEURE EVENTS OR OTHER DISASTERS
  INCLUDING THIRD PARTY DOS ATTACKS, SOFTWARE FAILURES, PROTOCOL CHANGES BY
  THIRD PARTY SERVICERS, SCHEDULED OR UNSCHEDULED DOWNTIME OR MAINTENANCE,
  COMPUTER OR HUMAN ERROR, OR OTHER CAUSES EITHER WITHIN OR OUTSIDE OUR
  CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND MAINTAINING
  DUPLICATE COPIES AND RECORDS OF ANY INFORMATION YOU STORE OR TRANSFER
  THROUGH OUR SERVICES.
</p>
<p>
  WE DO NOT ACCESS OR BACKUP ANY OF YOUR WALLET DATA.
</p>
<p>
  XSIGMA REPRESENTS THAT NFTS HAVE NO SPECIFIC INHERENT VALUE.
</p>
<p>
  You acknowledge that xSigma is not responsible for transferring,
  safeguarding, or maintaining your private keys or any Digital Assets
  associated therewith. If you lose, mishandle, or have stolen associated
  digital currency private keys, you acknowledge that you may not be able to
  recover associated Digital Assets, and that xSigma is not responsible for
  such loss. You acknowledge that xSigma is not responsible for any loss,
  damage or liability arising from your failure to comply with this
  Agreement.
</p>
<p>
  SOME JURISDICTIONS DO NOT PERMIT THE DISCLAIMER OF IMPLIED TERMS IN
  CONTRACTS; THEREFORE, SOME OR ALL OF THE DISCLAIMER OF IMPLIED WARRANTIES
  IN THIS SECTION MAY NOT APPLY TO YOU.
</p>
<ul>
  <li>
      Limitation of liability
  </li>
</ul>
<p>
  IN NO EVENT SHALL XSIGMA, ITS AFFILIATES OR SERVICE PROVIDERS, LICENSORS OR
  THEIR RESPECTIVE DIRECTORS, SHAREHOLDERS, MEMBERS, OFFICERS, EMPLOYEES,
  AGENTS, JOINT VENTURERS, OR REPRESENTATIVES, BE LIABLE FOR DAMAGES OF ANY
  KIND, INCLUDING BUT NOT LIMITED TO: (I) ANY DAMAGES CAUSED BY OR RESULTING
  FROM RELIANCE BY ANY USER ON ANY INFORMATION OBTAINED FROM XSIGMA, OR THAT
  RESULTS FROM MISTAKES, OMISSIONS, INTERRUPTIONS, ERRORS, DEFECTS, VIRUSES,
  OR DELAYS IN SERVICES OPERATION OR TRANSMISSION; (II) ANY FAILURE OF
  PERFORMANCE, WHETHER OR NOT RESULTING FROM A FORCE MAJEURE EVENT,
  COMMUNICATIONS FAILURE, THEFT, OR DESTRUCTION ARISING OUT OF OR IN
  CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE SERVICES, OR THIS
  AGREEMENT; OR (III) ANY LOST PROFITS, OR ANY SPECIAL, INCIDENTAL, INDIRECT,
  OR CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT (INCLUDING BUT
  NOT LIMITED TO NEGLIGENCE), NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE EVEN
  IF AN AUTHORIZED REPRESENTATIVE OF XSIGMA HAS BEEN ADVISED OF, OR KNEW OF,
  OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
</p>
<p>
  SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OR LIMITATION OF INCIDENTAL
  OR CONSEQUENTIAL DAMAGES; THEREFORE, SOME OR ALL OF THE LIMITATIONS IN THIS
  SECTION MAY NOT APPLY TO USER WHERE THEIR APPLICATION IS REDUCED BY
  APPLICABLE LAW.
</p>
<p>
  XSIGMA LIABILITY TO YOU UNDER THIS AGREEMENT SHALL NOT, IN THE AGGREGATE,
  EXCEED ONE HUNDRED UNITED STATES DOLLARS (USD $100.00).
</p>
<ul>
  <li>
      Indemnification
  </li>
</ul>
<p>
  You agree to indemnify and hold harmless xSigma, our officers, directors,
  shareholders, employees, agents, affiliates, attorneys, suppliers,
contractors, representatives and service providers (collectively, “    <strong>Indemnified Parties</strong>”), from any and all claims, demands,
  actions, lawsuits, proceedings, investigations, liabilities, damages,
  losses, costs, expenses, fines and penalties including, without limitation,
  reasonable attorney’s fees, arising out of or related to (i) your breach of
  this Agreement, (ii) your violation of any Applicable Law, or the rights,
  including intellectual property rights, of any other person or entity,
  (iii) your use or misuse of our Services, (iv) your conduct in connection
  with the use or misuse of our Services including but not limited to a
  dispute with an Other User, (v) your negligence, fraud, willful misconduct
  or misrepresentations, and (vi) acts or omissions of Third Party Servicers.
  If you are obligated to indemnify us, we will have the right, in our sole
  discretion, to control any action or proceeding and determine if we wish to
  settle it, and if so, on what terms.
</p>
<ul>
  <li>
      Binding Arbitration
  </li>
</ul>
<p>
  PLEASE READ THIS SECTION CAREFULLY, AS IT LIMITS THE MANNER IN WHICH YOU
  MAY SEEK RELIEF AND REQUIRES YOU TO ARBITRATE DISPUTES WITH US. If you have
  a dispute with us, we will first seek to resolve such a dispute through our
  support team.
</p>
<p>
  The support team will use their best efforts to resolve the dispute
  informally and amicably. If, after negotiating for thirty (30) days (or for
  some longer period if the parties agree), no resolution of the dispute is
  reached by the senior executives, the parties will submit the dispute to
  binding arbitration in the British Virgin Islands pursuant to the
Commercial Arbitration Rules of the American Arbitration Association (“    <strong>AAA</strong>”) and the procedures set forth below.
</p>
<p>
  All disputes that cannot be resolved pursuant to the internal issue
  resolution process identified above will be submitted to and settled by
  final and binding arbitration. The arbitration will take place in the
  British Virgin Islands and will apply the governing law of this Agreement.
  The final and binding arbitration will be performed by a panel of three
  arbitrators in accordance with and subject to the Commercial Arbitration
  Rules of the AAA then in effect. Following notice of a party’s election to
  require arbitration, each party will, within thirty (30) days, select one
  arbitrator; and those two arbitrators will, within thirty (30) days
  thereafter, select a third arbitrator. If the two arbitrators are unable to
  agree on a third arbitrator within thirty (30) days, the AAA will, within
  thirty (30) days thereafter, select the third arbitrator. The decision of
  the arbitrators will be final and binding, and judgment on the award may be
  entered in any court of competent jurisdiction. The arbitrators will be
  bound by the warranties, limitations of liability, and other provisions of
  this Agreement. Notwithstanding the foregoing, each party may seek
  injunctive relief in a court of competent jurisdiction, where appropriate,
  to protect its rights pending the outcome of the arbitration.
</p>
<p>
  ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT MUST BE
  ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS,
  AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY
  OR CONSOLIDATED WITH THOSE OF ANY OTHER USER.
</p>
<p>
  NOTICE: BOTH YOU AND XSIGMA ARE AGREEING TO HAVE ALL DISPUTES, CLAIMS OR
  CONTROVERSIES ARISING OUT OF OR RELATING TO THIS AGREEMENT DECIDED BY
  NEUTRAL BINDING ARBITRATION AND ARE GIVING UP ANY RIGHTS IT MIGHT POSSESS
  TO HAVE THOSE MATTERS LITIGATED IN A COURT OR JURY TRIAL. BY AGREEING TO
  THIS AGREEMENT, BOTH YOU AND XSIGMA ARE GIVING UP THEIR RESPECTIVE JUDICIAL
  RIGHTS TO DISCOVERY AND APPEAL EXCEPT TO THE EXTENT THAT THEY ARE
  SPECIFICALLY PROVIDED FOR UNDER THIS AGREEMENT. IF ANY PARTY REFUSES TO
  SUBMIT TO ARBITRATION AFTER AGREEING TO THIS PROVISION, THAT PARTY MAY BE
  COMPELLED TO ARBITRATE UNDER FEDERAL OR PROVINCIAL LAW. BOTH YOU AND XSIGMA
  CONFIRM THEIR AGREEMENT TO THIS ARBITRATION PROVISION IS VOLUNTARY.
</p>
<ul>
  <li>
      Governing law
  </li>
</ul>
<p>
  These Terms shall be governed by and construed in accordance with British
  Virgin Islands law, without regard to principles of conflict of laws.
</p>
<ul>
  <li>
      Severability
  </li>
</ul>
<p>
  This Agreement and any policies incorporated herein by reference shall be
  deemed severable. The invalidity or unenforceability of any of the terms or
  provisions of this Agreement, and any policies incorporated herein by
  reference, or the invalidity or unenforceability of the application thereof
  to any individual, entity or circumstance, shall not affect the application
  of such terms or provisions to individuals, entities or circumstances other
  than those as to which they are held invalid or unenforceable, and shall
  not affect the validity or enforceability of any other terms or provisions
  of this Agreement. Each term and provision of this Agreement and any
  policies incorporated herein by reference shall be valid and enforceable to
  the fullest extent permitted by law.
</p>
<ul>
  <li>
      Assignment
  </li>
</ul>
<p>
  xSigma may assign any of its rights or obligations under this Agreement
  without your consent and without prior notice to you. You may not assign or
  transfer any of your rights or obligations under this Agreement, in whole
  or in part, by operation of law or otherwise, without the prior written
  consent of xSigma, which may be withheld in xSigma’s sole discretion. Any
  actual or attempted assignment hereof by User contrary to the terms of this
  Agreement shall be null and void. xSigma may, at its discretion, assign
  some or all of its rights or obligations to a third party, without consent
  or approval of User. In the event that xSigma is acquired by, sold to, or
  merged with a third party entity, xSigma reserves the right to transfer or
  assign all User Data as part of such merger, acquisition, sale, or change
  of control.
</p>
<ul>
  <li>
      Non-waiver of rights
  </li>
</ul>
<p>
  Any failure or delay by xSigma to exercise any right, privilege, or power
  under this Agreement shall not operate as a waiver thereof. This Agreement
  shall not be construed to waive any rights that cannot be waived under
  Applicable Laws.
</p>
<ul>
  <li>
      Force majeure
  </li>
</ul>
<p>
  xSigma shall have no liability or responsibility for: (i) failure in
  performance or interruption of service; (ii) any inaccuracy, error, delay
  in, or omission of information or transmission of information; or (iii) any
  loss or damage that you may incur, which results directly or indirectly
  from any force majeure event including, but not limited to flood, act of
  civil or military authorities, terrorist acts, extraordinary weather
  conditions, pandemic, fire, pandemic, war, strike or other labor dispute,
  interruption of telecommunication services, accident, power failure,
  hardware or software malfunction, any act of god, or any other act that is
  beyond our reasonable control.
</p>
<ul>
  <li>
      Independent relationship; no broker or agent
  </li>
</ul>
<p>
  xSigma is an independent contractor, and nothing in the nature of the
  Services or any provisions in this Agreement shall be deemed, or cause, or
  is intended to cause User and xSigma to be treated as partners, joint
  venturers, or as an agent of the other party. xSigma is not User’s broker,
  intermediary, agent, or financial advisor, and xSigma has no fiduciary
  obligation to User in connection with any Transactions, or other acts or
  omissions of User with respect to the Services. For the avoidance of doubt,
  xSigma is in no manner providing investment advice, tax advice, legal
  advice, or other professional advice whether through the Services or
  otherwise. xSigma does not recommend or endorse that you purchase or sell
  any Digital Assets , or that you make any investment or enter into any
  trade.
</p>
<ul>
  <li>
      Survival
  </li>
</ul>
<p>
  All provisions of this Agreement, and any policies incorporated herein by
  reference, which by their nature extend beyond the expiration or
  termination of this Agreement shall survive the termination or expiration
  of this Agreement. Such provisions include, but are not limited to,
  sections and provisions relating to Account termination or suspension,
  funds due to xSigma, disputes with xSigma, Risk Disclosures, Unclaimed
  Property, Copyrights, Trademarks, Eligibility, Disclaimer of Warranties,
  Limitation of Liability, Indemnification, Binding Arbitration, and
  Governing Law.
</p>
<ul>
  <li>
      Contact
  </li>
</ul>
<p>
  If you have any feedback, comments, complaints or questions other than
  technical support, you can contact us using the contact information on our
  Site. When contacting us, please be sure to provide us with your name,
  contact information and any other information we may need to identify you,
  your Account and any transaction on which you have questions, comments, or
  complaints.
</p>
<ul>
  <li>
      Headings
  </li>
</ul>
<p>
  Section headings and section labels are provided for convenience only, and
  do not form part of this Agreement and shall not affect in any way the
  meaning or interpretation of this Agreement.
</p>
<ul>
  <li>
      English
  </li>
</ul>
<p>
  This Agreement is presented in the English language. Any translation of
  this Agreement is for your convenience, and any meanings of terms,
  conditions, or representations are subject to the definition and
  interpretation of the English language.
</p>
<ul>
  <li>
      Entire Agreement
  </li>
</ul>
<p>
  This Agreement, and the policies incorporated herein by reference,
  constitute the entire understanding and agreement between User and xSigma
  regarding the Services. This Agreement, and the policies incorporated
  herein by reference supersede all other representations, agreements and
  understandings, both written and oral, including without limitation any
  prior versions of this Agreement, among User and xSigma with respect to the
  Services. In the event of any conflict between this Agreement and any other
  agreement User may have with xSigma, unless the other agreement
  specifically references and identifies this Agreement, this Agreement shall
  control.
</p>
<ul>
  <li>
      Glossary
  </li>
</ul>
<p>
  For the purposes of this Agreement, the terms below in this glossary have
  the meanings indicated below:
</p>
<p>
  <strong>Account</strong>
  means a Transaction or series of Transaction involving the Marketplace, as
  created by you using your Approved Wallet. We do not create, host or
  control your Account; you do.
</p>
<p>
  <strong>Affiliate</strong>
  means, in relation to a Party, another person or entity that directly or
  indirectly owns or controls, is owned or controlled by, or is under common
  ownership or common control with the Party, or a Party's principal
  partners, shareholders, or owners of some other ownership interest.
</p>
<p>
  <strong>Applicable Law</strong>
  means, with respect to any Person, any domestic or foreign, federal, state,
  provincial or local statute, law, ordinance, rule, administrative
  interpretation, regulation, order, writ, injunction, judgment, decree or
  other requirement of any Regulator which is legally binding and applicable
  to such Person or any of its Affiliates or any of their respective
  properties, assets, equity holders, officers, directors, members, partners,
  employees, consultants or agents.
</p>
<p>
  <strong>Approved Regions</strong>
means The British Virgin Islands and those places not listed in    <strong>Exhibit “A”</strong> to this Agreement and where the Services are
  also accessible, such as they may be from time to time.
</p>
<p>
  <strong>Approved Wallet</strong>
  means a valid account established at any Approved Wallet Provider or
  otherwise capable of receiving or otherwise transacting in Digital Assets.
</p>
<p>
  <strong>Approved Wallet Provider</strong>
  means a Wallet provider that is compatible with the Ethereum blockchain and
  the Site, as determined by xSigma in its sole discretion. xSigma reserves
  the right to withdraw or grant Approved Wallet Provider status to any
  Approved Wallet Provider at its discretion.
</p>
<p>
  <strong>Device</strong>
  means a phone, computer or other device, that only you control, that you
  use to access the Site, Portal or Services.
</p>
<p>
  <strong>Digital Assets</strong>
  means digital assets that are controlled using an Approved Wallet on the
  Ethereum blockchain, such as NFTs, Ethereum and ERC-20 tokens.
</p>
<p>
  <strong>Ethereum blockchain</strong>
  means a decentralized computation environment that creates an electronic
  record established by multiple parties to verify and store a digital record
  of transactions, which is secured by the use of a mathematical algorithm
  which, utilizing previous transaction information, performs a one way
  conversion of input data into output data of a specified size to verify the
  integrity of the data. More information on Ethereum can be found here
  www.ethereum.org.
</p>
<p>
  <strong>Other User</strong>
  means a Person that is not the User or an Affiliate of the User, who is
  party to an agreement with xSigma substantively identical to this Agreement
  and that is a user of the Services.
</p>
<p>
  <strong>Person</strong>
  means any individual, corporation, limited liability company, partnership,
  joint venture, trust, unincorporated organization, Regulator or government
  or any agency or political subdivision thereof. The term Person shall also
  include automated contracts published to the Ethereum blockchain.
</p>
<p>
  <strong>Privacy Policy</strong>
  means the xSigma Privacy Policy, posted at the Site, such as it is from
  time to time.
</p>
<p>
  <strong>Regulator</strong>
  means any federal, provincial, state or local or foreign government or any
  court, arbitrator, administrative or regulatory agency or commission or
  other governmental authority or agency.
</p>
<p>
  <strong>Third Party Content</strong>
  means links, Transaction information, graphics, images, social media
  content to third party sites, content or other information supplied by
  third parties and that can be viewed or accessed from the Service.
</p>
<p>
  <strong>Third Party Servicer Agreement</strong>
  means an agreement between User and a Third Party Servicer pursuant to
  which the Third Party Servicer delivers their services.
</p>
<p>
  <strong>Third Party Servicer</strong>
  means a third party: (i) whose service are compatible with the Services, as
  indicated by xSigma; (ii) that User has retained under a Third Party
  Servicer Agreement or through integration with a protocol or Wallet; (iii)
  with whom User wishes to share User Data; and (iv) through which or from
  which User wishes to deliver or receive User Data with respect to the
  Account. Approved Wallet Providers that you use are Third Party Servicers.
</p>
<p>
  <strong>Transaction</strong>
  means an actual or attempted Ethereum blockchain transaction involving a
  User Ethereum Wallet and the Marketplace.
</p>
<p>
  <strong>User Data</strong>
  means the information collected, stored, accessed or used by xSigma in the
  course of supplying the Services in respect of the Account.
</p>
<p>
  <strong>User Device</strong>
  means an electronic device used by User to access the Services, such as a
  computer, tablet, mobile phone or other device.
</p>
<p>
  <strong>Wallet</strong>
  means an Approved Wallet or other device or application capable of storing
  or using private keys to Digital Assets with the effect of initiating
  Transactions.
</p>
<p>
  <strong>Prohibited Activity</strong>
  means the operation of or the direct or indirect facilitation of any of the
  following:
</p>
<ul>
  <li>
      Any act that is illegal in the British Virgin Islands, the United
      States or in the jurisdiction where the Person carrying out the
      activity is resident, domiciled or located;
  </li>
  <li>
      Operation of a business that is similar to or competitive with the
      Services;
  </li>
  <li>
      Operation of a crypto currency exchange;
  </li>
  <li>
      Operation of a payment processor, money transmitter, check casher,
      foreign exchange dealer, provider of prepaid access or other money
      services business;
  </li>
  <li>
      Betting, including lottery tickets, casino gaming chips, off-track
      betting, memberships on gambling-related internet sites and wagers at
      races;
  </li>
  <li>
      Bill payment services;
  </li>
  <li>
      Credit counseling or repair agencies;
  </li>
  <li>
      Credit protection or identity theft protection services;
  </li>
  <li>
      Direct marketing or subscription offers;
  </li>
  <li>
      Inbound or outbound telemarketing businesses including lead generation
      businesses;
  </li>
  <li>
      Internet, mail or telephone order pharmacies or pharmacy referral
      services;
  </li>
  <li>
      Creation, promotion or sale of items that encourage, promote,
      facilitate or instruct others to engage in illegal activity;
  </li>
  <li>
      Creation, promotion or sale of items that may be counterfeit;
  </li>
  <li>
      Creation, promotion or sale of items that may infringe or violate any
      copyright, trademark, right of publicity or privacy or any other
      proprietary right under the laws of any jurisdiction;
  </li>
  <li>
      Creation, promotion or sale of items that promote hate, violence,
      racial intolerance, or the financial exploitation of a crime;
  </li>
  <li>
      Creation, promotion or sale of items that promote, support or glorify
      acts of violence or harm towards self or others;
  </li>
  <li>
      Using the Services in any manner related to live animals;
  </li>
  <li>
      Using the Services in any manner related to payment aggregators;
  </li>
  <li>
      Purchase, sale or promotion of drugs, alcohol, or drug paraphernalia,
      or items that may represent these uses;
  </li>
  <li>
      Unregulated sales of money-orders or foreign currency;
  </li>
  <li>
      Using the Services for any illegal purpose, or in violation of any
      local, state, national, or international law, including, without
      limitation, laws governing intellectual property, taxation and other
      proprietary rights and data collection and privacy;
  </li>
  <li>
      Using the Services in a manner that xSigma or any of its suppliers
      reasonably believes to be an abuse of the Services;
  </li>
  <li>
      Using the Services in a manner so as to avoid payment of Fees;
  </li>
  <li>
      Using the Services in any manner that could damage, disable,
      overburden, or impair xSigma or any of the Services including without
      limitation, using the Services in an automated manner;
  </li>
  <li>
      Using the Services in any way that assists User or others in the
      violation of any law, statute or ordinance;
  </li>
  <li>
      Using the Services to collect payments that support pyramid or ponzi
      schemes, matrix programs, other “business opportunity” schemes or
      certain multi-level marketing programs;
  </li>
  <li>
      Using the Services to control an account that is linked to another
      account that has engaged in any of the foregoing activities;
  </li>
  <li>
      Using the Services to defame, harass, abuse, threaten or defraud
      others, or collect, or attempt to collect, personal information about
      users, registered recipients, or third parties without their consent;
  </li>
  <li>
      Using the Services to intentionally interfere with another user’s
      enjoyment of it, by any means, including uploading or otherwise
      disseminating viruses, adware, spyware, worms or other malicious code;
  </li>
  <li>
      Using the Services to make unsolicited offers, advertisements,
      proposals, or to send junk mail or spam to others;
  </li>
  <li>
      Using the Services to send or receive what xSigma considers to be funds
      for something that may have resulted from fraud or other illegal
      behavior;
  </li>
  <li>
      Using the Services while impersonating any person or entity or falsely
      claiming an affiliation with any person or entity; or
  </li>
  <li>
      Using the Services in any manner related to weapons including replicas
      and collectible items.
  </li>
</ul>
<p>
  <strong>Exhibit “A”</strong>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  <strong></strong>
  <br/>
</p>
<p>
  The Services may not be used in the following jurisdictions (none of these
  are Approved Regions):
</p>
<ul>
  <li>
      Afghanistan
  </li>
  <li>
      Balkans
  </li>
  <li>
      Belarus
  </li>
  <li>
      Burundi
  </li>
  <li>
      Central African Republic
  </li>
  <li>
      Crimea (Ukraine)
  </li>
  <li>
      Cuba
  </li>
  <li>
      Democratic Republic of Congo
  </li>
  <li>
      Ethiopia
  </li>
  <li>
      Guinea-Bissau
  </li>
  <li>
      Iran
  </li>
  <li>
      Iraq
  </li>
  <li>
      Lebanon
  </li>
  <li>
      Libya
  </li>
  <li>
      Mali
  </li>
  <li>
      Myanmar
  </li>
  <li>
      Nicaragua
  </li>
  <li>
      North Korea
  </li>
  <li>
      Somalia
  </li>
  <li>
      Sudan and Darfur
  </li>
  <li>
      South Sudan
  </li>
  <li>
      Syria
  </li>
  <li>
      Syrian Arab Republic
  </li>
  <li>
      Venezuela
  </li>
  <li>
      Yemen
  </li>
  <li>
      Zimbabwe
  </li>
</ul>`
};