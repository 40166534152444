import { EthereumNetworkID } from "./model/EthereumNetwork";

const configs = {
    DEPLOY_URL: process.env.REACT_APP_DEPLOY_URL,
    /* Wallet Info */
    ONBOARD_API_KEY: process.env.REACT_APP_ONBOARD_API_KEY,
    ONBOARD_NETWORK_ID: EthereumNetworkID.MainNetwork,
    ONBOARD_RINKEBY_ID: EthereumNetworkID.RinkebyNetwork,
    ONBOARD_POLYGON_ID: EthereumNetworkID.PolygonNetwork,
    ONBOARD_BSC_ID: EthereumNetworkID.BSCNetwork,
    ONBOARD_BSC_TEST_ID: EthereumNetworkID.BSCTestNetwork,
    ONBOARD_MUMBAI_ID: EthereumNetworkID.MumbaiNetwork,
    FORTMATIC_KEY:  "pk_test_BF97DF7960DC3212", /* live(Mainnet) pk_live_0E8B54C4DD1C8667*/
    PORTIS_KEY: "4ca10ae9-b144-43a3-b633-632c20f961ae",
    INFURA_KEY: process.env.REACT_APP_INFURA_KEY,
    WALLET_APP_URL: "",
    CONTACT_EMAIL: "general@xsigma.com",
    RPC_URL: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
    RINKEBY_RPC_URL: "https://rinkeby.infura.io/v3/dc31f5b956264b37a11a5753ec424860",
    POLYGON_RPC_URL: "https://polygon-rpc.com/",
    MUMBAI_RPC_URL: "https://rpc-mumbai.maticvigil.com/",
    MUMBAI_BLOCK_EXPLORER: "https://mumbai.polygonscan.com/",
    POLYGON_BLOCK_EXPLORER: "https://polygonscan.com/",
    POLYGON_GAS_STATION: "https://gasstation-mainnet.matic.network/",
    BSC_RPC_URL: "https://bsc-dataseed.binance.org/",
    BSC_TEST_RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    BSC_BLOCK_EXPLORER: "https://bscscan.com",
    BSC_TEST_BLOCK_EXPLORER: "https://testnet.bscscan.com",
    WALLET_APP_NAME: "xSigma NFT",
    CURRENCY_API_URL: "https://api.binance.com/api/v1/ticker/price",
    ETH_SCAN_URL: "https://etherscan.io/address/",
    RINKEBY_SCAN_URL: "https://rinkeby.etherscan.io/address/",
    MATIC_SCAN_URL: "https://polygonscan.com/address/",
    MUMBAI_SCAN_URL: "https://mumbai.polygonscan.com/address/",
    BSC_SCAN_URL: "https://bscscan.com/address/",
    BSC_TEST_SCAN_URL: "https://testnet.bscscan.com/address/",
    MAXIMNFT_URL: "https://maximnft.com/sub.php",
    DEFAULT_GAS: '150',
    GAS_LIMIT: 200000,
    TIMEOUT: 600,
    
    /* API Info*/
    API: {
        BASE_URL: process.env.REACT_APP_API_BASE_URL,
        AUTH_WITH_WALLET_URL: "auth/wallet",
        USERS_URL: "users",
        COLLECTION_URL: "collections",
        MY_COLLECTION_URL: "collections/user/my",
        CATEGORIES_URL: "categories",
        ACTIVITY_URL: "activities",
        TOKEN_URL: "tokens",
        MY_TOKEN_URL: "tokens/my",
        OFFER_URL: "offers",
        COMMON_URL: "common",
        IMPORTING_URL: "importing"
    },
    
    /* STORAGE Values */
    STORAGE: {
        TOKEN: 'auth_token',
        SELECTED_WALLET: 'sellectedWallet',
        SELECTED_NETWORK: 'networkID'
    },

    /* ACCEPT Values */
    NFT_FILE_ACCEPT: "image/gif,image/png,image/jpeg,image/jpg,image/webp,video/mp4,video/x-m4v,.webm,.mp3",
    IMG_FILE_ACCEPT: "image/gif,image/png,image/jpeg,image/jpg,image/webp",

    /* Smart Contract */
    XSIGMA721_ADDRESS: process.env.REACT_APP_XSIGMA721_ADDRESS || '0xB35Bde954613dC0d75eC31DCc4d46D3b9F862a15',
    RINKEBY_XSIGMA721: process.env.RINKEBY_XSIGMA721_ADDRESS || '0x554F2EbF769097AA29Fa23133EB1E9c7F446BF6C',

    MATIC_XSIGMA721: process.env.MATIC_XSIGMA721_ADDRESS || '0xcB23f98403F6AB63df54a82C71Efbd115C477a3C',
    MUMBAI_XSIGMA721: process.env.MUMBAI_XSIGMA721_ADDRESS || '0x12B558d9891f1B360d91FAD967f8A267B178aC26',

    BNB_XSIGMA721: process.env.BNB_XSIGMA721_ADDRESS || '0xd4c9E56e3A2FEFBD5b31c83a801da929076b6011', 
    BNB_TEST_XSIGMA721: process.env.BNB_TEST_XSIGMA721_ADDRESS || '0xB35Bde954613dC0d75eC31DCc4d46D3b9F862a15',

    ENGINE721_ADDRESS: process.env.REACT_APP_ENGINE721_ADDRESS || '0xe196EaE0cF3d014C05A11699f1d860F30A729A14', 
    RINKEBY_ENGINE721: process.env.RINKEBY_ENGINE721_ADDRESS || '0x5295DE94DeB3bC9F96407a91a3d9E68Af086C619',

    MATIC_ENGINE721: process.env.MATIC_ENGINE721_ADDRESS || '0x2aE342a65104A433b5707a6EB0603bB8053Ba57B',
    MUMBAI_ENGINE721: process.env.MUMBAI_ENGINE721_ADDRESS || '0x6837A4388268F2c7f388f366815eC7C49B08eD27',

    BNB_ENGINE721: process.env.BNB_ENGINE721_ADDRESS || '0x743F0d0A3fe92EF142536C4aDAfCf8aDaEFCd6A0', 
    BNB_TEST_ENGINE721: process.env.BNB_TEST_ENGINE721_ADDRESS || '0xe196EaE0cF3d014C05A11699f1d860F30A729A14',
    
    XSIGMA1155_ADDRESS: process.env.REACT_APP_XSIGMA1155_ADDRESS || '0x6FaD60AF74e91AFC80Eb739B463269EB66Ce18fA',
    RINKEBY_XSIGMA1155: process.env.RINKEBY_XSIGMA1155_ADDRESS || '0x714EC134734317a41FC892A4248d3f0430abC6d4',

    MATIC_XSIGMA1155: process.env.MATIC_XSIGMA1155_ADDRESS || '0x9bCE8e4758eB88D946cEC350d930EbC4f1bC0B82',
    MUMBAI_XSIGMA1155: process.env.MUMBAI_XSIGMA1155_ADDRESS || '0xB35Bde954613dC0d75eC31DCc4d46D3b9F862a15',

    BNB_XSIGMA1155: process.env.BNB_XSIGMA1155_ADDRESS || '0x8D30c8918896C9A776019d2f3271D4c1E2649df7',
    BNB_TEST_XSIGMA1155: process.env.BNB_TEST_XSIGMA1155_ADDRESS || '0x799176bb0B4a3dD17b3F296A6ABA43D8E504FED2',

    ENGINE1155_ADDRESS: process.env.REACT_APP_ENGINE1155_ADDRESS || '0x83dD3621b362eb63eF7e08278e0687B0606Ea841',
    RINKEBY_ENGINE1155: process.env.RINKEBY_ENGINE1155_ADDRESS || '0x32035e558d8E5A725706aB279feBDbb7Df7D0BF5',

    MATIC_ENGINE1155: process.env.MATIC_ENGINE1155_ADDRESS || '0x6E927AFaA9cBF278c56Ee0a4EaC0F37b45E3dCac',
    MUMBAI_ENGINE1155:  process.env.MUMBAI_ENGINE1155_ADDRESS || '0x2c6a623Bb916c46d0700ACA4bE004A32B857C3E6',

    BNB_ENGINE1155: process.env.BNB_ENGINE1155_ADDRESS || '0x3A45B92D6749488459AC3b8cecF5eB7913634a2e',
    BNB_TEST_ENGINE1155: process.env.BNB_ENGINE1155_ADDRESS || '0x3EB9D18133C266257A49A29854e06602818c0Ce0'
};

export default configs;