/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  NftAvatar,
  B2NormalTextTitle,
  NormalTextTitle,
  TinyMatriceTitle,
} from "../common/common.styles";
import { useHistory, Link } from "react-router-dom";
import configs from "configs";
import { AiFillHeart } from "react-icons/ai";
import imgAvatar from "assets/imgs/avatar.svg";
import { Image } from "react-bootstrap";
import { isBiggerValue } from "service/number";
import verifyBadge from "assets/imgs/verify.png";
import Utility from "service/utility";
import ethUtil from "ethereum/EthUtil";
import {
  getETHUSDTCurrency,
  getMATICUSDTCurrency,
  getBNBUSDTCurrency,
} from "store/Nft/nft.selector";

import { useAppSelector } from "store/hooks";
interface AuctionItemProps {
  item: any;
  showTimer?: boolean;
}

const AuctionItem: React.FC<AuctionItemProps> = ({
  item,
  showTimer = false,
}) => {
  const history = useHistory();
  const token = item.token || {};
  const [endingTime, setEndingTime] = useState("");
  const ethDollarPrice = useAppSelector(getETHUSDTCurrency);
  const maticDollarPrice = useAppSelector(getMATICUSDTCurrency);
  const bnbDollarPrice = useAppSelector(getBNBUSDTCurrency);

  const getTokenThumbnail = () => {
    let media = token.media_type && token.media_type.toLowerCase();
    if (
      media &&
      (media.includes("mp3") || media.includes("mp4") || media.includes("webm"))
    ) {
      if (token.preview) {
        return Utility.mediaValidation(token.preview);
      }
      return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
    }
    return Utility.mediaValidation(
      token.preview ||
        (token.thumbnail && token.thumbnail !== "false"
          ? token.thumbnail
          : "") ||
        token.media ||
        token.image
    );
  };

  const getCreator = () => {
    if (item?.creator?.wallet) return item.creator;
    return null;
  };

  const getCreatorAvatar = () => {
    const creator = getCreator();
    if (creator && creator.avatar) {
      return Utility.mediaValidation(creator.avatar);
    }
    return imgAvatar;
  };

  const getCurrentBidPrice = () => {
    if (item.bids && item.bids.length) {
      let bids = item.bids.sort(function (a: any, b: any) {
        return b.price - a.price;
      });
      return bids[0].price;
    } else if (item.min_bid) {
      return item.min_bid;
    } else {
      return 0;
    }
  };

  const getFromPrice = () => {
    let curBidPrice = getCurrentBidPrice();
    if (isBiggerValue(item.offer_price, curBidPrice) && curBidPrice !== 0)
      return curBidPrice;
    return item.offer_price;
  };

  useEffect(() => {
    if (!showTimer) return;

    let time = Utility.getPassedTimeString(item.date_end);
    setEndingTime(time);

    const timeInterval = setInterval(() => {
      let time = Utility.getPassedTimeString(item.date_end);
      setEndingTime(time);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  const goToCreatorProfile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (getCreator())
      history.push("/users/" + (getCreator()?.wallet || getCreator()?._id));
  };

  return (
    <Link
      to={
        "/tokens/" +
        token._id +
        (token.type === 1155 ? `?offer=${item._id}` : "")
      }
      className="auction-item mr-4 p-4"
    >
      <div
        className="auction-item__owner-wrapper"
        onClick={(e) => {
          goToCreatorProfile(e);
        }}
      >
        <NftAvatar
          className="auction-owner-avatar"
          imagePath={getCreatorAvatar()}
        >
          {getCreator() && getCreator().verified && (
            <Image style={{ width: 12, height: 12 }} src={verifyBadge} />
          )}
        </NftAvatar>

        <span className="auction-item__owner">
          BY <span>{getCreator()?.name || "NAME"}</span>
        </span>
      </div>

      <div className="token-img-area">
        <div className="pre-token-img">
          {token.media ? (
            <img src={getTokenThumbnail()} alt="auctionImage" />
          ) : (
            <div className="no-thumbnail"></div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between token-details-area align-items-end">
        <div className="item-content">
          <B2NormalTextTitle
            className="mt-3 font-matrice title"
            title={token.name}
          >
            {token.name}
            {getCreator() && getCreator().verified && (
              <Image
                style={{ width: 20, height: 20, marginLeft: 5 }}
                src={verifyBadge}
              />
            )}
          </B2NormalTextTitle>

          <NormalTextTitle className="grey-color">
            {token.type === 1155 &&
              `${item.sales_copies || item.available_copies}/${
                item.total_copies
              }`}
          </NormalTextTitle>

          {(item.type === "auction" || item.type === "both") && showTimer && (
            <div className="auction-item__btn">
              <span className="auction-item__btn-text">Ending in</span>
              <span className="auction-item__btn-price">{endingTime}</span>
            </div>
          )}

          {(item.type === "auction" || item.type === "both") && (
            <div className="auction-item__btn">
              <span className="auction-item__btn-text">Bid</span>
              <span className="auction-item__btn-price">
                ~{" "}
                {ethUtil.getDollarPrice(
                  getCurrentBidPrice(),
                  ethUtil.getChainIDByNetworkName(token.blockchain),
                  {
                    ethDollarPrice,
                    maticDollarPrice,
                    bnbDollarPrice,
                  }
                )}
              </span>
            </div>
          )}

          {(item.type === "direct" || item.type === "both") && (
            <div className="auction-item__btn">
              <span className="auction-item__btn-text">Buy Now</span>
              <span className="auction-item__btn-price">
                {ethUtil.getDollarPrice(
                  getFromPrice(),
                  ethUtil.getChainIDByNetworkName(token.blockchain),
                  {
                    ethDollarPrice,
                    maticDollarPrice,
                    bnbDollarPrice,
                  }
                )}
              </span>
            </div>
          )}
        </div>
        {token && token.likes > 0 ? (
          <div className="likes d-flex align-items-center">
            <AiFillHeart />
            &nbsp;
            {token.likes}
          </div>
        ) : (
          ""
        )}
      </div>
    </Link>
  );
};

export default AuctionItem;
