import React from "react";
import moment from 'moment';
import {
  B1NormalTextTitle,
  NftAvatar,
} from "../common/common.styles";
import imgAvatar from "assets/imgs/avatar.svg";

import { Image } from 'react-bootstrap';
import verifyBadge from 'assets/imgs/verify.png';
import Utility from 'service/utility';

interface BidHistoryItemProps {
  item: any;
  token: any;
  onClick?: any;
}

const BidHistoryItem: React.FC<BidHistoryItemProps> = ({ item, token, onClick }) => {
  const getDifferentHours = (date: string) => {
    if (date) {
      var now = moment(new Date()); //todays date
      var end = moment(date); // another date
      if (now.diff(end, 'hours') > 0) {
        return now.diff(end, 'hours') + ' hours ago';
      } else {
        return now.diff(end, 'minutes') + ' mins ago';
      }
    } else {
      return '';
    }
  }

  const getUserAvatar = () => {
    if (item.user && item.user.avatar) {
      return Utility.mediaValidation(item.user.avatar);
    }
    return imgAvatar;
  }

  return (
    <div className="bid-history-item d-flex align-items-center mt-3 mb-2 pointer-cursor" onClick={() => onClick(item.user)}>
      <NftAvatar imagePath={getUserAvatar()} className="mr-3 no-border">
        {item.user && item.user.verified && <Image width="20" height="20" src={verifyBadge} />}
      </NftAvatar>
      <div>
          <B1NormalTextTitle style={{ whiteSpace: 'nowrap' }}>{item.user ? item.user.name : ''} </B1NormalTextTitle>
          <p className="mb-1">Offered {parseFloat((item.copies > 1 ? item.copies * item.price : item.price).toFixed(4))} {token.blockchain} for {item.copies || 1} {item.copies > 1 ? 'editions' : 'edition'}</p>
          <p className="time-ago mb-1">{getDifferentHours(item.date)}</p>
      </div>
    </div>
  );
};

export default BidHistoryItem;