import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  LargeTextTitle,
  B2NormalTextTitle,
} from "components/common/common.styles";
import Layout from "components/Layout";
import "./Tokenization.scss";
import FeaturedImage from "assets/imgs/feature.png";

interface TokenizationProps {}

const Tokenization: React.FC<TokenizationProps> = () => {
  useEffect(() => {}, []);

  return (
    <Layout className="tokenization-container">
      <Row className="mb-5 row-reserve">
        <Col lg="6">
          <Row>
            <Col xl="9">
              <LargeTextTitle className="title-txt">
                <span>NFT</span>
                <br /> Tokenization
              </LargeTextTitle>
              <B2NormalTextTitle className="mt-3 mr-3 grey-color">
                Buy and sell fractions of any NFT starting at only $1.
              </B2NormalTextTitle>
              <B2NormalTextTitle className="mr-3 grey-color">
                Available on MaximNFT soon.
              </B2NormalTextTitle>
            </Col>
          </Row>
        </Col>
        <Col lg="6" className="pt-5">
          <img
            src={FeaturedImage}
            className="collectible-img"
            alt="collectibleImage"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default Tokenization;
