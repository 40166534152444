import SmartContract721 from "ethereum/Contract721";
import SmartContract1155 from "ethereum/Contract1155";
import OfferController from "controller/OfferController";
import { NotificationManager } from "react-notifications";
import ethUtil from "./EthUtil";

class EthMethods {
  async handleCreateOffer(
    token: any,
    chain_id: any,
    type: 721 | 1155,
    callback?: Function,
    rejectCallback?: Function
  ) {
    const engineContract =
      type === 721
        ? SmartContract721.getEngine721ReadContract()
        : SmartContract1155.getEngine1155ReadContract();

    let offerObj: any = {};

    const offer = await engineContract.methods.offers(chain_id).call();

    if (type === 721) {
      const auction =
        offer.isAuction &&
        (await engineContract.methods.auctions(offer.idAuction).call());

      console.log({ offer, auction, token });

      offerObj = {
        token_id: token._id,
        offer_price: Number(ethUtil.fromWei(offer.price)) || 0,
        min_bid: auction
          ? Number(ethUtil.fromWei(auction.currentBidAmount))
          : 0,
        offer_chain_id: Number(offer.idAuction),
      };

      if (offer.isAuction) {
        offerObj["expiry_date"] = new Date(
          (Number(auction.duration) + Number(auction.startTime)) * 1000
        ).toISOString();
      }
    }

    if (type === 1155) {
      console.log({ offer });

      offerObj = {
        token_id: token._id,
        offer_price: Number(ethUtil.fromWei(offer.price)) || 0,
        min_bid: Number(ethUtil.fromWei(offer.minimumBidAmount)) || 0,
        offer_chain_id: Number(chain_id),
        copies: Number(offer.availableCopies),
      };

      if (offer.isAuction) {
        offerObj["auctions_duration"] = Number(offer.duration) * 1000;
      }
    }

    try {
      await OfferController.create(offerObj);
    } catch (error) {
      rejectCallback && rejectCallback(error);
      return false;
    }

    callback && callback();

    NotificationManager.success("Offer is created successfully.", "Success");
    return true;
  }
}

const ethMethods = new EthMethods();

export default ethMethods;
