export const PrivacyContent = {
  data: `<p>
  xSigma Marketplace Privacy Policy
</p>
<p>
  Last Updated: October 7, 2021
</p>
<p>
  The xSigma Marketplace is an open source, decentralized marketplace
platform that runs on the Ethereum blockchain (the “    <strong>Marketplace</strong>”). xSigma recognizes that people who use the
Marketplace value their privacy. This privacy policy (“<strong>Policy</strong>”) describes how xSigma Corporation (“    <strong>Company</strong>”, “<strong>we</strong>”, “<strong>our</strong>”,
  or “<strong>us</strong>”) collects, uses, shares, and stores Personal Data
  (as defined below) of visitors to _____________________ and users of our
  portal at that URL (collectively the “<strong>Site</strong>”). The Site
  provides an interface by which you can interact with the Ethereum
  blockchain, such as to submit transactions to or approve transactions on
  the Ethereum blockchain with respect to the purchase or sale of
  non-fungible tokens (“<strong>NFT</strong>s”). The Site does not itself
  control any of your Ethereum or other token transactions, all of which
  remain entirely in your control through your virtual currency wallet. This
  Policy applies to the Company’s Site, applications, products and services
  (collectively, “<strong>Services</strong>”).
</p>
<p>
  By using the Services, you accept the terms of this Policy and our
  Marketplace Terms of Use (incorporated herein by reference), and consent to
  our collection, use, disclosure, and retention of your information as
  described in this Policy. If you have not done so already, please also
  review the Marketplace Terms of Use. The Marketplace Terms of Use contain
  provisions that limit our liability to you and require you to resolve any
  dispute with us on an individual basis and not as part of any class or
  representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS POLICY OR
  OUR DEFI PLATFORM DISCLOSURES, THEN PLEASE DO NOT USE ANY OF THE SERVICES.
</p>
<p>
  Please note that this Policy does not apply to information collected
  through third-party websites or services that you may access through the
  Services or the Site.
</p>
<p>
If you are visiting this Site from the European Union (“    <strong>EU”</strong>), see our Notice to EU Data Subjects below for our
  legal bases for processing and transfer of your data.
</p>
<p>
  We take our data protection responsibilities with the utmost seriousness
  and we have designed the Site and Services so that you may navigate and use
  our website by providing minimal Personal Data.
</p>
<p>
  In this Policy, “Personal Data” means any information relating to you as an
  identified or identifiable natural person; an identifiable natural person
  is one who can be identified, directly or indirectly, in particular by
  reference to an identifier such as a name, an online identifier or to one
  or more factors specific to your physical, physiological, genetic, mental,
  economic, cultural or social identity.
</p>
<ul>
  <li>
      WHAT WE COLLECT
  </li>
</ul>
<p>
  We get information about you in a range of ways.
</p>
<p>
  <strong>Information You Give Us.</strong>
  Information we collect from you includes:
</p>
<ul>
  <li>
      Network information regarding transactions;
  </li>
  <li>
      Contact information, such as your email address, Telegram, Discord or
      Twitter contact information;
  </li>
  <li>
      You name, address, IP address and personal identification, where
      applicable;
  </li>
  <li>
      Feedback and correspondence, such as information you provide in your
      responses to surveys, if and when you participate in market research
      activities, report a problem with Service, receive customer support or
      otherwise correspond with us;
  </li>
  <li>
      Usage information, such as information about how you use the Service
      and interact with us;
  </li>
  <li>
      Marketing information, such as your preferences for receiving
      marketing;
  </li>
  <li>
      Ethereum wallet addresses that you use;
  </li>
  <li>
      NFTs that you possess, buy or sell;
  </li>
  <li>
      The purchase price that you pay or receive in an NFT transaction; and
  </li>
  <li>
      Ethereum blockchain transactions that you initiate.
  </li>
</ul>
<p>
  <strong>Information We Get From Others.</strong>
  We may get information about you from other third party sources and we may
  add this to information we get from your use of the Services. Such
  information may include social media sites or other third party service
  providers. These features may collect your Personal Data and track your use
  of the Site. Your interactions with these features and with third party
  service providers are governed entirely by the Policy and terms of service
  of the company providing such functionality.
</p>
<p>
  <strong>Information Automatically Collected.</strong>
  We may automatically record certain information about how you use our Site
  (“<strong>Log Data</strong>“). Log Data may include information such as IP
  address, MAC or device IDs, device and browser type, operating system, the
  pages or features of our Site to which a user browsed and the time spent on
  those pages or features, the frequency with which the Site is used by a
  user, search terms, the links on our Site that a user clicked on or used,
  and other statistics. We use this information to administer the Service and
  we analyze (and may engage third parties to analyze) this information to
  improve and enhance the Service by expanding its features and functionality
  and tailoring it to our users’ needs and preferences.
</p>
<p>
  We may use cookies, local storage, or similar technologies to analyze
  trends, administer the website, track users’ movements around the website,
  and to gather demographic information about our user base as a whole. Users
  can control the use of cookies and local storage at the individual browser
  level.
</p>
<p>
  We also use Google Analytics to help us offer you an optimized user
  experience. You can find more information about Google Analytics’ use of
  your Personal Data here: https://policies.google.com/privacy?hl=en
</p>
<p>
  <strong>Information we will never collect</strong>
  . We will never ask you to share your private keys or wallet seed. Never
  trust anyone or any site that asks you to enter your private keys or wallet
  seed.
</p>
<ul>
  <li>
      <strong>USE OF PERSONAL DATA</strong>
  </li>
</ul>
<p>
  <strong>To provide our service</strong>
</p>
<p>
  We will use your Personal Data in the following ways:
</p>
<ul>
  <li>
      To enable you to access and use the Services;
  </li>
  <li>
      To improve the Services;
  </li>
  <li>
      To provide and deliver products and services that you may request;
  </li>
  <li>
      To send information, including confirmations, technical notices,
      updates, security alerts, and support and administrative messages;
  </li>
  <li>
      To screen your IP address against IP addresses of sanction
      jurisdictions where use of the Services is prohibited, including but
      not limited to those designated by the U.S. Department of the Treasury
      Office of Foreign Asset Control (“<strong>OFAC</strong>”);
  </li>
  <li>
      To block use of the Services in association with Ethereum wallet
      addresses that are associated with OFAC, other sanctions or suspicious
      activity.
  </li>
</ul>
<p>
  <strong>To comply with law</strong>
</p>
<p>
  We use your Personal Data as we believe necessary or appropriate to comply
  with applicable laws, lawful requests and legal process, such as to respond
  to subpoenas or requests from government authorities.
</p>
<p>
  <strong>To communicate with you</strong>
</p>
<p>
  We use your Personal Data to communicate about promotions, upcoming events,
  and other news about products and services offered by us and our selected
  partners.
</p>
<p>
  <strong>To optimize our platform</strong>
</p>
<p>
  In order to optimize your user experience, we may use your Personal Data to
  operate, maintain, and improve our Services. We may also use your
  information to respond to your comments and questions regarding the
  Services, and to provide you and other users with general customer service.
</p>
<p>
  <strong>With your consent</strong>
</p>
<p>
  We may use or share your Personal Data with your consent, such as when you
  instruct us to take a specific action with respect to your Personal Data,
  or you opt into third party marketing communications.
</p>
<p>
  <strong>For compliance, fraud prevention, and safety</strong>
</p>
<p>
  We may use your Personal Data to protect, investigate, and deter against
  fraudulent, unauthorized, or illegal activity.
</p>
<ul>
  <li>
      <strong>SHARING OF PERSONAL DATA</strong>
  </li>
</ul>
<p>
  We do not share or sell the Personal Data that you provide us with other
  organizations without your express consent, except as described in this
  Policy. We disclose Personal Data to third parties under the following
  circumstances:
</p>
<ul>
  <li>
      <strong>Affiliates.</strong>
      We may disclose your Personal Data to our subsidiaries and corporate
      affiliates for purposes consistent with this Policy.
  </li>
</ul>
<ul>
  <li>
      <strong>Business Transfers. </strong>
      We may share Personal Data when we do a business deal, or negotiate a
      business deal, involving the sale or transfer of all or a part of our
      business or assets. These deals can include any merger, financing,
      acquisition, or bankruptcy transaction or proceeding.
  </li>
  <li>
      <strong>
          Compliance with Laws and Law Enforcement; Protection and Safety.
      </strong>
      We may share Personal Data for legal, protection, and safety purposes.
  </li>
</ul>
<ul>
  <li>
      We may share information to comply with laws.
  </li>
  <li>
      We may share information with law enforcement where required by law or
      on a voluntary basis in order to report suspicious activity.
  </li>
  <li>
      We may share information to respond to lawful requests and legal
      processes.
  </li>
  <li>
      We may share information to protect the rights and property of the
      Company, our agents, customers, and others. This includes enforcing our
      agreements, policies, and terms of use.
  </li>
  <li>
      We may share information in an emergency. This includes protecting the
      safety of our employees and agents, our customers, or any person.
  </li>
</ul>
<ul>
  <li>
      <strong>Professional Advisors and Service Providers. </strong>
      We may share information with those who need it to do work for us.
      These recipients may include third party companies and individuals to
      administer and provide the Service on our behalf (such as customer
      support, hosting, email delivery and database management services), as
      well as lawyers, bankers, auditors, and insurers.
  </li>
  <li>
      <strong>Other. </strong>
      You may permit us to share your Personal Data with other companies or
      entities of your choosing. Those uses will be subject to the privacy
      policies of the recipient entity or entities.
  </li>
</ul>
<ul>
  <li>
      <strong>DATA ON THE BLOCKCHAIN</strong>
  </li>
</ul>
<p>
  Blockchain technology is at the core of the Marketplace. The Marketplace is
  a DeFi project on the Ethereum blockchain. Blockchains are usually
  decentralized and made up of digitally recorded data in a chain of packages
  called ‘blocks’. The manner in which these blocks are linked is
  chronological, meaning that the data is very difficult to alter once
  recorded. Since the ledger may be distributed all over the world, this
  means there is no single person making decisions or otherwise administering
  the system, and that there is no centralized place where it is located
  either.
</p>
<p>
  By design, a blockchain’s records cannot be changed or deleted once
  recorded on the blockchain. This may affect your ability to exercise your
  data rights such as your right to erasure, or your rights to object or
  restrict processing of your Personal Data. Data on the blockchain cannot be
  erased and cannot be changed. Although smart contracts may be used to
  revoke certain access rights, and some content may be made invisible to
  others, it is not deleted.
</p>
<p>
  While some data on the blockchain may be anonymized or is not directly
  identifiable to you, when analyzed together with other public or private
  data, this information may be used to identify you.
</p>
<p>
  IF YOU WANT TO ENSURE YOUR PRIVACY RIGHTS ARE NOT AFFECTED IN ANY WAY, YOU
  SHOULD NOT TRANSACT ON BLOCKCHAINS AS CERTAIN RIGHTS MAY NOT BE FULLY
  AVAILABLE OR EXERCISABLE BY YOU OR US DUE TO THE TECHNOLOGICAL
  INFRASTRUCTURE OF THE BLOCKCHAIN. IN PARTICULAR THE BLOCKCHAIN IS AVAILABLE
  TO THE PUBLIC AND ANY PERSONAL DATA SHARED ON THE BLOCKCHAIN WILL BECOME
  PUBLICLY AVAILABLE.
</p>
<ul>
  <li>
      <strong>HOW INFORMATION IS SECURED AND STORED</strong>
  </li>
</ul>
<p>
  We retain information we collect as long as it is necessary and relevant to
  fulfill the purposes outlined in this Policy. However, we may retain your
  Personal Data for a longer period of time where such retention is necessary
  for compliance with a legal obligation to which we are subject, to prevent
  fraud, resolve disputes, troubleshoot problems, assist with any
  investigation, enforce our DeFi Marketplace Disclosure, and other actions
  permitted by law. To determine the appropriate retention period for
  Personal Data, we consider the amount, nature, and sensitivity of the
  Personal Data, the potential risk of harm from unauthorized use or
  disclosure of your Personal Data, the purposes for which we process your
  Personal Data and whether we can achieve those purposes through other
  means, and the applicable legal requirements.
</p>
<p>
  In some circumstances we may anonymize your Personal Data (so that it can
  no longer be associated with you) in which case we may use this information
  indefinitely without further notice to you.
</p>
<p>
  We employ industry standard security measures designed to protect the
  security of all information submitted through the Services. However, the
  security of information transmitted through the internet can never be
  guaranteed. We are not responsible for any interception or interruption of
  any communications through the internet or for changes to or losses of
  data. In order to protect you and your data, we may suspend your use of any
  of the Services, without notice, pending an investigation, if any breach of
  security is suspected.
</p>
<ul>
  <li>
      <strong>INFORMATION CHOICES AND CHANGES</strong>
  </li>
</ul>
<p>
  <strong>
      Accessing, Updating, Correcting, and Deleting your Information
  </strong>
</p>
<p>
  You may access information that you have voluntarily provided to us by
  contacting us to review, correct, or delete it by sending a request to
  [insert email]. You can request to change contact choices, opt-out of our
  sharing with others, and update your Personal Data and preferences.
</p>
<p>
  If you no longer wish to receive marketing communications from us, you can
  also let us know via email to [insert email]. The electronic marketing
  communications we send to you also contain opt-out mechanisms that allow
  you to opt-out from receiving those communications, update your contact
  information or change your preferences at any time. We will honor your
  choice and refrain from sending you such announcements. You may also opt
  back in to receive those communications at any time.
</p>
<p>
  <strong>Tracking Technologies Generally</strong>
</p>
<p>
  Regular cookies may generally be disabled or removed by tools available as
  part of most commercial browsers, and in some instances blocked in the
  future by selecting certain settings. If you disable or block some cookies
  settings, you may not be able to view or access the Site or Services. For
  more information, please see the section entitled “Cookies Policy” below.
</p>
<ul>
  <li>
      <strong>CHANGES TO THIS POLICY. </strong>
  </li>
</ul>
<p>
  We may change this Policy at any time. We encourage you to periodically
  review this page for the latest information on our privacy practices. If we
  make any changes, we will change the last updated date above.
</p>
<p>
  Any modifications to this Policy will be effective upon our posting of the
  new terms and/or upon implementation of the changes to the Site (or as
  otherwise indicated at the time of posting). In all cases, your continued
  use of the Site or Services after the posting of any modified Policy
  indicates your acceptance of the terms of the modified Policy. If you do
  not agree with the modifications, you must terminate your use of the Site
  and Services.
</p>
<ul>
  <li>
      <strong>ELIGIBILITY</strong>
  </li>
</ul>
<p>
  The Services are available only to persons 18 years of age or older and are
  not a Prohibited User (as defined in the xSigma Marketplace Disclosure). If
  we learn that we have received any information directly from a child under
  the age of 18, we will use that information only to respond directly to
  that child (or his or her parent or legal guardian) to inform the child
  that he or she cannot use the Site and subsequently we will delete that
  information.
</p>
<ul>
  <li>
      <strong>NOTICE TO CALIFORNIA RESIDENTS</strong>
  </li>
</ul>
<p>
  Under California Civil Code Section 1789.3, California users are entitled
  to the following consumer rights notice: California residents may reach the
  Complaint Assistance Unit of the Division of Consumer Services of the
  California Department of Consumer Affairs by mail at 1625 North Market
  Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800)
  952-5210.
</p>
<ul>
  <li>
      <strong>NOTICE TO EU DATA SUBJECTS</strong>
  </li>
</ul>
<p>
  <strong>Personal Data</strong>
</p>
<p>
  With respect to EU data subjects, Personal Data, as used in this Policy, is
equivalent to “personal data” as defined in the<strong>European Union General Data Protection Regulation</strong> (“    <strong>GDPR”</strong>). You can find out more about the GDPR and your
  rights by accessing the European Commission’s website
  (https://ec.europa.eu/info/index_en).
</p>
<p>
  <strong>Sensitive Data</strong>
</p>
<p>
  Some of the information you provide us may constitute sensitive data as
  defined in the GDPR (also referred to as special categories of Personal
  Data).
</p>
<p>
  <strong>Legal Bases for Processing</strong>
</p>
<p>
  We only use your Personal Data as permitted by law. We are required to
  inform you of the legal bases of our processing of your Personal Data,
  which are described in this Policy. If you have questions about the legal
  bases under which we process your Personal Data, contact us at [insert
  email].
</p>
<p>
  Some<strong> </strong>legal bases under which we process your Personal Data
  include:
</p>
<ul>
  <li>
      To communicate with you;
  </li>
  <li>
      To optimize our platform;
  </li>
  <li>
      For compliance, fraud prevention, and safety; or
  </li>
  <li>
      To provide our service.
  </li>
</ul>
<p>
  These processing activities constitute our legitimate interests. We make
  sure we consider and balance any potential impacts on you (both positive
  and negative) and your rights before we process your Personal Data for our
  legitimate interests. We do not use your Personal Data for activities where
  our interests are overridden by any adverse impact on you (unless we have
  your consent or are otherwise required or permitted to by law).
</p>
<p>
  <strong>With your consent</strong>
</p>
<p>
  Where our use of your Personal Data is based upon your consent, you have
  the right to withdraw it anytime in the manner indicated in the Service or
  by contacting us at [insert email].
</p>
<p>
  <strong>Use for New Purposes</strong>
</p>
<p>
  We may use your Personal Data for reasons not described in this Policy,
  where we are permitted by law to do so and where the reason is compatible
  with the purpose for which we collected it. If we need to use your Personal
  Data for an unrelated purpose, we will notify you and explain the
  applicable legal basis for that use. If we have relied upon your consent
  for a particular use of your Personal Data, we will seek your consent for
  any unrelated purpose.
</p>
<p>
  <strong>Your Rights</strong>
</p>
<p>
  Under the GDPR, you have certain rights regarding your Personal Data. You
  may ask us to take the following actions in relation to your Personal Data
  that we hold:
</p>
<ul>
  <li>
      <strong>Opt-out</strong>
      . Stop sending you direct marketing communications which you have
      previously consented to receive. We may continue to send you
      Service-related and other non-marketing communications.
  </li>
</ul>
<ul>
  <li>
      <strong>Access</strong>
      . Provide you with information about our processing of your Personal
      Data and give you access to your Personal Data.
  </li>
  <li>
      <strong>Correct</strong>
      . Update or correct inaccuracies in your Personal Data.
  </li>
  <li>
      <strong>Delete</strong>
      . Delete your Personal Data.
  </li>
  <li>
      <strong>Transfer</strong>
      . Transfer a machine-readable copy of your Personal Data to you or a
      third party of your choice.
  </li>
  <li>
      <strong>Restrict</strong>
      . Restrict the processing of your Personal Data.
  </li>
  <li>
      <strong>Object</strong>
      . Object to our reliance on our legitimate interests as the basis of
      our processing of your Personal Data that impacts your rights.
  </li>
</ul>
<p>
  You can submit these requests by email to [insert email]. We may request
  specific information from you to help us confirm your identity and process
  your request. Applicable law may require or permit us to decline your
  request. If we decline your request, we will tell you why, subject to legal
  restrictions. If you would like to submit a complaint about our use of your
  Personal Data or response to your requests regarding your Personal Data,
  you may contact us at [insert email] or submit a complaint to the data
  protection regulator in your jurisdiction. You can find your data
  protection regulator <strong>here</strong>.
</p>
<p>
  You also have the right to lodge a complaint with the supervisory authority
  in the country of your habitual residence, place of work, or the place
  where you allege an infringement of one or more of our rights has taken
  place, if that is based in the EEA.
</p>
<p>
  <strong>Cross-Border Data Transfer</strong>
</p>
<p>
  Please be aware that your Personal Data may be transferred to, processed,
  and stored in or outside of the EEA. Data protection laws in other
  countries may be different from those in your country of residence. You
  consent to the transfer of your information, including Personal Data,
  outside of the EEA as set forth in this Policy by visiting our Site or
  using our Service.
</p>
<p>
  Whenever we transfer your Personal Data out of the EEA to the U.S. or
  countries not deemed by the European Commission to provide an adequate
  level of Personal Data protection, the transfer will be based on a data
  transfer mechanism recognized by the European Commission as providing
  adequate protection for Personal Data.
</p>
<p>
  Please contact us if you want further information on the specific mechanism
  used by us when transferring your Personal Data out of the EEA.
</p>
<ul>
  <li>
      <strong>COOKIES POLICY</strong>
  </li>
</ul>
<p>
  We understand that your privacy is important to you and are committed to
  being transparent about the technologies and methods we use. In the spirit
  of transparency, this Policy provides detailed information about how and
  when we use cookies on our Site.
</p>
<p>
  <strong>Do we use Cookies?</strong>
</p>
<p>
  Yes. We and our marketing partners, affiliates, and analytics or service
  providers use cookies, web beacons, or pixels and other technologies to
  ensure everyone who uses the Site has the best possible experience.
</p>
<p>
  <strong>What is a Cookie?</strong>
</p>
<p>
  A cookie is a small text file that is placed on your hard drive by a web
  page server. Cookies contain information that can later be read by a web
  server in the domain that issued the cookie to you. Some of the cookies
  will only be used if you use certain features or select certain
  preferences, and some cookies will always be used. Web beacons, tags and
  scripts may be used in the Site or in emails to help us to deliver cookies,
  count visits, understand usage and campaign effectiveness and determine
  whether an email has been opened and acted upon. We may receive reports
  based on the use of these technologies by our service/analytics providers
  on an individual and aggregated basis.
</p>
<p>
  <strong>Why do we use Cookies?</strong>
</p>
<p>
  We generally use cookies for the following purposes:
</p>
<ul>
  <li>
      To recognize new or past customers.
  </li>
  <li>
      To improve our Site and to better understand your visits on our
      platforms and Site.
  </li>
  <li>
      To integrate with third party websites.
  </li>
  <li>
      To serve you with interest-based or targeted advertising.
  </li>
  <li>
      To observe your behaviors and browsing activities over time across
      multiple websites or other platforms.
  </li>
  <li>
      To better understand the interests of our customers and our website
      visitors.
  </li>
</ul>
<p>
  Some cookies are necessary for certain uses of the Site, and without such
  cookies, we would not be able to provide many services that you need to
  properly use the Site. These cookies, for example, allow us to operate our
  Site so you may access it as you have requested and let us recognize that
  you have an Account (as defined in the Marketplace Terms of USe) and have
  logged into that Account to access Site content. They also include cookies
  that enable us to remember your previous actions within the same browsing
  session and secure our Sites.
</p>
<p>
  We also use functional cookies and cookies from third parties for analysis
  and marketing purposes. Functional cookies enable certain parts of the site
  to work properly and your user preferences to remain known. Analysis
  cookies, among other things, collect information on how visitors use our
  Site, the content and products that users view most frequently, and the
  effectiveness of our third party advertising. Advertising cookies assist in
  delivering ads to relevant audiences and having our ads appear at the top
  of search results. Cookies are either “session” cookies which are deleted
  when you end your browser session, or “persistent,” which remain until
  their deletion by you (discussed below) or the party who served the cookie.
</p>
<ul>
  <li>
      <strong>QUESTIONS, COMMENTS, FEEDBACK</strong>
  </li>
</ul>
<p>
  You may access and verify your Personal Data held by Us by submitting an
  email request to [insert email].
</p>
<p>
  You will not have to pay a fee to access your Personal Data or to exercise
  any of your other rights. We may charge a reasonable fee if your request is
  clearly unfounded, repetitive or excessive. Alternatively, we may refuse to
  comply with your request in these circumstances.
</p>
<p>
  We may need to request specific information from you to help us confirm
  your identity and ensure your right to access your Personal Data (or to
  exercise any of your other rights). This is a security measure to ensure
  that Personal Data is not disclosed to any person who has no right to
  receive it. We may also contact you to ask you for further information in
  relation to your request to speed up our response.
</p>
<p>
  We try to respond to all legitimate requests within one month. Occasionally
  it may take us longer than a month if your request is particularly complex
  or you have made a number of requests. In this case, we will notify you and
  keep you updated.
</p>`
};
