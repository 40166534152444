import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '.';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useInterval = () => {
    const interval: any = useRef();
    useEffect(
        () => () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        },
        [],
    );
    return interval;
};
