import engine1155ABI from "ethereum/abis/ERC1155/engine1155ABI";
import xSigma1155ABI from "ethereum/abis/ERC1155/xSigma1155ABI";
import { web3 } from './OnBoard';
import configs from 'configs';
import EthUtil from './EthUtil';
import Utility from 'service/utility';

class Contract1155 {
    getXsigma1155Address (network?: any) {
        const net = network || EthUtil.getNetwork();

        if (net) {
          switch(net) {
            case configs.ONBOARD_POLYGON_ID:
              return configs.MATIC_XSIGMA1155;
            case configs.ONBOARD_MUMBAI_ID:
              return configs.MUMBAI_XSIGMA1155;
            case configs.ONBOARD_NETWORK_ID:
              return configs.XSIGMA1155_ADDRESS;
            case configs.ONBOARD_RINKEBY_ID:
              return configs.RINKEBY_XSIGMA1155;
            case configs.ONBOARD_BSC_ID:
              return configs.BNB_XSIGMA1155;
            case configs.ONBOARD_BSC_TEST_ID:
              return configs.BNB_TEST_XSIGMA1155;
            default:
              return configs.XSIGMA1155_ADDRESS;
          }
        }
    }
    
    getEngine1155Address (network?: any) {
        const net = network || EthUtil.getNetwork();

        if (net) {
          switch(net) {
            case configs.ONBOARD_POLYGON_ID:
              return configs.MATIC_ENGINE1155;
            case configs.ONBOARD_MUMBAI_ID:
              return configs.MUMBAI_ENGINE1155;
            case configs.ONBOARD_NETWORK_ID:
              return configs.ENGINE1155_ADDRESS;
            case configs.ONBOARD_RINKEBY_ID:
            return configs.RINKEBY_ENGINE1155;  
            case configs.ONBOARD_BSC_ID:
              return configs.BNB_ENGINE1155;
            case configs.ONBOARD_BSC_TEST_ID:
              return configs.BNB_TEST_ENGINE1155;
            default:
              return configs.ENGINE1155_ADDRESS;
          }
        }
    }

    async getXsigma1155Bytecode () {
        return new web3.eth.getCode(this.getXsigma1155Address());
    }

    async getEngine1155Bytecode () {
        return new web3.eth.getCode(this.getEngine1155Address());
    }

    getXsigma1155Contract(network?: any) {
        const XSIGMA_ADDRESS = this.getXsigma1155Address(network);

        return new web3.eth.Contract(xSigma1155ABI, XSIGMA_ADDRESS);
    }

    getEngine1155Contract(network?: any) {
        const ENGINE_ADDRESS = this.getEngine1155Address(network);
        
        return new web3.eth.Contract(engine1155ABI, ENGINE_ADDRESS);
    }

    getXsigma1155ReadContract(network?: any) {
        const XSIGMA_ADDRESS = this.getXsigma1155Address(network);
        const web3Reader = EthUtil.getWeb3Reader(network);

        const abi: any = xSigma1155ABI;

        return new web3Reader.eth.Contract(abi, XSIGMA_ADDRESS);
    }

    getEngine1155ReadContract(network?: any) {
        const ENGINE_ADDRESS = this.getEngine1155Address(network);
        
        const web3Reader = EthUtil.getWeb3Reader(network);

        const abi: any = engine1155ABI;

        return new web3Reader.eth.Contract(abi, ENGINE_ADDRESS);
    }

    async getAuctionId(tokenId: any, network?: any) {
        if (web3) {
            const engineContract = this.getEngine1155ReadContract(network);

            try {
                let auctionId = await engineContract.methods.getAuctionId(tokenId).call();
                return Number(auctionId);
            } catch (err: any) {
                Utility.downloadFile(err.message || err);
                return 0;
            }
        }
        return 0;
    }

    async getApprovedForAll() {
        if (web3) {
            const xSigmaContract = this.getXsigma1155Contract();

            try {
                const approved = await xSigmaContract.methods.isApprovedForAll(EthUtil.getAddress(), this.getEngine1155Address()).call();

                return approved;
            } catch (err: any) {
                Utility.downloadFile(err.message || err);
            }
        }

        return false;
    }

    async createNft (amount: any , royalties: any , locked_content: string = '') {
        const network = EthUtil.getNetwork();
        const XSIGMA_1155_ADDRESS = this.getXsigma1155Address(network);
        if(web3) {
            const bytecode = await new web3.eth.getCode(XSIGMA_1155_ADDRESS);
            const xSigmaContract = new web3.eth.Contract(xSigma1155ABI, XSIGMA_1155_ADDRESS);
            try{
                const gasEstimate: any = await xSigmaContract.methods.createItem(amount, royalties, locked_content).estimateGas({
                    from: EthUtil.getAddress(),
                    data: bytecode
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                const result: any = await xSigmaContract.methods.createItem(amount, royalties , locked_content).send({
                    from: EthUtil.getAddress(),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    ...gasPrice,
                });
                if(result.status === true) {
                    const { TransferSingle } = result.events;
                    if(TransferSingle && TransferSingle["returnValues"]) return { success: true , tokenId: TransferSingle["returnValues"]["id"] };
                }
            }catch(err: any) {
                Utility.downloadFile(err.message || err);
                return { success: false, error: err }
            }
        }
        return { success: false, error: 'Failed to create NFT!' };
    }

    async setApprovalForAll() {
        const network = EthUtil.getNetwork();
        const XSIGMA_1155_ADDRESS = this.getXsigma1155Address(network);
        const ENGINE_1155_ADDRESS = this.getEngine1155Address(network);
        if(web3) {
            const bytecode = await new web3.eth.getCode(ENGINE_1155_ADDRESS);
            const xSigmaContract = new web3.eth.Contract(xSigma1155ABI, XSIGMA_1155_ADDRESS);
            try {
                const gasEstimate: any = await xSigmaContract.methods.setApprovalForAll(ENGINE_1155_ADDRESS, true).estimateGas({
                    data: bytecode,
                    from: EthUtil.getAddress()
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                await xSigmaContract.methods.setApprovalForAll(ENGINE_1155_ADDRESS, true).send({
                    from: EthUtil.getAddress(),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    ...gasPrice,
                });
                return true;
            }catch(err: any) {
                Utility.downloadFile(err.message || err);
                return false;
            }
        }
        return false;
    }

    async createOffer(tokenId: any, amount: number, isDirectSale: boolean , isAuction: boolean , price: any, minPrice: any, startTime: any, duration: any) {
        const network = EthUtil.getNetwork();
        const XSIGMA_1155_ADDRESS = this.getXsigma1155Address(network);
        const ENGINE_1155_ADDRESS = this.getEngine1155Address(network);
        if(web3) {
            const bytecode = await new web3.eth.getCode(ENGINE_1155_ADDRESS);
            const engineContract = new web3.eth.Contract(engine1155ABI, ENGINE_1155_ADDRESS);
            try {
                const gasEstimate: any = await engineContract.methods.createOffer(XSIGMA_1155_ADDRESS, tokenId, amount ,isDirectSale,isAuction,EthUtil.toWei(price),EthUtil.toWei(minPrice),startTime,duration).estimateGas({
                    data: bytecode,
                    from: EthUtil.getAddress()
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                const result: any = await engineContract.methods.createOffer(XSIGMA_1155_ADDRESS, tokenId, amount ,isDirectSale,isAuction,EthUtil.toWei(price),EthUtil.toWei(minPrice),startTime,duration).send({
                    from: EthUtil.getAddress(),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    ...gasPrice,
                });
                if(result.status === true) {
                    const { OfferCreated } = result.events;
                    if(OfferCreated && OfferCreated["returnValues"]) return { success: true , offerId: OfferCreated["returnValues"][0] };
                }
            }catch(err: any) {
                Utility.downloadFile(err.message || err);
                return { success: false, error: err }
            }
        }
        return { success: false, error: 'Failed to create NFT Offer!' };
    }

    async hasBids(offerId: any) {
        const network = EthUtil.getNetwork();
        const ENGINE_1155_ADDRESS = this.getEngine1155Address(network);
        if(web3) {
            const engineContract = new web3.eth.Contract(engine1155ABI, ENGINE_1155_ADDRESS);
            try{
                let hasBids = await engineContract.methods.hasBids(offerId).call();
                return hasBids;
            } catch(err: any) {
                Utility.downloadFile(err.message || err);
                return false;
            } 
        }
        return false;
    }

    async createAuctionAndBid(offerId: any, numberOfCopies: number, totalPrice: any) {
        const network = EthUtil.getNetwork();
        const ENGINE_1155_ADDRESS = this.getEngine1155Address(network);
        if(web3) {
            const bytecode = new web3.eth.getCode(ENGINE_1155_ADDRESS);
            const engineContract = new web3.eth.Contract(engine1155ABI, ENGINE_1155_ADDRESS);
            try{
                let gasEstimate: any = await engineContract.methods.createAuctionAndBid(offerId, numberOfCopies).estimateGas({
                    data: bytecode,
                    from: EthUtil.getAddress(),
                    value: EthUtil.toWei(totalPrice)
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                let result = await engineContract.methods.createAuctionAndBid(offerId, numberOfCopies).send({
                    from: EthUtil.getAddress(),
                    value: EthUtil.toWei(totalPrice),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    ...gasPrice,
                });

                if(result.status === true) {
                    const { AuctionBid } = result.events;
                    if(AuctionBid && AuctionBid["returnValues"]) return { success: true , auctionId: AuctionBid["returnValues"][0], transactionHash: result.transactionHash };
                }
                
            } catch(err: any) {
                Utility.downloadFile(err.message || err);
                return { success: false, error: err };
            }
        }
        return { success: false, error: 'Failed to create NFT Auction and Bids!' };
    }

    async bid(auctionId:any, totalPrice: any, numberOfCopies: number) {
        const network = EthUtil.getNetwork();
        const ENGINE_1155_ADDRESS = this.getEngine1155Address(network);
        if(web3) {
            if(auctionId!==null) {
                const bytecode = new web3.eth.getCode(ENGINE_1155_ADDRESS);
                const engineContract = new web3.eth.Contract(engine1155ABI, ENGINE_1155_ADDRESS);
                try {
                    const gasEstimate: any = await engineContract.methods.bid(auctionId, numberOfCopies).estimateGas({
                        data: bytecode,
                        from: EthUtil.getAddress(),
                        value: EthUtil.toWei(totalPrice),
                    });

                    const gasPrice = await EthUtil.getGasPrice() || {}

                    const result: any = await engineContract.methods.bid(auctionId, numberOfCopies).send({
                        from: EthUtil.getAddress(),
                        value: EthUtil.toWei(totalPrice),
                        gas: gasEstimate + configs.GAS_LIMIT,
                        maxPriorityFeePerGas: null,
                        maxFeePerGas: null,
                        ...gasPrice,
                    });
                    if(result.status === true) {
                        return { success: true , transactionHash: result.transactionHash };
                    }
                    
                } catch (err: any) {
                    Utility.downloadFile(err.message || err);
                    return { success: false };
                }
            }
        }
        return { success: false, error: 'Failed to bid to this item!' };
    }

    async directBuy(offerChainId: any, amount: any, totalPrice: any) {
        const network = EthUtil.getNetwork();
        const ENGINE_1155_ADDRESS = this.getEngine1155Address(network);
        if(web3) {
            const bytecode = new web3.eth.getCode(ENGINE_1155_ADDRESS);
            const engineContract = new web3.eth.Contract(engine1155ABI, ENGINE_1155_ADDRESS);
            try {
                const gasEstimate: any = await engineContract.methods.buy(offerChainId, amount).estimateGas({
                    data: bytecode,
                    from: EthUtil.getAddress(),
                    value: EthUtil.toWei(totalPrice),
                });

                const gasPrice = await EthUtil.getGasPrice() || {}

                const result: any = await engineContract.methods.buy(offerChainId, amount).send({
                    from: EthUtil.getAddress(),
                    value: EthUtil.toWei(totalPrice),
                    gas: gasEstimate + configs.GAS_LIMIT,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null,
                    ...gasPrice,
                })
                if(result.status === true) {
                    return { success: true , transactionHash: result.transactionHash };
                }
            } catch (err: any) {
                Utility.downloadFile(err.message || err);
                return { success: false, error: 'Failed to buy this item directly!' };
            }
        }
        return { success: false, error: 'Failed to buy this item directly!' };
    }
}

const smartContract = new Contract1155();

export default smartContract;