import React, { useEffect } from "react";
import Layout from "components/Layout";
import "./Privacy.scss";
import { PrivacyContent } from './data';

interface PrivacyProps {}

const Privacy: React.FC<PrivacyProps> = () => {
  useEffect(() => {}, []);

  return (
    <Layout className="container">
      <div dangerouslySetInnerHTML={{__html: PrivacyContent.data}} />
    </Layout>
  );
};

export default Privacy;
