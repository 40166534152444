import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { connectUserWallet, signInWithWallet } from "store/User/user.slice";
import { getWalletAddress, isAuthenticated } from "store/User/user.selector";

import Collectible from "./pages/Collectible/Collectible";
import CreateERC1155 from "./pages/CreateCollectible/CreateERC1155";
import CreateERC721 from "./pages/CreateCollectible/CreateERC721";

import Tokenization from "./pages/Tokenization/Tokenization";

import Home from "./pages/Home/Home";
import TokenDetail from "./pages/TokenDetail/TokenDetail";
import MyItems from "./pages/MyItems/MyItems";
import Activity from "./pages/Activity/Activity";
import SearchView from "./pages/Search/Search";
import Profile from "./pages/Profile/Profile";
import UserProfile from "./pages/UserProfile/UserProfile";
import CollectionDetail from "./pages/CollectionDetail/CollectionDetail";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";

import "./styles/index.scss";
import {
  getNftCategories,
  getETHUSDTCurrency,
  getMATICUSDTCurrency,
  getBNBUSDTCurrency,
} from "store/Nft/nft.slice";
import { getMyInfo } from "./store/User/user.slice";
import configs from "configs";
import Page404 from "pages/404/Page404";
import UpcomingDrops from "pages/UpcomingDrops/UpcomingDrops";

interface AppProps {}
declare let window: any;

const App: React.FC<AppProps> = () => {
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector(getWalletAddress);
  const isAuth = useAppSelector(isAuthenticated);

  useEffect(() => {
    if (window && window.ethereum && window.ethereum.networkVersion) {
      localStorage.setItem(
        configs.STORAGE.SELECTED_NETWORK,
        window.ethereum.networkVersion
      );
    }
  }, []);

  useEffect(() => {
    if (isAuth || walletAddress) {
      dispatch(connectUserWallet());
    }
    dispatch(getETHUSDTCurrency());
    dispatch(getMATICUSDTCurrency());
    dispatch(getBNBUSDTCurrency());
    dispatch(getNftCategories());
  }, [dispatch, isAuth, walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      dispatch(signInWithWallet({ wallet: walletAddress }));
      dispatch(getMyInfo(walletAddress));
    }
  }, [dispatch, walletAddress]);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/collections/:id">
            <CollectionDetail />
          </Route>
          <Route exact path="/users/:walletAddress">
            <UserProfile />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/search">
            <SearchView />
          </Route>
          <Route exact path="/tokenization">
            <Tokenization />
          </Route>
          <Route exact path="/upcoming-drops">
            <UpcomingDrops />
          </Route>
          <Route exact path="/collectible">
            <Collectible />
          </Route>
          <Route exact path="/create-collectible/erc1155">
            <CreateERC1155 />
          </Route>
          <Route exact path="/create-collectible/erc721">
            <CreateERC721 />
          </Route>
          <Route exact path="/tokens/:id">
            <TokenDetail />
          </Route>
          <Route exact path="/items">
            <MyItems />
          </Route>
          <Route exact path="/activity">
            <Activity />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/explore">
            <Home />
          </Route>
          <Route>
            <Page404 />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
