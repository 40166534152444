import configs from 'configs'
import API from 'service/api'

class LogController {
  public static getErrorLogs() {
    return API.get(`${configs.API.COMMON_URL}/logs`).then((res) => res.data)
  }

  public static addErrorLogs(payload: any) {
    const message =
      typeof payload.message === 'object'
        ? JSON.stringify(payload.message)
        : payload.message

    return API.post(`${configs.API.COMMON_URL}/logs/add`, {
      ...payload,
      message,
    }).then((res) => res.data)
  }
}

export default LogController
