/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Col, Row, Nav, Tab, Button } from "react-bootstrap";
import {
  BigTitle,
  NormalTextTitle,
  FilterIcon
} from "components/common/common.styles";
import Layout from "components/Layout";
import NoItem from "components/common/noItem";
import "./Activity.scss";
import ActivityController from "controller/ActivityController";
import { FaTag, FaHeart, FaCheck } from "react-icons/fa";
import { BsLightningFill } from 'react-icons/bs';
import { BiTransfer } from 'react-icons/bi';
import { RiAuctionFill } from 'react-icons/ri';
import { ImDiamonds } from 'react-icons/im';
import ActivityItem from "components/token/ActivityItem";
import LoadingBar from "components/common/LoadingBar";
import { useAppSelector } from "store/hooks";
import { isAuthenticated } from "store/User/user.selector";

interface ActivityProps {}

const _categories = [
  { title: "All", count: 0, active: true, path: "all" },
  { title: "Following", count: 0, active: false, path: "following" },
  { title: "My Activity", count: 0, active: false, path: 'my' }
];

const _filters = [
  { name: 'Listings', selected: false, filter: 'listed', icon: <FaTag /> },
  { name: 'Purchases', selected: false, filter: 'purchased', icon: <ImDiamonds /> },
  { name: 'Sales', selected: false, filter: 'minted', icon: <BsLightningFill /> },
  { name: 'Transfers', selected: false, filter: 'transfered', icon: <BiTransfer /> },
  { name: 'Bids', selected: false, filter: 'offered', icon: <RiAuctionFill /> },
  { name: 'Likes', selected: false, filter: 'liked', icon: <FaHeart /> },
  { name: 'Followings', selected: false, filter: 'following', icon: <FaCheck /> },
];

const Activity: React.FC<ActivityProps> = () => {
  const isAuth = useAppSelector(isAuthenticated);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(_categories);
  const [pages, setPages] = useState(1);
  const [pageNum, setPageNumber] = useState(1);
  const [filters] = useState(_filters);
  const [activityList, setActivities] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [selectedCategory, setSelelctedCategory] = useState('all');
  useEffect(() => {
    const loadActivities = async () => {
      setLoading(true);
      let { activities, pages } = await ActivityController.getList(selectedCategory, pageNum, selectedFilter);
      if (pageNum === 1) {
        setPages(pages);
      }
      setCategories(categories);
      if (pageNum === 1) {
        setActivities(activities);
      } else {
        setActivities(activityList.concat(activities))
      }
      setLoading(false);
    }
    if (isAuth) {
      loadActivities();
    }
  }, [selectedCategory, isAuth, pageNum, selectedFilter]);

  const onSelectFilter = (filter: String) => {
    setLoading(true);
    if (selectedFilter === filter || filter === '') {
      setSelectedFilter('');
    } else {
      setSelectedFilter(`${filter}`);
    }
    setPageNumber(1);
    setLoading(false);
  }

  

  const getFilterClass = (val: string) => {
    if (selectedFilter === val)
      return "filter-btn active";
    return "filter-btn";
  };

  const getFilterColor = (type: String) => {
    if (selectedFilter === type) {
      switch(type) {
        case 'following':
          return 'rgb(109, 188, 0)';
        case 'liked':
          return 'rgb(255, 158, 18)';
        case 'listed':
          return 'rgb(0, 147, 255)';
        case 'purchased':
          return 'rgb(255, 199, 90)';
        case 'minted':
          return 'rgb(235, 88, 73)';
        case 'transfered':
          return 'rgb(177, 89, 220)';
        case 'offered':
          return 'rgb(247, 109, 192)';
        default:
          break;
      }
    }
  };

  return (
    <Layout className="activity-container">
      <Container>
        <Tab.Container defaultActiveKey="all" id="activity_tabs">
          <BigTitle className="mb-4 font-matrice">Activity</BigTitle>
          <div className="link-section">
            <Nav>
              {categories.map((category, i) => (
                <Nav.Item key={i} onClick={() => {setSelelctedCategory(category.path); setPages(1); setPageNumber(1);}}>
                  <Nav.Link eventKey={category.path}>
                    {category.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <Row className="flex-reserve">
            <Col md="8">
              <div className="activity-list mt-4">
                <Tab.Content>
                  <Tab.Pane eventKey={selectedCategory}>
                    {
                      loading && pageNum === 1 ? (
                        <div className="no-data">
                          <div className="no-item loading-bar">
                            <LoadingBar />
                          </div>
                        </div>
                      ) : (
                        activityList.length > 0 ? (
                          activityList.map((activity, idx) => (
                            <ActivityItem key={idx} item={activity} />
                          ))
                        ) : (
                          <NoItem
                            title="Nothing yet"
                            description="Activity will be shown here"
                            btnLink="/"
                            btnLabel="Explore Xsigma"
                          />
                        )
                      )
                    }
                    {
                      loading && pageNum > 1 ? (
                        <div className="my-3 d-flex justify-content-center loading-bar">
                          <LoadingBar />
                        </div>
                      ) : (
                        activityList.length > 0 && pages > pageNum && (
                          <div className="d-flxex mt-5 justify-content-center text-center">
                            <Button
                              variant="primary"
                              className="btn-round w-50 outline-btn"
                              onClick={() => setPageNumber(pageNum + 1)}
                            >
                              <span>
                                Load More
                              </span>
                            </Button>
                          </div>
                        )
                      )
                    }
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
            <Col md="4">
              <div className="sticky-filters mt-4">
                <div className="filter-header mb-4">
                  <NormalTextTitle className="mr-3">
                    Filters
                  </NormalTextTitle>
                  {
                    selectedFilter && (
                      <NormalTextTitle className="filter-reset" onClick={() => { onSelectFilter('') }}>
                        Reset filter
                      </NormalTextTitle>
                    )
                  }
                </div>
                <div className="filter-buttons">
                  {
                    filters.map((filter, index) => {
                      return (
                        <div
                          className={getFilterClass(filter.filter)}
                          key={index}
                          onClick={() => {onSelectFilter(`${filter.filter}`)}}
                          style={{ backgroundColor: getFilterColor(filter.filter) }}
                        >
                          <FilterIcon className="mr-3">
                            {filter.icon}
                          </FilterIcon>
                          <span>{filter.name}</span>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </Layout>
  );
};

export default Activity;
