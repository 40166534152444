import moment from "moment";

class DateTimeService {
  public static getIsoDateTimeWithDays(days: any) {
    let minutes = Math.floor(days * 24 * 60);
    let date = moment().add(minutes, "m").toISOString();
    return date;
  }

  public static getDurationByDays(days: any, milliseconds: boolean) {
    return days * 24 * 60 * 60 * (milliseconds ? 1000 : 1);
  }
  public static getDurationSecondsWithTwoDates(firstDate: any, secondDate: any) {
    var firstM = firstDate === 'now' ? moment() : moment(firstDate);
    var secondM = secondDate === 'now' ? moment() : moment(secondDate);
    var duration = moment.duration(secondM.diff(firstM));
    var seconds = Math.floor(duration.asSeconds());
    return seconds;
  }
}

export default DateTimeService;
