import React from "react";
import {
  B1NormalTextTitle,
  FlexAlignCenterDiv,
  NormalTextTitle,
  SmallTextTitleGrey,
} from "../common/common.styles";
import { Link } from "react-router-dom";
import configs from "configs";
import { AiFillHeart } from 'react-icons/ai';
import Utility from 'service/utility';
import ethUtil from "ethereum/EthUtil";
import {
  getETHUSDTCurrency,
  getMATICUSDTCurrency,
  getBNBUSDTCurrency,
} from "store/Nft/nft.selector";
import { useAppSelector } from "store/hooks";
interface OnSaleItemProps {
  item: any;
}

const OnSaleItem: React.FC<OnSaleItemProps> = ({ item }) => {
  const token = item.token || {};
  const ethDollarPrice = useAppSelector(getETHUSDTCurrency);
  const maticDollarPrice = useAppSelector(getMATICUSDTCurrency);
  const bnbDollarPrice = useAppSelector(getBNBUSDTCurrency);

  const getTokenThumbnail = () => {
    let media = token.media_type && token.media_type.toLowerCase();
    if ( media &&
      (media.includes("mp3") ||
      media.includes("mp4") ||
      media.includes("webm"))
    ) {
      if (token.preview) {
        return Utility.mediaValidation(token.preview);
      }
      return `${configs.DEPLOY_URL}/content/collection/xsigma.jpg`;
    }
    return Utility.mediaValidation(token.preview || (token.thumbnail && token.thumbnail !== 'false' ? token.thumbnail : '') || token.media || token.image);
  };

  const getCurrentBidPrice = () => {
    if (item.bids && item.bids.length) {
      let bids = item.bids.sort(function (a: any, b: any) {
        return b.price - a.price;
      });
      return bids[0].price;
    }
    return 0;
  };

  return (
    <Link
      className="auction-item mr-4 p-4"
      to={"/tokens/" + token._id + (token.type === 1155 ? `?offer=${item._id}` : '')}
    >
      {token.type === 1155 && item.available_copies ? (
        <div className="multiple" />
      ) : ''}
      <div className="token-img-area mt-2">
        <div className="pre-token-img">
          {token.media ? (
            <img src={getTokenThumbnail()} alt="auctionImage" />
          ) : (
            <div className="no-thumbnail"></div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between token-details-area align-items-end">
        <div className="item-content">
          <B1NormalTextTitle className="mt-3">{token.name}</B1NormalTextTitle>
          <SmallTextTitleGrey className="token-collection-name">{token.collections ? token.collections.name : "xSigma"}</SmallTextTitleGrey>
          <SmallTextTitleGrey>
            {item.type !== "auction" && <>From {ethUtil.getDollarPrice(
                            item.offer_price,
                            ethUtil.getChainIDByNetworkName(token.blockchain),
                            {
                              ethDollarPrice,
                              maticDollarPrice,
                              bnbDollarPrice,
                            }
                          )}</>}
          </SmallTextTitleGrey>
          <FlexAlignCenterDiv className="mt-1">
            {
              item.type !== 'direct' && (
                <NormalTextTitle>
                  {getCurrentBidPrice() ? (
                    <>Current Bid {ethUtil.getDollarPrice(
                      getCurrentBidPrice(),
                      ethUtil.getChainIDByNetworkName(token.blockchain),
                      {
                        ethDollarPrice,
                        maticDollarPrice,
                        bnbDollarPrice,
                      }
                    )}</>
                  ) : (
                    <>Minimum Bid {ethUtil.getDollarPrice(
                      item.min_bid,
                      ethUtil.getChainIDByNetworkName(token.blockchain),
                      {
                        ethDollarPrice,
                        maticDollarPrice,
                        bnbDollarPrice,
                      }
                    )}</>
                  )}{" "}
                </NormalTextTitle>
              )
            }
          </FlexAlignCenterDiv>
          {
            item.token && item.token.type === 1155 && item.available_copies && <NormalTextTitle className="grey-color">{item.available_copies + '/' + item.total_copies} {item.available_copies > 1 ? 'editions' : 'edition'} on Sale</NormalTextTitle>
          }
        </div>
        {
          token.likes ? (
            <div className="likes d-flex align-items-center">
              <AiFillHeart />
              &nbsp;
              {token.likes}
            </div>
          ) : ''
        }
      </div>
    </Link>
  );
};

export default OnSaleItem;
