export const EXPIRE_DATE_OPTIONS: any = [
  {
    value: '600',
    label: '10 Mins',
  },
  {
    value: '86400',
    label: '1 Day',
  },
  {
    value: '259200',
    label: '3 Days',
  },
  {
    value: '432000',
    label: '5 Days',
  },
  {
    value: '604800',
    label: '1 Week',
  },
  {
    value: '864000',
    label: '10 Days',
  },
  {
    value: '1209600',
    label: '2 Weeks',
  },
];