import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from 'components/Layout'
import './UpcomingDrops.scss'
import Collections from 'components/collection/Collections'
import { BigTitle } from 'components/common/common.styles'

interface TokenizationProps {}

const UpcomingDrops: React.FC<TokenizationProps> = () => {
  return (
    <Layout className="home-container upcoming-drops">
      <div className="item-columns">
        <Row className="mb-5 row-reserve">
          <Col>
            <BigTitle className="mb-5 mt-5">
              <span className="mr-3">Upcoming Drops</span>
            </BigTitle>
            <Collections type="upcoming" isSlider={false} />
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default UpcomingDrops
